import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom";
import { url } from "../utils/Helper";


const ThankYou = () => {
    const navigate = useNavigate()
    const [seconds, setSeconds] = useState(0);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(true);
    {/* In Case user is already logged in, take user directly to select address page */ }
    const orderId = localStorage.getItem('order_id')
    const vendorId = localStorage.getItem('vendor_id')
    const customerId = localStorage.getItem('customer_id')
    const totalDiscount = localStorage.getItem('totalDiscount')
    const couponId = localStorage.getItem('couponId')
    const totalAmount = localStorage.getItem('totalAmount')
    const address = localStorage.getItem('address')
    const token = localStorage.getItem('token')
    const paymentMethod = localStorage.getItem('paymentMethod')


    useEffect(() => {
        let interval;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);


    useEffect(() => {
        setLoading(true)
        setTimeout(() => {
            handlePlaceOrder()
        }, 1000);
    }, [])



    useEffect(() => {
        if (seconds == 1) {
            navigate('/my-account')
        }
    }, [seconds])



    const handlePlaceOrder = async (item) => {
        setLoading(true)
        // console.log(item)
        const placeOrder = await fetch(`${url}/customer/submitOrder`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "orderId": orderId,
                "customer_id": customerId,
                "vendor_id": vendorId,
                "coupon_id": couponId || null,
                "price": totalAmount,
                "discount": totalDiscount || 0,
                "delivery_address": address,
                "payment_status": paymentMethod,
                "order_status": "New Order"
            }),
        })
        if (!placeOrder.ok) {
            setLoading(false)
            alert('Something went wrong')
            navigate('/cart')
            setShow(false)
        }
        const data = await placeOrder.json()
        if (data) {
            // console.log(data)
            setLoading(false)
            setSeconds(6)
            setShow(true)
        }
    }




    return <>

        <section className="frontCheckout frontGreyBg">
            {loading == true ?
                <>
                    <div>Loading</div>
                </>
                :
                <>
                    {show == true ?
                        <div className="webContainer">

                            <div className='row'>
                                <div className="col-sm-5 m-auto">
                                    <h2 className='font-weight-semibold mt-5 text-center'>Thank You</h2>
                                    <h3 className="text-success text-center">Your order is successful</h3>
                                    <p className="text-center">Redirecting in {seconds} sec.</p>
                                </div>
                            </div>

                        </div>
                        :
                        <div className='row'>
                            <div className="col-sm-5 m-auto">
                                <h2 className='font-weight-semibold mt-5 text-center'>Payment Failed</h2>
                            </div>
                        </div>
                    }
                </>
            }
        </section>

    </>
}

export default ThankYou;