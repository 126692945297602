import React, { useState, useEffect } from 'react'
import Admin from '../../components/Admin'
import '../../styles/index.css'
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
// import GoogleAnalytics from './GoogleAnalytics'
import { url } from "../../../utils/Helper"
import { formats, modules } from '../../components/EditorComponents/TextEditor'
import { handleUpdateSetting } from '../../Service'


const AllSettings = () => {
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [settings, setSettings] = useState([]);
  const [updatedContent, setUpdatedContent] = useState('');
  const [activeSettingId, setActiveSettingId] = useState(null);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${url}/admin/settings`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch settings');
        }
        const data = await response.json();
        setSettings(data.settings);
      } catch (error) {
        console.error('Error fetching settings:', error);
      } finally {
        setLoading(false);
        setRefresh(false)
      }
    };
    fetchSettings();
  }, [refresh]);



  const toggleVisibility = (settingId) => {
    setActiveSettingId(activeSettingId === settingId ? null : settingId);

    updateSetting(settingId)

  };
  const toggleVisibility1 = (settingId) => {
    setActiveSettingId(activeSettingId === settingId ? null : settingId);

  };


  const updateSetting = async (id) => {
    const sendStatusData = {
      token: token,
      body: updatedContent,
      setting_id: id,
    }
    try {
      console.log(sendStatusData)
      setRefresh(true)
      const res = await handleUpdateSetting(sendStatusData)
      console.log(res, 'hell')
      setUpdatedContent('')
      // window.location.reload()
      // Redirect or perform any other necessary actions
    } catch (error) {
      console.error('Login error:', error.message)
    }
  }

  if (loading) {
    return <p>Loading...</p>
}

  return <>

    <Admin />

    <div id="adminMain">

      <div className="container">

        <div className="row">
          <div className='col-12'>
            <h3>All Settings</h3>
          </div>
        </div>

        <div className="row">
          {settings?.map((setting) => (
            <div className="col-sm-4 mb-3" key={setting.id}>
              <div className="card shadow bg-light">
                <div className="card-body d-flex justify-content-around">
                  <h4 className="card-title">{setting.name}</h4>
                </div>
                <div className='mb-0 text-center'>
                  <p>{setting.body}</p>
                </div>
                <div className='p-2'>
                  {/* Render input fields or ReactQuill based on setting type and activeSettingId */}
                  {activeSettingId === setting.id && setting.type === 'text' && (
                    <div className="modal">
                      <div className="modal-content1">
                        <span className="close" onClick={toggleVisibility}>
                          &times;
                        </span>
                        <div >
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            placeholder="write your content ...."
                            onChange={(content) => setUpdatedContent(content)}
                            style={{ height: "300px", width: "550px" }}
                          />
                        </div>
                        <button type="button" onClick={() => { activeSettingId === setting.id ? toggleVisibility(setting.id) : toggleVisibility1(setting.id) }} className="btn admin-btn-theme" style={{marginTop:"15%"}}>
                          {activeSettingId === setting.id ? 'Update' : 'Edit'}
                        </button>
                      </div>
                    </div>
                  )}
                  {activeSettingId === setting.id && setting.type === 'contact' && (
                    <div className='form-group'>
                      <input type="text" placeholder='Enter here' value={updatedContent} onChange={(e) => setUpdatedContent(e.target.value)} />
                    </div>
                  )}
                  {activeSettingId === setting.id && setting.type === 'location' && (
                    <div className='form-group'>
                      <input type="text" placeholder='Enter here' value={updatedContent} onChange={(e) => setUpdatedContent(e.target.value)} />
                    </div>
                  )}
                  {activeSettingId === setting.id && setting.type === 'script' && (
                    <div className='form-group'>
                      <input type="text" placeholder='Enter here' value={updatedContent} onChange={(e) => setUpdatedContent(e.target.value)} />
                    </div>
                  )}
                  {/* ... other cases for email, phone, address, googleanalytics */}
                  {isVisible && activeSettingId !== setting.id && (
                    <div className='form-group'>
                      <input type="text" placeholder='Enter here' value={updatedContent} onChange={(e) => setUpdatedContent(e.target.value)} />
                    </div>
                  )}
                </div>

                <button type="button" onClick={() => { activeSettingId === setting.id ? toggleVisibility(setting.id) : toggleVisibility1(setting.id) }} className="btn admin-btn-theme">
                  {activeSettingId === setting.id ? 'Update' : 'Edit'}
                </button>
              </div>
            </div>
          ))}
        </div>

      </div>

    </div>

  </>
}

export default AllSettings