import React, { useEffect, useState } from "react";
import { url } from "../../../utils/Helper";
import DateTime from "../../../utils/DateTime";
import Admin from "../../components/Admin";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { handleAddReply, handleChangeStatus } from "../../Service";
import { formats, modules } from "../../components/EditorComponents/TextEditor";

const ViewTicket = () => {
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const ticketId = params.get("id");

    const [ticketMessages, setTicketMessages] = useState([]);
    const [loading, setLoading] = useState(true);
    const [reply, setReply] = useState('');
    const [image, setImage] = useState('');


    const userId = localStorage.getItem("user_id");
    const token = localStorage.getItem("token");

    useEffect(() => {
        const fetchMessages = async () => {
            try {
                const token = localStorage.getItem("token");
                const ticketMessages = await fetch(`${url}/admin/ticket/${ticketId}`, {
                    method: "GET",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });
                if (!ticketMessages.ok) {
                    throw new Error("Failed to fetch user");
                }
                const data = await ticketMessages.json();
                console.log(data)
                setTicketMessages(data);
            } catch (error) {
                console.error("Error fetching messages:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchMessages();
    }, [ticketId]);


    const addReplyInTicket = async () => {
        const sendData = {
            message: reply,
            token: token,
            user_id: userId,
            sender_id: 2,
            ticket_id: ticketId,
            sender_id: userId,
            receiver_id: '2',
            image: image
        }
        const sendStatusData = {
            status: 'Replied',
            token: token,
            ticket_id: ticketId,
        }
        try {
            const resData = await handleAddReply(sendData)
            const res = await handleChangeStatus(sendStatusData)
            // console.log(resData)
            // console.log(res,'hell')
            window.location.reload()
            // Redirect or perform any other necessary actions
        } catch (error) {
            console.error('Login error:', error.message)
        }
    }


    const handleProcedureContentChange = (content) => {
        // console.log("content---->", content);
        setReply(content);
    };



    if (loading) {
        return <p>Loading...</p>;
    }

    return (
        <>
            <Admin />

            <div id="adminMain">
                <div className="container">
                    <div className="row mb-3">
                        <div className="col-12">
                            <div className="card p-2 w-100 shadow text-right text-info">
                            Dashboard &gt;&gt; Ticket
                            </div>
                        </div>
                    </div>

                    <div className="row mb-3">
                        <div className="col-md-7">
                            <div className="card p-1 shadow">
                                <div className="admin-chat-container">
                                    <div className="admin-chat-messages" id="chatMessages">
                                        {ticketMessages.map((ticket) => (
                                            <div key={ticket.message_id}>
                                                {ticket.sender_id.toString() === userId ? (
                                                    <div className="admin-message text-right bg-light p-2 rounded">
                                                        <span className="admin-username">
                                                            <h5>Admin:</h5>
                                                            <span className="admin-timestamp">
                                                                <DateTime apiDateTime={ticket.created_at} />
                                                            </span>
                                                            <div className="article" style={{ fontSize: '15px' }}>
                                                                <p dangerouslySetInnerHTML={{ __html: ticket.message }}></p>
                                                            </div>
                                                        </span>

                                                    </div>
                                                ) : (
                                                    // Message from vendor
                                                    <div className="admin-message p-2">
                                                        <span className="admin-username">
                                                            <h5>Vendor:</h5>
                                                            <span className="admin-timestamp">
                                                                <DateTime apiDateTime={ticket.created_at} />
                                                            </span>
                                                            <p dangerouslySetInnerHTML={{ __html: ticket.message }}></p>
                                                        </span>
                                                    </div>
                                                )}

                                                <hr />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="card p-3 w-100 shadow">
                            <h5>
                                Send Reply To
                                <Link to={`/admin/user?id=${ticketId}&role=vendor`}>
                                    {" "}
                                    Vendor
                                </Link>
                            </h5>
                            <br />
                            <div>
                                <div style={{ display: "grid", justifyContent: "center" }}>
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="write your content ...."
                                        onChange={handleProcedureContentChange}
                                        style={{ height: "220px" }}
                                    >
                                    </ReactQuill>
                                </div>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <button className="btn btn-theme btn-primary" onClick={addReplyInTicket} style={{ cursor: "pointer" }} >
                                Send
                            </button>
                        </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    );
};

export default ViewTicket;
