import React, { useEffect, useState } from 'react';
import Admin from '../../components/Admin';
import '../../styles/index.css';
import { url } from '../../../utils/Helper';
import DateTime from '../../../utils/DateTime';
import { Link } from 'react-router-dom';
import { handleChangeStatus } from '../../Service';

const ListTicket = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 1;


  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${url}/admin/tickets`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Failed to fetch tickets');
        }
        const data = await response.json();
        setTickets(data);
      } catch (error) {
        console.error('Error fetching tickets:', error);
      } finally {
        setLoading(false);
        setRefresh(false)
      }
    };
    fetchTickets();
  }, [refresh]);

  const changeTicketStatus = async (id, status) => {
    const sendStatusData = {
      status: status,
      token: token,
      ticket_id: id,
    }
    try {
      console.log(sendStatusData)
      setRefresh(true)
      const res = await handleChangeStatus(sendStatusData)
      // console.log(resData)
      // console.log(res,'hell')
      // window.location.reload()
      // Redirect or perform any other necessary actions
    } catch (error) {
      console.error('Login error:', error.message)
    }
  }

  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = tickets.slice(indexOfFirstTicket, indexOfLastTicket);

  const renderTickets = currentTickets.map((ticket) => (
    <tr key={ticket.ticket_id}>
      <td>{ticket.title}</td>
      <td>{ticket.subject}</td>
      <td>{ticket.status}</td>
      <td><DateTime apiDateTime={ticket.created_at} /></td>
      <td>
        <Link to={`/admin/ticket/view?id=${ticket.ticket_id}`}>View</Link>
      </td>
      <td><select className="form-control" onChange={(e) => changeTicketStatus(ticket.ticket_id, e.target.value)}>
        <option >-Ticket Status-</option>
        <option value='Processing'>Processing</option>
        <option value='Replied'>Replied</option>
        <option value='Investigating'>Investigating</option>
        <option value='Closed'>Closed</option>
      </select> </td>
    </tr>
  ));

  const totalPages = Math.ceil(tickets.length / ticketsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Admin />

      <div id="adminMain">
        <div className="container">
          <div className="row mb-3">
            <div className='col-12'>
              <div className='card p-2 w-100 shadow text-right text-info'>
                Dashboard &gt;&gt; Ticket</div>
            </div>
          </div>

          <div className='row'>
            <div className='col-12'>
              <div className='card p-2 w-100 shadow'>
                <table className='table table-striped'>
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Subject</th>
                      <th>Status</th>
                      <th>On Date</th>
                      <th>Action</th>
                      <th>Update Status</th>
                    </tr>
                  </thead>
                  <tbody>{renderTickets}</tbody>
                </table>

                <div className="pagination" style={{ justifySelf: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                  {[...Array(totalPages)].map((_, index) => (
                    <span
                      key={index}
                      onClick={() => handlePageChange(index + 1)}
                      style={{
                        padding: '8px',
                        margin: '4px',
                        cursor: 'pointer',
                        backgroundColor: currentPage === index + 1 ? '#007BFF' : '#FFFFFF',
                        color: currentPage === index + 1 ? '#FFFFFF' : '#007BFF',
                        borderRadius: '4px',
                      }}
                    >
                      {index + 1}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListTicket;
