import firebase from 'firebase/compat/app';
import 'firebase/compat/auth'


const firebaseConfig = {
  apiKey: "AIzaSyB8mJi_R8YzkpOhqdP4hNp3BSJYv6jtdNE",
  authDomain: "tiffin-service-ae167.firebaseapp.com",
  projectId: "tiffin-service-ae167",
  storageBucket: "tiffin-service-ae167.appspot.com",
  messagingSenderId: "750168543490",
  appId: "1:750168543490:web:66208c0b99b7e88dbff21b",
  measurementId: "G-Q4XPYSKNHH",
};

firebase.initializeApp(firebaseConfig);
let auth = firebase.auth();
export { auth, firebase };



