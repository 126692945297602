import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper";
import calanderIcon from '../../../assets/icons/calendar.png'
import checkIcon from '../../../assets/icons/checked.png'
import { useState } from "react";

const Revenue = () => {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [revenueData, setRevenueData] = useState();
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')

    const handleCalculateStock = async () => {
        try {
            const calculateStock = await fetch(`${url}/vendor/profit`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendorId": vendorId,
                    "startDate": startDate,
                    "endDate": endDate,
                }),
            })
            if (!calculateStock.ok) {
                throw new Error('Failed to fetch users')
            }
            const data = await calculateStock.json()
            setRefresh(true)
            setRevenueData(data)

        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }


    return <>

        <Vendor />

        <div className='frontGreyBg vendorDataContainer'>

            <section className='webContainer py-5'>

                <div className='row'>
                    <div className='col-10'>
                        <div className='row'>
                            <div className='col-sm-4'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={calanderIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" class="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="From"
                                        autoComplete="off" required
                                        value={startDate}
                                        onChange={(e) => setStartDate(e.target.value)}
                                        onClick={(e) => e.currentTarget.showPicker()}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                            <div className='col-sm-4'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={calanderIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" class="form-control frontFormInput"
                                        id="email"
                                        name="email"
                                        placeholder="To"
                                        autoComplete="off" required
                                        value={endDate}
                                        onChange={(e) => setEndDate(e.target.value)}
                                        onClick={(e) => e.currentTarget.showPicker()}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>

                            <div className='col-4'>
                                <div className="d-flex justify-content-start">
                                    <button className="btn frontFormBtn mt-1" onClick={handleCalculateStock}>
                                        <div className="frontBtnIcon" >
                                            <img src={checkIcon} alt="" />
                                        </div>
                                        <p>Calculate</p>
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* ./Row */}
                    </div>

                    <div className='col-4'>

                    </div>

                </div>
                {/* ./Form And Nav */}

                <div className='row mt-5'>
                    <div className='col-12'>
                        <div className='card rounded-50 themeShadow border-0 py-2 px-4'>
                            <h5>Revenue For The Period <span className='text-primary'>{startDate} - {endDate}</span> is <span className='text-success h2'>{revenueData?.profit}</span></h5>
                        </div>
                    </div>

                </div>

                <div className='row mt-4'>
                    <div className='col-sm-7'>
                        <div className='card rounded-50 themeShadow border-0 p-4'>
                            <h3 className='font-weight-bold'>Summary</h3>

                            <table className='table'>
                                <tbody className='font-weight-bold'>
                                    <tr>
                                        <td className='border-0  text-left'>
                                            Total Stock Purchased
                                        </td>
                                        <td className='border-right border-top-0 border-bottom-0  text-left'>
                                            {revenueData?.totalStockPurchased}
                                        </td>
                                        <td className='border-0  text-left'>
                                            Total Orders
                                        </td>
                                        <td className='border-0'>
                                            {revenueData?.totalOrders}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='border-0  text-left'>
                                            Total Sales
                                        </td>
                                        <td className='border-right border-top-0 border-bottom-0  text-left'>
                                            {revenueData?.totalSales}
                                        </td>
                                        <td className='border-0  text-left'>
                                            Deliveries Made
                                        </td>
                                        <td className='border-0'>
                                            {revenueData?.totalDeliveries}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                </div>

            </section>

        </div>


    </>
}

export default Revenue