import backArrow from '../assets/icons/back.png'
import cart from '../assets/icons/cart.png'
import { url } from '../utils/Helper';
import React, { useState, useRef, useEffect } from 'react';
import HeaderNavigation from './components/HeaderNavigation';
import { useNavigate } from 'react-router-dom';

const Coupon = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [coupons, setCoupons] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')


    useEffect(() => {
        getCoupons();
    }, [refresh]);


    const getCoupons = async () => {
        try {
            const couponsRes = await fetch(`${url}/front/vendor-coupons`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendorId": vendorId
                }),

            })
            if (!couponsRes.ok) {
                throw new Error('Failed to fetch users')
            }

            const data = await couponsRes.json()
            if (data) {
                // console.log(data)
                setCoupons(data)

            }
            else {
                console.log('no data')
                setRefresh(false)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    return <>

        <section className="frontCheckout frontGreyBg">
            <div className="webContainer">
                {/* Header */}
                <HeaderNavigation pageName={"Coupon"} refreshed={true} />


                <div className='row'>
                    <div className="col-sm-6 m-auto">
                        {/* Select */}
                        <div className='cartSummaryCard'>
                            <h4 className='font-weight-bold'>Select Below</h4>
                            <div class="accordion" id="accordionCoupons">
                                {coupons.map((item) =>

                                    <div class="card border-0">
                                        <div class="py-2" id="headingOne">
                                            <h2 class="mb-0">
                                                <button class="border-bottom font-weight-bold rounded-0 btn p-0 w-100 text-left" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne" onClick={()=>{navigate('/cart',{state:{item}})}} >
                                                    {item.code}
                                                </button>
                                            </h2>
                                        </div>

                                        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordionCoupons">
                                            <div class="card-body p-0">
                                                 <div dangerouslySetInnerHTML={{ __html: item.details }} />
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>

    </>
}

export default Coupon;