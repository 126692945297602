import Header from './components/Header';

const Refund = () => {


  return <>

    <Header />

    <section className="webContainer text-center">
        <h1 className='mt-5'>Refund Policy</h1>
    </section>

    <section className='webContainer mt-5'>
        <div className='policyContainer'>
            <p>
                This privacy policy describes how your personal information is collected, used and shared when you use the
            </p>
        </div>
    </section>

    </>
}

export default Refund