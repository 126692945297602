
import userDefault from '../assets/img/user.png'
import emailIcon from '../assets/icons/arroba.png'
import userIcon from '../assets/icons/user-2.png'
import shopIcon from '../assets/icons/shop.png'
import addressIcon from '../assets/icons/map.png'
import checkIcon from '../assets/icons/checked.png'
import { useEffect, useState, useRef } from 'react';
import { url } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import Date from '../utils/Date'
import HeaderNavigation from '../Front/components/HeaderNavigation';

const MyAccount = () => {
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [image, setImage] = useState('');
    const [ProfileData, setProfileData] = useState(null);
    const [addresses, setAddresses] = useState([]);
    const [myOrders, setMyOrders] = useState([]);
    const userId = localStorage.getItem('user_id')
    const customerId = localStorage.getItem('customer_id')
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");
    const [tabs, setTabs] = useState(0)

    useEffect(() => {
        if (role == 'customer' && token != null) {
            fetchDashboardData();
        }
        else {
            navigate('/customer-login')
        }
    }, [refresh]);

    const fetchDashboardData = async () => {
        try {
            const getDashboard = await fetch(`${url}/customer/profile/${userId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })
            if (!getDashboard.ok) {
                throw new Error('Failed to fetch users')
            }
            const data = await getDashboard.json()
            if (data) {
                // console.log(data)
                localStorage.setItem('customer_id', data.id)
                fetchMyOrders(data.id)
                setProfileData(data)
                setName(data.name)
                setEmail(data.email)
                setPhone(data.phone)
                setImage(data.image)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (tabs == 0) {
            fetchAddress()
        }
        else if (tabs == 1) {
            fetchMyOrders()
        }
    }, [tabs])


    const fetchMyOrders = async () => {
        try {
            const getMyOrders = await fetch(`${url}/customer/orders/${customerId}`, {
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            })

            if (!getMyOrders.ok) {
                throw new Error('Failed to fetch')
            }
            const data = await getMyOrders.json()
            if (data) {
                // console.log(data.order)
                setMyOrders(data?.order)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
            }

        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    const fetchAddress = async () => {
        try {
            const getAddressdata = await fetch(`${url}/customer/addresses/${customerId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },

            })
            if (!getAddressdata.ok) {
                throw new Error('Failed to fetch')
            }

            const addressData = await getAddressdata.json()
            if (addressData) {
                setAddresses(addressData)
                console.log(addressData)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
            }

        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    const [file, setFile] = useState('')

    function handleChange(event) {
        setFile(event.target.files[0])

    }



    const handleUpdateProfile = async (item) => {
        const formData = new FormData();
        // console.log(file,"kk")
        formData.append('user_id', userId);
        formData.append('name', name);
        formData.append('email', email); // Assuming slug is the same as name
        formData.append('phone', phone);
        if (file) {
            formData.append('image', file);
            console.log(file)
        }
        else {
            formData.append('image', image);
            console.log(image)

        }
        const updateProfile = await fetch(`${url}/customer/profile/update/`, {
            method: 'PUT',
            headers: {
                'Authorization': `Bearer ${token}`,
                // 'Content-Type': 'application/json',
                // 'content-type': 'multipart/form-data',
            },
            body: formData
        })
        const dataRes = await updateProfile.json()
        if (dataRes) {
            // console.log(dataRes, "removeCart")
            setRefresh(true)
            fetchDashboardData()
            setTabs(3)
        }
    };


    const handleDeleteAddress = async (id) => {
        // console.log(item)
        const updateProfile = await fetch(`${url}/customer/delete-address/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },

        })
        const dataRes = await updateProfile.json()
        if (dataRes) {
            // console.log(dataRes, "removeCart")
            setRefresh(true)
            fetchDashboardData()
            fetchAddress()
            setTabs(0)
        }
    };

    return <>

        <section className="frontCheckout frontGreyBg">
            <div className="webContainer">
                <HeaderNavigation pageName={'Profile'} refreshed={refresh} />
                <div className='row mt-3'>
                    <div className="col-sm-12">
                        <div className='card p-4 border-0'>
                            <div className='row'>
                                <div className='col-sm-2'>
                                    <img src={userDefault} alt='' className='userProfileImg' />
                                </div>
                                <div className='col-sm-4 border-lg-right'>
                                    <h4 className='mt-3'><b>Name: {ProfileData?.name}</b></h4>
                                    <h5 className='mt-3'>Email: {ProfileData?.email}</h5>
                                    <h5 className='mt-3'>Phone: {ProfileData?.phone}</h5>
                                </div>
                                <div className='col-sm-6'>
                                    <h5 className='mt-3 font-weight-light'>Joined: <Date apiDateTime={ProfileData?.created_at} /></h5>
                                </div>
                            </div>

                            <div className='row mt-5'>
                                <div className='col-12'>
                                    <div className='profileNavs'>
                                        <button onClick={() => { setTabs(0) }} className='btn text-left profileNavBtn'>
                                            Profile
                                        </button>
                                        <button onClick={() => { setTabs(1) }} className='btn text-left profileNavBtn'>
                                            My Orders
                                        </button>
                                        {/* <button onClick={() => { setTabs(2) }} className='btn text-left profileNavBtn'>
                                            My Subscriptions
                                        </button> */}
                                        <button onClick={() => { setTabs(3) }} className='btn text-left profileNavBtn'>
                                            Settings
                                        </button>
                                    </div>
                                </div>
                            </div>

                            {/*delivery address  */}
                            {tabs == 0 &&
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <h4><b>Your Addresses</b></h4>

                                        <div className='row mt-2'>
                                            <div className='col-sm-6'>
                                                {/* {addresses?.map((item) =>
                                                    <div className='profileAddress flex row '>
                                                        {item.address} <button onClick={() => { handleDeleteAddress(item.id) }} className='text-danger ml-5'>Delete</button>
                                                    </div>
                                                )} */}
                                                {addresses ?
                                                    <>
                                                    <div className="table-responsive">
                                                        <table className='table table-striped table-sm'>
                                                            {addresses?.map((item, i) =>
                                                                <tbody>
                                                                    <tr key={i}>
                                                                        <td className='text-left'>{item.address}</td>
                                                                        <td><button onClick={() => { handleDeleteAddress(item.id) }} className='text-danger btn'>Delete</button></td>
                                                                    </tr>
                                                                </tbody>
                                                            )}

                                                        </table>
                                                        </div>
                                                    </>
                                                    :
                                                    <div style={{ textAlign: "center" }}>No address found</div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* my orders */}
                            {
                                tabs == 1 &&
                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <h4><b>My Orders</b></h4>

                                        <div className='row mt-5'>
                                            <div className='col-12'>
                                                <div className='card p-2 w-100 shadow'>
                                                    {myOrders ?
                                                        <>
                                                        <div className="table-responsive">
                                                            <table className='table table-striped'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>Order No.</th>
                                                                        <th>Price</th>
                                                                        <th>Discount</th>
                                                                        <th>Quantity</th>
                                                                        <th>Order Status</th>
                                                                        <th>Delivery Date</th>
                                                                        <th>Payment Status</th>
                                                                    </tr>
                                                                </thead>
                                                                {myOrders?.map((order, i) =>
                                                                    <tbody>
                                                                        <tr key={i}>
                                                                            <td>{i + 1}</td>
                                                                            <td>{order.price}</td>
                                                                            <td>{order.discount}</td>
                                                                            <td>{order.qty}</td>
                                                                            <td>{order.order_status}</td>

                                                                            <td >
                                                                                <Date apiDateTime={order.created_at} />
                                                                            </td>
                                                                            <td style={{ backgroundColor: order.payment_status == 'Paid' ? "green" : "orange", color: "#fff" }}>{order.payment_status}</td>
                                                                        </tr>
                                                                    </tbody>
                                                                )}

                                                            </table>
                                                            </div>
                                                        </>
                                                        :
                                                        <div style={{ textAlign: "center" }}>Currently no orders</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* subscriptions */}
                            {/* {tabs == 2 &&

                                <div className='row mt-5'>
                                    <div className='col-12'>
                                        <h4><b>Subscriptions</b></h4>

                                        <div className='row mt-5'>
                                        </div>
                                    </div>
                                </div>

                            } */}


                            {/* settings */}
                            {tabs == 3 &&

                                <div className='mt-5'>
                                    <div className='col-12'>
                                        <h4><b>Settings</b></h4>
                                        <div className='mt-5'>


                                            <div className='row'>
                                                <div className='col-sm-7'>

                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <div className="input-group frontInputBox">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text frontFromIcon">
                                                                        <img src={userIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control frontFormInput"
                                                                    id="name"
                                                                    name="name"
                                                                    placeholder="Your Name"
                                                                    autoComplete="off" required
                                                                    value={name} onChange={(e) => setName(e.target.value)}
                                                                />
                                                            </div>
                                                            {/* Single Input */}
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="input-group frontInputBox">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text frontFromIcon">
                                                                        <img src={emailIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <input type="email" className="form-control frontFormInput"
                                                                    id="email"
                                                                    name="email"
                                                                    placeholder="Your Email"
                                                                    autoComplete="off" required
                                                                    value={email} onChange={(e) => setEmail(e.target.value)}
                                                                />
                                                            </div>
                                                            {/* Single Input */}
                                                        </div>
                                                    </div>
                                                    {/* ./Row End Name and Email */}

                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <div className="input-group frontInputBox">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text frontFromIcon">
                                                                        <img src={shopIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <input type="text" className="form-control frontFormInput"
                                                                    id="phone"
                                                                    name="phone"
                                                                    placeholder="Your phone number"
                                                                    autoComplete="off" required
                                                                    value={phone} onChange={(e) => setPhone(e.target.value)}
                                                                />
                                                            </div>
                                                            {/* ./Single Input */}
                                                        </div>

                                                    </div>
                                                    {/* ./Row End Restaurant Name */}

                                                    <div className='row'>
                                                        <div className='col-sm-12'>
                                                            <div className="input-group frontInputBox">
                                                                <div className="input-group-prepend">
                                                                    <span className="input-group-text frontFromIcon">
                                                                        <img src={addressIcon} alt="" />
                                                                    </span>
                                                                </div>
                                                                <input type="file" className="form-control frontFormInput"
                                                                    id="image"
                                                                    name="image"
                                                                    placeholder="Upload your image"
                                                                    autoComplete="off" required
                                                                    onChange={handleChange}
                                                                />
                                                            </div>
                                                            {/* ./Single Input */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex justify-content-start">
                                                <button className="btn frontFormBtn" onClick={handleUpdateProfile}  >
                                                    <div className="frontBtnIcon" >
                                                        <img src={checkIcon} alt="" />
                                                    </div>
                                                    <p>Update</p>
                                                </button>
                                                {/* <div className="justify-center">{msg}</div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            }
                        </div>

                    </div>
                </div>

            </div>
        </section>

    </>
}

export default MyAccount;