import '../styles/style.css'
import logo from '../../assets/img/logo.png'
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';


const Header = () => {
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState('default')
    const userRole = localStorage.getItem('role')
    const token = localStorage.getItem('token')

    useEffect(() => {
        if (userRole === 'admin') {
            if (token) {
                setIsLoggedIn('admin')
            }
        } else if (userRole === 'vendor') {
            if (token) {
                setIsLoggedIn('vendor')
            }
        } else if (userRole === 'customer') {
            if (token) {
                setIsLoggedIn('customer')
            }
        } else {
            setIsLoggedIn('default')
        }
    }, [userRole, token])
    // console.log(isLoggedIn)

    const handleLogOut = () => {
        localStorage.clear()
        window.location.reload()
    }
    return (
        <header className="border-bottom">
            <div className="webContainer">
                <nav className="navbar navbar-expand-lg navbar-light bg-white py-3">
                    <a className="navbar-brand" href="/">
                        <img src={logo} className='logo' alt='logo' />
                    </a>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarScroll" aria-controls="navbarScroll" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarScroll">
                        <ul className="navbar-nav ml-auto my-2 my-lg-0 navbar-nav-scroll">
                            <li className="nav-item">
                                <a className="nav-link frontNavLink" href="/">Home</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link frontNavLink" href="/">For Restaurants</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link frontNavLink" href="/">About</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link frontNavLink" href="/">Contact</a>
                            </li>
                            {isLoggedIn === 'vendor' || isLoggedIn === 'customer' || isLoggedIn === 'admin' ?
                                <li className="nav-item">
                                    <a className="nav-link frontNavLink btn frontGetStartedBtn" href={isLoggedIn === 'vendor' ? '/vendor' : isLoggedIn === 'customer' ? '/my-account' : isLoggedIn === 'admin' ? '/admin' : ''}>Dashboard</a>
                                </li>
                                :
                                <li className="nav-item">
                                    <a className="nav-link frontNavLink" onClick={()=>{navigate("/login")}}>Login</a>
                                </li>
                            }
                            {isLoggedIn === 'vendor' || isLoggedIn === 'customer' || isLoggedIn === 'admin' ?
                                <li className="nav-item">
                                    <button className="btn logoutedBtn my-2 my-sm-0" onClick={handleLogOut}>Logout</button>
                                </li>
                                :
                                <li className="nav-item">
                                    <button className="btn frontGetStartedBtn my-2 my-sm-0" onClick={()=>{navigate('/vendor/register')}}>Get Started</button>
                                </li>
                            }

                        </ul>
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header