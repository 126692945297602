import '../styles/style.css'
import backArrow from '../../assets/icons/back.png'
import carts from '../../assets/icons/cart.png'
import { url } from '../../utils/Helper';

import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
const HeaderNavigation = ({ pageName, refreshed }) => {
    const navigate = useNavigate();
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState('');
    const deviceToken = localStorage.getItem('deviceToken')
    const vendorPage = localStorage.getItem('vendorPage')


    useEffect(() => {
        getCart()
    }, []);



    useEffect(() => {
        if (refreshed) {
            getCart()
        }
    }, [refreshed]);




    const getCart = async () => {
        try {
            const getCartData = await fetch(`${url}/front/getCartItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "device": deviceToken
                }),
            })
            const data = await getCartData.json()
            if (data) {
                setCart(data[0]?.qty)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
                setCart(0)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }

    }

    return (
        <header >

            <div className="row pt-5">
                <div className='col-lg-1 col-2'>
                    <button className='frontBackBtn border-0' onClick={() => {
                        if (pageName == "Select address") {
                            navigate("/cart")
                        } else if (pageName == "Payment") {
                            navigate("/select-address")
                        } else if (pageName == "Checkout") {
                            navigate("/cart")
                        }
                        else if (pageName == "Profile") {
                            navigate("/cart")
                        }
                        else if (pageName == "Cart") {
                            navigate(vendorPage)
                        }
                        else {
                            if (vendorPage != null) {
                                navigate(vendorPage)
                            }
                            else {
                                navigate(-1)
                            }
                        }

                    }}>
                        <img src={backArrow} alt="back"/>
                    </button>
                </div>
                <div className='col-lg-10 col-8'>
                    <h1 className='pageName font-weight-bold mt-2 text-center'>{pageName}</h1>
                </div>

                <div className='col-lg-1 col-2' onClick={() => {
                    navigate("/cart")
                }}>
                    <span className='frontCartCount'>
                        <span>{cart?.split('.')[0] || 0}</span>
                    </span>
                    <div className='frontCartBtn'>
                        <img src={carts} alt="cart" />
                    </div>
                </div>

            </div>
        </header>
    )
}

export default HeaderNavigation