import React, { useEffect, useState } from 'react'
import { url } from "../../../utils/Helper"
import image from "../../img/userimage.png"
import DateTime from "../../../utils/DateTime"
import { handleUpdateVendorProfile } from '../../Service';
// import OrderList from "./OrderList"

const VendorProfile = ({ userId }) => {
  const [vendorProfile, setVendorProfile] = useState(null);
  const [vendorSettings, setVendorSettings] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [approvedUser, setApprovedUser] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [pincode, setPincode] = useState('');
  const [country, setCountry] = useState('');
  const [resturant, setResturant] = useState('');
  const [mealType, setMealType] = useState('');
  const [numberOfMeals, setNumberOfMeals] = useState();
  const [monthlyActiveDays, setMonthlyActiveDays] = useState();
  const [deliveryInADay, setDeliveryInADay] = useState();
  const token = localStorage.getItem('token')

  useEffect(() => {
    const fetchVendorProfile = async () => {
      try {
        const vendorProfile = await fetch(`${url}/admin/users/vendor-profile/${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        if (!vendorProfile.ok) {
          throw new Error('Failed to fetch users')
        }
        const data = await vendorProfile.json()
        // console.log(data, "vender")
        setVendorProfile(data)
        setName(data?.name)
        setResturant(data?.resturant)
        setEmail(data?.email)
        setPhone(data?.phone)
        setAddress(data?.address)
        setPincode(data?.pincode)
        setCountry(data?.country)
        setApprovedUser(data?.approved)
        setRefresh(false)
        // Get Customer Address
        const vendorSettings = await fetch(`${url}/admin/users/vendor-settings/${data.id}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        if (!vendorSettings.ok) {
          throw new Error('Failed to fetch data');
        }
        const data2 = await vendorSettings.json();
        // console.log(data2)
        setVendorSettings(data2);
        setMealType(data2?.meal_type)
        setNumberOfMeals(data2?.no_of_meals)
        setMonthlyActiveDays(data2?.monthly_active_days)
        setDeliveryInADay(data2?.no_of_delivery_in_day)
        setRefresh(false)
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchVendorProfile();
  }, [userId, refresh, token]);


  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };


  const addUser = async () => {
    const sendStatusData = {
      token: token,
      user_id: userId,
      name: name,
      email: email,
      phone: phone,
      address: address,
      pincode: pincode,
      country: country,
      vendor_id: vendorProfile.id,
      resturant: resturant,
      meal_type: mealType,
      no_of_meals: numberOfMeals,
      monthly_active_days: monthlyActiveDays,
      no_of_delivery_in_day: deliveryInADay

    }
    try {
      // console.log(sendStatusData)
      await handleUpdateVendorProfile(sendStatusData)
      // console.log(res, 'hell')
      setRefresh(true)
      toggleVisibility()
      // Redirect or perform any other necessary actions
    } catch (error) {
      console.error('Login error:', error.message)
    }
  }

  const handleBanUser = async () => {
    try {
      const apiUrl = approvedUser === false
        ? `${url}/admin/users/allow-user/${userId}`
        : `${url}/admin/users/ban-user/${userId}`;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },

      })
      const resData = await response.json()
      // console.log(resData, 'ban user')
      setRefresh(true)
      // setApprovedUser(!approvedUser)
      return resData // API returns success data
    } catch (error) {
      console.error('Error in getting data', error.message)
      throw error
    }
  }


  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <div className='card shadow p-3'>
        <div className='row mb-3'>
          <div className='col-sm-2'>
            <img className='card-img-top user-image w-100' src={image} alt='User' />
          </div>

          <div className='col-sm-4'>
            <div className='card-body'>
              <h5 className='card-title'><b>Name:</b> {vendorProfile?.name}</h5>
              <p className='card-text'><b>Email:</b> {vendorProfile?.email}</p>
              <p className="card-text"><b>Phone:</b> {vendorProfile?.phone}</p>
              <p className="card-text"><b>Register Date:</b> <DateTime apiDateTime={vendorProfile.created_at} /></p>
            </div>
          </div>
          <div className='col-sm-5'>
            <div className='card-body'>
              <h5 className="card-title"><b>Code:</b> {vendorProfile?.vendor_code}</h5>
              <p className='card-text'><b>Address:</b> {vendorProfile?.address}</p>
              <p className='card-text'><b>Pincode:</b> {vendorProfile?.pincode}</p>
              <p className="card-text"><b>Country:</b> {vendorProfile?.country}</p>
            </div>
          </div>
          <div className='col-sm-1'>
            <div className=' text-right'>
              <button className='btn btn-sm rounded btn-warning' onClick={toggleVisibility}>Edit</button>
            </div>
            <div className='mt-2 text-right'>
              <button className='btn btn-sm rounded btn-danger' onClick={handleBanUser}>{approvedUser === true ? 'Ban' : 'Allow'}</button>
            </div>
          </div>
        </div>
        <hr />
        <table>
          <thead>
            <tr>
              <th>Resturant</th>
              <th>Meal Type</th>
              <th>No. Of Meals In A Day</th>
              <th>Monthly Active Days</th>
              <th>Delivery In A Day</th>
            </tr>
          </thead>
          <tbody>
            <tr key={vendorSettings?.id}>
              <td>{vendorProfile?.resturant}</td>
              <td>{vendorSettings?.meal_type}</td>
              <td>{vendorSettings?.no_of_meals}</td>
              <td>{vendorSettings?.monthly_active_days}</td>
              <td>{vendorSettings?.no_of_delivery_in_day}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='card shadow p-2'>
        <div className='row mb-3'>
          <div className='col-12'>
            <button className='btn btn-info'>Get Order List</button>
          </div>
          <div className='col-12'>
            {/* <OrderList id={vendorProfile.id} /> */}
          </div>
        </div>

      </div>

      {isVisible && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={toggleVisibility}>
              &times;
            </span>
            <div >
              <div className="row">
                <div className="col">
                  <label htmlFor="name">Name:</label>
                  <input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} required />
                </div>
                <div className="col">
                  <label htmlFor="email">Email:</label>
                  <input type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="resturant">Resturant Name:</label>
                  <input type="text" id="resturant" value={resturant} onChange={(e) => setResturant(e.target.value)} required />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="phone">Phone:</label>
                  <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                </div>
                <div className="col">
                  <label htmlFor="address">Address:</label>
                  <input type="text" id="address" value={address} onChange={(e) => setAddress(e.target.value)} required />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="pincode">Pincode:</label>
                  <input type="text" id="pincode" value={pincode} onChange={(e) => setPincode(e.target.value)} required />
                </div>
                <div className="col">
                  <label htmlFor="country">Country:</label>
                  <input type="text" id="country" value={country} onChange={(e) => setCountry(e.target.value)} required />
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <label htmlFor="mealType">Meal Type:</label>
                  <select id="mealType" value={mealType} onChange={(e) => setMealType(e.target.value)}>
                    <option >{mealType}</option>
                    <option value="veg">Vegetarian</option>
                    <option value="nonveg">Non-Vegetarian</option>
                  </select>
                </div>
                <div className="col">
                  <label htmlFor="numberOfMeals">No. Of Meals In A Day:</label>
                  <input
                    type="number"
                    id="numberOfMeals"
                    value={numberOfMeals}
                    onChange={(e) => setNumberOfMeals(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <label htmlFor="monthlyActiveDays">Monthly Active Days:</label>
                  <input
                    type="number"
                    id="monthlyActiveDays"
                    value={monthlyActiveDays}
                    onChange={(e) => setMonthlyActiveDays(e.target.value)}
                    required
                  />
                </div>
                <div className="col">
                  <label htmlFor="deliveryInADay">Delivery In A Day:</label>
                  <input
                    type="number"
                    id="deliveryInADay"
                    value={deliveryInADay}
                    onChange={(e) => setDeliveryInADay(e.target.value)}
                    required
                  />
                </div>
              </div>
              <button className='col mt-5' onClick={addUser}>Submit</button>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default VendorProfile;