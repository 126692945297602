import Header from '../../../Front/components/Header'
// Images
import checkIcon from '../../../assets/icons/checked.png'
import emailIcon from '../../../assets/icons/arroba.png'
import userIcon from '../../../assets/icons/user-2.png'
import shopIcon from '../../../assets/icons/shop.png'
import addressIcon from '../../../assets/icons/map.png'
import pincodeIcon from '../../../assets/icons/mail.png'
import countryIcon from '../../../assets/icons/countries.png'
import Footer from "../../../Front/components/Footer"
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'


const CompleteProfile = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [country, setCountry] = useState('');
    const [restaurant, setrestaurant] = useState('');


    const handleSendData = () => {
        const data = {
            name: name,
            email: email,
            phone: state.phoneNumber,
            address: address,
            pincode: pincode,
            country: country,
            restaurant: restaurant
        }
        navigate('/vendor/register/meal-information', { state: data })

        
    }


    return (
        <>
            <Header />
            <section className="frontLogin frontGreyBg">
                <div className="webContainer">
                    <div className="row pt-1">
                        <div className="col-sm-7 m-auto">
                            <h5 className='frontSectionTitle text-center mt-5 mb-0'>Complete Your Profile</h5>
                            <br />

                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={userIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="name"
                                            name="name"
                                            placeholder="Your Name"
                                            autoComplete="off"
                                            value={name} onChange={(e) => setName(e.target.value)} required
                                        />
                                    </div>
                                    {/* Single Input */}
                                </div>
                                <div className='col-sm-6'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={emailIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="email"
                                            name="email"
                                            placeholder="Your Email"
                                            autoComplete="off"
                                            value={email} onChange={(e) => setEmail(e.target.value)} required
                                        />
                                    </div>
                                    {/* Single Input */}
                                </div>
                            </div>
                            {/* ./Row End Name and Email */}

                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={shopIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="restaurant_name"
                                            name="restaurant_name"
                                            placeholder="Your Restaurant Name"
                                            autoComplete="off"
                                            value={restaurant} onChange={(e) => setrestaurant(e.target.value)}  required
                                        />
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>

                            </div>
                            {/* ./Row End Restaurant Name */}

                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={addressIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="address"
                                            name="address"
                                            placeholder="Restaurant Address"
                                            autoComplete="off"
                                            value={address} onChange={(e) => setAddress(e.target.value)} required
                                        />
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>
                            </div>
                            {/* ./Row End Restaurant Address */}

                            <div className='row'>
                                <div className='col-sm-6'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={pincodeIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="pincode"
                                            name="pincode"
                                            placeholder="Pincode"
                                            autoComplete="off"
                                            value={pincode} onChange={(e) => setPincode(e.target.value)} required
                                        />
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>
                                <div className='col-sm-6'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={countryIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" className="form-control frontFormInput"
                                            id="country"
                                            name="country"
                                            placeholder="Country"
                                            autoComplete="off"
                                            value={country} onChange={(e) => setCountry(e.target.value)} required
                                        />
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>
                            </div>
                            {/* ./Row End Restaurant Address */}

                            <div className="d-flex justify-content-center">
                                <button className="btn frontFormBtn" onClick={handleSendData}>
                                    <div className="frontBtnIcon" >
                                        <img src={checkIcon} alt="" />
                                    </div>
                                    <p>Add Restaurant</p>
                                </button>
                            </div>



                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>

    )
}

export default CompleteProfile