import Header from './components/Header';
import cook from '../assets/img/cook.png';
import howItWorks from '../assets/img/how.png';
import howItWorksMobile from '../assets/img/howmobile.png';
import manageOrder from '../assets/icons/order.png';
import schedule from '../assets/icons/schedule.png';
import payment from '../assets/icons/payment.png';
import about from '../assets/img/about.jpg';
import sendMail from '../assets/icons/user.png';
import LogoSlider from './components/LogoSlider';

import { useNavigate } from 'react-router-dom';

const Home = () => {

  const navigate = useNavigate()

  return <>

    <Header />

    {/* Hero Section */}
    <section className="webContainer text-center">
      <h1 className="frontMainHeading">Welcome to Tiffinbox</h1>
      <p className="frontSubHeading">
        The UK's first meal planner service for restaurants. Manage your meals, orders, deliveries, and more with ease.
      </p>
      <a className="btn frontGetStartedBtn mt-3 mb-5" onClick={() => { navigate('/vendor/register') }}>Get Started</a>
      <br />
      <img src={cook} className="text-center frontBannerImg" alt='' />
    </section>

    <LogoSlider />

    {/* Why Join Us */}
    <section className='webContainer mt-5'>

      <h2 className='frontSectionTitle'>Why Choose Tiffinbox?</h2>

      <div className='row mt-5'>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={manageOrder} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Order Management System</h4>
          <p className='frontPointDetails'>Share Menu for the week with your customers on Whatsapp and get pre-orders</p>
        </div>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={schedule} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Increase Sales in DownTime</h4>
          <p className='frontPointDetails'>Get orders from loyal customer base in morning less rush hour</p>
        </div>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={payment} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Take & Track Payments</h4>
          <p className='frontPointDetails'>Take and track payments online</p>
        </div>
      </div>

    </section>

    {/* How It Works */}
    <section className='webContainer frontHowitWorks'>
      <h2 className='frontSectionTitle'>How It Works?</h2>
      <div className='row mt-5'>
        <div className='col-12 py-5 desktop'>
          <img src={howItWorks} alt='' className='w-auto' />
        </div>
        <div className='col-12 mobile'>
          <img src={howItWorksMobile} alt='' className='w-auto' />
        </div>
      </div>
    </section>

    {/* Counts */}
    {/* <section className='webContainer my-5'>
      <div className='row'>
        <div className='col-sm-4'>
          <div className='d-flex justify-content-center'>
            <div className='w-50'>
              <h5 className='frontCount text-right'>99</h5>
            </div>
            <div className='w-50'>
              <h5 className='h3 fw-bold mt-3 ml-2 frontCountText'>Vendor Registered</h5>
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='d-flex justify-content-center'>
            <div className='w-50'>
              <h5 className='frontCount text-right'>200</h5>
            </div>
            <div className='w-50'>
              <h5 className='h3 fw-bold mt-3 ml-2 frontCountText'>Location Covered</h5>
            </div>
          </div>
        </div>

        <div className='col-sm-4'>
          <div className='d-flex justify-content-center'>
            <div className='w-50'>
              <h5 className='frontCount text-right'>2000</h5>
            </div>
            <div className='w-50'>
              <h5 className='h3 fw-bold mt-3 ml-2 frontCountText'>Order Delivered</h5>
            </div>
          </div>
        </div>
      </div>
    </section> */}

    {/* Freshly Delivered */}
    {/* <section className='webContainer mt-5 frontHowitWorks'>

      <h2 className='frontSectionTitle'>Freshly Delivered</h2>

      <div className='row mt-5'>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={manageOrder} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Manage Orders On The Go</h4>
          <p className='frontPointDetails'>Lorem ipsum is placeholder text commonly used in the graphic.</p>
        </div>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={schedule} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Get Pre Orders From Customers</h4>
          <p className='frontPointDetails'>Lorem ipsum is placeholder text commonly used in the graphic.</p>
        </div>
        <div className='col-sm-4 text-center'>
          <div className='frontImgPoint mx-auto'>
            <img src={payment} className='' alt='' />
          </div>
          <h4 className='frontPointHeading'>Take Payments & Create Orders</h4>
          <p className='frontPointDetails'>Lorem ipsum is placeholder text commonly used in the graphic.</p>
        </div>
      </div>

    </section> */}

    {/* About Us */}
    <section className='webContainer my-5'>
      <div className='row'>
        <div className='col-sm-5'>
          <img src={about} alt='' className='img-fluid' />
        </div>

        <div className='col-sm-7'>
          <h2 className='frontSectionTitle text-left'>About Us</h2>
          <p className='frontAboutText'>An order management platform for connecting customers with delicious, homemade meals from local tiffin service providers. Founded to revolutionise the way people enjoy healthy & delicious food, we offer a convenient platform for restros, cloud kitchens and customers alike.
            <br />
          </p>
          <h4>
            Our Mission</h4>
          <p className='frontAboutText'>
            Our mission at Tiffinbox is simple: to make healthy eating effortless and enjoyable for everyone. We believe that everyone deserves access to fresh, homemade meals made with quality ingredients. By offering a diverse menu of carefully curated dishes, we aim to cater to varying tastes and dietary preferences while promoting overall wellness and satisfaction.
          </p>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <h4>Sustainability Commitment</h4>
          <p className='frontAboutText'>
            We are dedicated to sustainability and minimising our environmental footprint. From eco-friendly packaging materials to responsible sourcing practices, we prioritise sustainability at every step of our operation. By choosing Tiffinbox, you're not just enjoying delicious meals – you're also supporting a greener, more sustainable future.
          </p>
          <h4>Join Us</h4>
          <p className='frontAboutText'>
            Join us on a culinary journey of flavors and freshness, where every meal is a delightful experience. Whether you're a busy professional, a health-conscious individual, or a family seeking wholesome meals, Tiffinbox is here to satisfy your cravings and simplify your life. Discover the convenience and joy of homemade food delivered with love – order from Tiffinbox today!</p>
        </div>
      </div>
    </section>

    {/* FAQ's Section*/}
    <section className='webContainer my-5'>

      <h2 className='frontSectionTitle'>FAQs</h2>

      <div className="accordion" id="faqAccordion">
        <div className="card border-0 frontAccordianCard mb-3 shadow-sm">
          <div className="border-bottom" id="headingOne">
            <h4 className="mb-0">
              <div className="font-weight-bold btn w-100 px-3 text-left my-3" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                What is Tiffinbox?
              </div>
            </h4>
          </div>

          <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#faqAccordion">
            <div className="card-body p-3">
              Tiffinbox is a food order management platform that connects tiffin service providers with customers. Food Providers can list their meals, and customers can order fresh, homemade food delivered to their door.
            </div>
          </div>
        </div>
      </div>

      <div className="accordion" id="faqAccordion">
        <div className="card border-0 frontAccordianCard mb-3 shadow-sm">
          <div className="border-bottom" id="heading2">
            <h4 className="mb-0">
              <div className="font-weight-bold btn w-100 px-3 text-left my-3" data-toggle="collapse" data-target="#collapse2" aria-expanded="true" aria-controls="collapse2">
                How do I sign up as a tiffin service provider?
              </div>
            </h4>
          </div>

          <div id="collapse2" className="collapse" aria-labelledby="heading2" data-parent="#faqAccordion">
            <div className="card-body p-3">
              Visit our sign-up page and fill out the registration form. Once approved, you can start listing your meals and receiving orders.
            </div>
          </div>
        </div>
      </div>


      <div className="accordion" id="faqAccordion">
        <div className="card border-0 frontAccordianCard mb-3 shadow-sm">
          <div className="border-bottom" id="heading3">
            <h4 className="mb-0">
              <div className="font-weight-bold btn w-100 px-3 text-left my-3" data-toggle="collapse" data-target="#collapse3" aria-expanded="true" aria-controls="collapse3">
                Can customers pre-order meals?
              </div>
            </h4>
          </div>

          <div id="collapse3" className="collapse" aria-labelledby="heading3" data-parent="#faqAccordion">
            <div className="card-body p-3">
              Yes, customers can place pre-orders, allowing tiffin service providers to better manage their resources and prepare meals in advance.
            </div>
          </div>
        </div>
      </div>



      <div className="accordion" id="faqAccordion">
        <div className="card border-0 frontAccordianCard mb-3 shadow-sm">
          <div className="border-bottom" id="heading4">
            <h4 className="mb-0">
              <div className="font-weight-bold btn w-100 px-3 text-left my-3" data-toggle="collapse" data-target="#collapse4" aria-expanded="true" aria-controls="collapse4">
                How are payments processed?
              </div>
            </h4>
          </div>

          <div id="collapse4" className="collapse" aria-labelledby="heading4" data-parent="#faqAccordion">
            <div className="card-body p-3">
              Payments are securely processed through our integrated payment system. Customers can pay online, and providers receive payments directly to their accounts.

            </div>
          </div>
        </div>
      </div>



      <div className="accordion" id="faqAccordion">
        <div className="card border-0 frontAccordianCard mb-3 shadow-sm">
          <div className="border-bottom" id="heading5">
            <h4 className="mb-0">
              <div className="font-weight-bold btn w-100 px-3 text-left my-3" data-toggle="collapse" data-target="#collapse5" aria-expanded="true" aria-controls="collapse5">
                What areas do you cover?
              </div>
            </h4>
          </div>

          <div id="collapse5" className="collapse" aria-labelledby="heading5" data-parent="#faqAccordion">
            <div className="card-body p-3">
              We currently cover multiple locations across London, ensuring a wide reach for our tiffin service providers.
            </div>
          </div>
        </div>
      </div>

    </section>

    {/* Contact Us */}
    <section className='webContainer my-5'>
      <div className='row'>
        <div className='col-sm-6'>
          <h2 className='frontSectionTitle text-left mt-5'>Contact Us</h2>
          <p className='frontContactDetails mb-2'>Email: nakulgarg2017@gmail.com</p>
          <p className='frontContactDetails mb-2'>Phone: +44 7522563309</p>
          <p className='frontContactDetails mb-2'>Address: 124 City Road, London, England, EC1V 2NX</p>
        </div>
        <div className='col-sm-6'>
          <div className='frontFormCard'>
            <h5 className='font-weight-bold'>Fill the form below. We will get in touch with you.</h5>

            <div className='form-group'>
              <label className='frontLabel'>Complete Name*</label>
              <input type='text' className='form-control border-0' name='name' required />
            </div>

            <div className='form-group'>
              <label className='frontLabel'>Your Email*</label>
              <input type='email' className='form-control border-0' name='email' autoComplete='off' required />
            </div>

            <div className='form-group'>
              <label className='frontLabel'>Subject*</label>
              <input type='text' className='form-control border-0' name='subject' required />
            </div>

            <div className='form-group'>
              <label className='frontLabel'>Message*</label>
              <textarea className='form-control border-0' rows={7} name='message' required></textarea>
            </div>

            <div className='form-group'>
              <div className="d-flex justify-content-start">
                <button className="btn frontFormBtn">
                  <div className="frontBtnIcon" >
                    <img src={sendMail} alt="" />
                  </div>
                  <p>Send</p>
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>

    <section className='frontMap'>
      <div className="w-100"><iframe width="100%" height="600" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=124%20City%20Road,%20London,%20England,%20EC1V%202NX+(CROWDBAZAAR%20LIMITED)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps systems</a></iframe></div>
    </section>


    {/* Footer */}

    <footer className='webContainer my-3'>
      <div className='row'>
        <div className='col-sm-6'>
          <p className='copyWrite'>Copyright 2024 | Tiffinbox</p>
        </div>
        <div className='col-sm-6'>
          <ul className="nav frontFooterNav">
            <li className="nav-item">
              <a className="nav-link text-dark" onClick={() => { navigate('/privacy-policy') }}>Privacy Policy</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" onClick={() => { navigate('/terms-of-use') }}>Terms Of Use</a>
            </li>
            <li className="nav-item">
              <a className="nav-link text-dark" onClick={() => { navigate('/refund-policy') }}>Refund Policy</a>
            </li>
          </ul>
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-12'>
          <p className='frontPoweredBy'>Powered By CROWDBAZAAR LIMITED</p>
        </div>
      </div>
    </footer>


  </>
}

export default Home
