import React, { useEffect, useState } from 'react'
import { url ,Domain } from "../../../utils/Helper"
import image from "../../img/userimage.png"
import DateTime from "../../../utils/DateTime"


const CustomerProfile = ({ userId }) => {
    const [customerProfile, setCustomerProfile] = useState(null)
    const [customerAddress, setCustomerAddress] = useState([])
    const [loading, setLoading] = useState(true)
    const [refresh, setRefresh] = useState(false);
    const [approvedUser, setApprovedUser] = useState(false);
    const token = localStorage.getItem('token')

    useEffect(() => {
        const fetchCustomerProfile = async () => {
            try {
                const token = localStorage.getItem('token')
                // Get Customer Profile
                const customerProfile = await fetch(`${url}/admin/users/customer-profile/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                if (!customerProfile.ok) {
                    throw new Error('Failed to fetch user')
                }
                const data = await customerProfile.json()
                // console.log(data)
                setCustomerProfile(data)
                setApprovedUser(data.approved)

                // Get Customer Address
                const customerAddress = await fetch(`${url}/admin/users/customer-address/${data.id}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                if (!customerAddress.ok) {
                    throw new Error('Failed to fetch data');
                }
                const data2 = await customerAddress.json();
                // console.log(data2)
                setCustomerAddress(data2);

            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }
        fetchCustomerProfile()

    }, [userId, refresh])


    const handleBanUser = async () => {
        try {
            const apiUrl = approvedUser === false
                ? `${url}/admin/users/allow-user/${userId}`
                : `${url}/admin/users/ban-user/${userId}`;
            const response = await fetch(apiUrl, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },

            })
            const resData = await response.json()
            // console.log(resData, 'ban user')
            setRefresh(true)
            // setApprovedUser(!approvedUser)
            return resData // API returns success data
        } catch (error) {
            console.error('Error in getting data', error.message)
            throw error
        }
    }


    if (loading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <div className='card shadow p-3'>
                <div className='row mb-3'>
                    {/* <div className='col-sm-2'>
                        <img className='card-img-top user-image w-100'  src={`${Domain}${customerProfile.image}`} alt='User' />
                    </div> */}
                    <div className='col-sm-2'>
                        <img className='card-img-top user-image w-100' src={image} alt='User' />
                    </div>

                    <div className='col-sm-9'>
                        <div className='card-body'>
                            <h5 className='card-title'><b>Name:</b> {customerProfile?.name}</h5>
                            <p className='card-text'><b>Email:</b> {customerProfile?.email}</p>
                            <p className="card-text"><b>Phone:</b> {customerProfile?.phone}</p>
                            <p className="card-text"><b>Register Date:</b> <DateTime apiDateTime={customerProfile?.created_at} /></p>
                        </div>
                    </div>

                    <div className='col-sm-1'>
                        <div className='text-right'>
                            <button className='btn btn-sm rounded btn-warning' type='submit'>Edit</button>
                        </div>
                        <div className='mt-2 text-right'>
                            <button className='btn btn-sm rounded btn-danger' onClick={handleBanUser}>{approvedUser === true ? 'Ban' : 'Allow'}</button>
                        </div>
                    </div>
                </div>
                <hr />

                <div className='row mb-3'>

                    {customerAddress?.map((item, index) => (
                        <div key={index} className='col-sm-6'>
                            <div className='card-body'>
                                <p className='card-text'><b>Address:</b> {item.address}</p>
                                <p className='card-text'><b>Pincode:</b> {item.pincode}</p>
                                <p className="card-text"><b>Added At:</b> <DateTime apiDateTime={item.created_at} /></p>
                            </div>
                        </div>
                    ))}

                </div>



            </div>
        </>
    )
}

export default CustomerProfile