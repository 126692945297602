import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper"
// image
import checkIcon from '../../../assets/icons/checked.png'
import emailIcon from '../../../assets/icons/arroba.png'
import userIcon from '../../../assets/icons/user-2.png'
import shopIcon from '../../../assets/icons/shop.png'
import addressIcon from '../../../assets/icons/map.png'
import pincodeIcon from '../../../assets/icons/mail.png'
import countryIcon from '../../../assets/icons/countries.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import totalMeals from '../../../assets/icons/choice.png'
import workingDays from '../../../assets/icons/calendar.png'
import deliveriesInADay from '../../../assets/icons/fast-delivery.png'
import { useState, useEffect } from "react"
import { handleUpdateVendorSettings } from "../Service"

const Setting = () => {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(false);
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [address, setAddress] = useState('');
    const [pincode, setPincode] = useState('');
    const [country, setCountry] = useState('');
    const [resturant, setResturant] = useState('');
    const [mealType, setMealType] = useState('');
    const [numberOfMeals, setNumberOfMeals] = useState('');
    const [monthlyActiveDays, setMonthlyActiveDays] = useState('');
    const [deliveryInADay, setDeliveryInADay] = useState('');
    const [vendorProfile, setVendorProfile] = useState(null);
    const token = localStorage.getItem('token')
    const userId = localStorage.getItem('user_id')



    useEffect(() => {
        const fetchVendorProfile = async () => {

            try {
                const vendorProfile = await fetch(`${url}/vendor/profile/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })

                const data = await vendorProfile.json()
                if(data){
                setVendorProfile(data)
                setName(data.name)
                setResturant(data.restaurant)
                setEmail(data.email)
                setPhone(data.phone)
                setAddress(data.address)
                setPincode(data.pincode)
                setCountry(data.country)
                setMealType(data.meal_type)
                setNumberOfMeals(data.no_of_meals)
                setMonthlyActiveDays(data.monthly_active_days)
                setDeliveryInADay(data.no_of_delivery_in_day)
                setRefresh(false)
                }
                // Get Customer Address

            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }
        setTimeout(() => {
            fetchVendorProfile();
          }, 2000);
          setLoading(true)
    }, [userId, refresh, token]);

    const handleUpdateVendorSetting = async () => {
        const formattedName = resturant.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
        const slug = `${formattedName}-${pincode.replaceAll(' ','')}`;
        const sendStatusData = {
            token: token,
            user_id: userId,
            name: name,
            slug: slug,
            email: email,
            phone: phone,
            address: address,
            pincode: pincode,
            country: country,
            vendor_id: vendorProfile?.id,
            resturant: resturant,
            meal_type: mealType,
            no_of_meals: numberOfMeals,
            monthly_active_days: monthlyActiveDays,
            no_of_delivery_in_day: deliveryInADay

        }
        try {
            // console.log(sendStatusData)
            await handleUpdateVendorSettings(sendStatusData)
            // console.log(res, 'hell')
            setRefresh(true)
            setMsg('Profile updated succesfully')
            setTimeout(() => {
                setMsg('')
            }, 2000);
            // Redirect or perform any other necessary actions
        } catch (error) {
            console.error('Login error:', error.message)
        }
    }


    return <>

        <Vendor />

        <div className='frontGreyBg vendorDataContainer'>
        {loading ?
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
            <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
            <p>loading</p>
          </div>
          :
            <section className='webContainer'>

            

                <h1 className='pt-5 pb-3 font-weight-bold'>Update Your Profile</h1>

                <div className='row'>
                    <div className='col-sm-7'>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Your Name"
                                        autoComplete="off" required
                                        value={name} onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={emailIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="email" class="form-control frontFormInput"
                                        id="email"
                                        name="email"
                                        placeholder="Your Email"
                                        autoComplete="off" required
                                        value={email} onChange={(e) => setEmail(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Name and Email */}

                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={shopIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="restaurant_name"
                                        name="restaurant_name"
                                        placeholder="Your Restaurant Name"
                                        autoComplete="off" required
                                        value={resturant} onChange={(e) => setResturant(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row End Restaurant Name */}

                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={addressIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="address"
                                        name="address"
                                        placeholder="Restaurant Address"
                                        autoComplete="off" required
                                        value={address} onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Restaurant Address */}

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={pincodeIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="pincode"
                                        name="pincode"
                                        placeholder="Pincode"
                                        autoComplete="off" required
                                        value={pincode} onChange={(e) => setPincode(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={countryIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="country"
                                        name="country"
                                        placeholder="Country"
                                        autoComplete="off" required
                                        value={country} onChange={(e) => setCountry(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Restaurant Address */}


                    </div>
                </div>
                {/* ./Profile Update */}


                <div className='row pb-5'>
                    <div className='col-sm-7'>
                        <h1 className='pt-5 pb-3 font-weight-bold'>Meal Information</h1>

                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <select name='meal_type' className='form-control frontFormInput' value={mealType} onChange={(e) => setMealType(e.target.value)}>
                                        <option >{mealType || "Meal Type"}</option>
                                        <option value='Veg'>Veg</option>
                                        <option value='Non-Veg'>Non-Veg</option>
                                        <option value='Veg and Non-Veg'>Veg and Non-Veg</option>
                                    </select>
                                </div>
                                {/* ./
                    Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Meal Type */}

                        {/* <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={totalMeals} alt="" />
                                        </span>
                                    </div>

                                    <select name='no_of_meals' className='form-control frontFormInput'
                                        required value={numberOfMeals}
                                        onChange={(e) => setNumberOfMeals(e.target.value)}>
                                        <option>{numberOfMeals || "How many meals you cook in a day?"}</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                    </select>
                                </div>
            
                            </div>
                        </div> */}
                        {/* ./Row End No. OF Meals */}


                        {/* <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={workingDays} alt="" />
                                        </span>
                                    </div>
                                    <select name='no_of_meals' className='form-control frontFormInput'
                                        required value={monthlyActiveDays}
                                        onChange={(e) => setMonthlyActiveDays(e.target.value)}>
                                        <option>{monthlyActiveDays || "Monthly Active Days?"}</option>
                                        <option value='1'>1</option>
                                        <option value='2'>2</option>
                                        <option value='3'>3</option>
                                        <option value='4'>4</option>
                                        <option value='5'>5</option>
                                        <option value='6'>6</option>
                                        <option value='7'>7</option>
                                        <option value='8'>8</option>
                                        <option value='9'>9</option>
                                        <option value='10'>10</option>
                                        <option value='11'>11</option>
                                        <option value='12'>12</option>
                                        <option value='13'>13</option>
                                        <option value='14'>14</option>
                                        <option value='15'>15</option>
                                        <option value='16'>16</option>
                                    </select>
                                </div>
                        
                            </div>
                        </div> */}
                        {/* ./Row End Active Days In Month */}


                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={deliveriesInADay} alt="" />
                                        </span>
                                    </div>
                                    <select name='no_of_delivery_in_day' className='form-control frontFormInput'
                                        required value={deliveryInADay}
                                        onChange={(e) => setDeliveryInADay(e.target.value)}  >
                                        <option>{deliveryInADay || "How many times you deliver in a day?"}</option>
                                        <option value='1 Time per Day (Morning Only)'>1 Time per Day (Morning Only)</option>
                                        <option value='1 Time per Day (Afternoon Only)'>1 Time per Day (Afternoon Only)</option>
                                        <option value='1 Time per Day (Night Only)'>1 Time per Day (Night Only)</option>
                                        <option value='2 Times per Day (Morning & Night)'>2 Times per Day (Morning & Night)</option>
                                        <option value='2 Times per Day (Afternoon &  Night)'>2 Times per Day (Afternoon &  Night)</option>
                                    </select>
                                </div>
                                {/* ./
                    Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Deliveries In A Day */}

                        <div className="d-flex justify-content-start row">
                            <button className="btn frontFormBtn" onClick={handleUpdateVendorSetting} >
                                <div className="frontBtnIcon" >
                                    <img src={checkIcon} alt="" />
                                </div>
                                <p>Update</p>
                            </button>
                            <br />
                            <div className="justify-center">{msg}</div>
                            <br />

                        </div>
                    </div>
                </div>
                {/* ./Meal Info Update */}

            </section>
        }
        </div>


    </>
}

export default Setting
