import Header from '../../../Front/components/Header'

// Images
import checkIcon from '../../../assets/icons/checked.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import totalMeals from '../../../assets/icons/choice.png'
import workingDays from '../../../assets/icons/calendar.png'
import deliveriesInADay from '../../../assets/icons/fast-delivery.png'
import { url } from '../../../utils/Helper'
import Footer from "../../../Front/components/Footer"
import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { registerUser } from '../../../Auth/Service'


const MealInfo = () => {
    const navigate = useNavigate()
    const { state } = useLocation();
    const [mealType, setMealType] = useState('');
    const [numberOfMeals, setNumberOfMeals] = useState();
    const [monthlyActiveDays, setMonthlyActiveDays] = useState();
    const [deliveryInADay, setDeliveryInADay] = useState();

    const handleregisterUser = async () => {
        try {
            const userData = await registerUser(state.phone, 'vendor')
            // Store user_id, role, and token locally
            localStorage.setItem('user_id', userData.user.id)
            localStorage.setItem('role', userData.user.role)
            localStorage.setItem('token', userData.token)
            console.log(userData, 'registered data')
            handleUpdateVendorProfile(userData?.user?.id, userData?.user?.role, userData?.token)
        } catch (error) {
            console.log(error)
        }

    }

    const handleUpdateVendorProfile = async (userId, role, token) => {
        const formattedName = state.restaurant.toLowerCase().replace(/\s+/g, '-').replace(/[^a-zA-Z0-9-]/g, '');
        const slug = `${formattedName}-${state?.pincode?.replaceAll(' ','')}`;

        try {
            const response = await fetch(`${url}/vendor/profile/create`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "profileData": {

                        user_id: userId,
                        restaurant: state.restaurant,
                        slug: slug,
                        name: state.name,
                        email: state.email,
                        phone: state.phone,
                        address: state.address,
                        pincode: state.pincode,
                        country: state.country,
                        vendor_id: slug,
                        meal_type: mealType,
                        no_of_meals: numberOfMeals,
                        monthly_active_days: monthlyActiveDays,
                        no_of_delivery_in_day: deliveryInADay,
                        image: null,
                        banner: null,
                        vendor_code: state.name + state.phone

                    }
                }),
            })
            const resData = await response.json()
            if (response.ok) {
                if (role === 'customer') {
                    navigate('/home')
                } else if (role === 'vendor') {
                    navigate('/vendor')
                }
                else if (role === 'admin') {
                    navigate('/admin')
                }
            }
            else {
                alert("Something went wrong")
            }
            // console.log("New Vendor: ", resData);

            console.log(resData, 'vendor profile') // API returns success data
        } catch (error) {
            console.error('Error in getting data', error.message)
            throw error
        }
    }



    return (
        <>
            <Header />
            <section className="frontLogin frontGreyBg">
                <div className="webContainer">
                    <div className="row pt-1">
                        <div className="col-sm-7 m-auto">
                            <h5 className='frontSectionTitle text-center mt-5 mb-0'>Kitchen Information</h5>
                            <br />

                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={mealTypes} alt="" />
                                            </span>
                                        </div>
                                        <select name='meal_type' className='form-control frontFormInput' value={mealType} onChange={(e) => setMealType(e.target.value)}>
                                            <option>Select Meal Type</option>
                                            <option value='Veg'>Veg</option>
                                            <option value='Non-Veg'>Non-Veg</option>
                                            <option value='Veg and Non-Veg'>Veg and Non-Veg</option>
                                        </select>
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>
                            </div>
                            {/* ./Row End Meal Type */}

                            {/* <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={totalMeals} alt="" />
                                            </span>
                                        </div>
                                        <select name='no_of_meals' className='form-control frontFormInput' value={numberOfMeals}
                                            onChange={(e) => setNumberOfMeals(e.target.value)}
                                            required>
                                            <option>How many different meals do you prepare each day?</option>
                                            <option value='1'>1</option>
                                            <option value='2'>2</option>
                                            <option value='3'>3</option>
                                            <option value='4'>4</option>
                                            <option value='5'>5</option>
                                        </select>
                                    </div>
                                </div>
                            </div> */}
                            {/* ./Row End No. OF Meals */}


                            {/* <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={workingDays} alt="" />
                                            </span>
                                        </div>
                                        <select name='no_of_meals' className='form-control frontFormInput' value={monthlyActiveDays}
                                            onChange={(e) => setMonthlyActiveDays(e.target.value)}
                                            required>
                                            <option>Monthly Active Days?</option>
                                            <option value='1'>1</option>
                                            <option value='2'>2</option>
                                            <option value='3'>3</option>
                                            <option value='4'>4</option>
                                            <option value='5'>5</option>
                                            <option value='6'>6</option>
                                            <option value='7'>7</option>
                                            <option value='8'>8</option>
                                            <option value='9'>9</option>
                                            <option value='10'>10</option>
                                            <option value='11'>11</option>
                                            <option value='12'>12</option>
                                            <option value='13'>13</option>
                                            <option value='14'>14</option>
                                            <option value='15'>15</option>
                                            <option value='16'>16</option>
                                            <option value='17'>17</option>
                                            <option value='18'>18</option>
                                            <option value='19'>19</option>
                                            <option value='20'>20</option>
                                            <option value='21'>21</option>
                                            <option value='22'>22</option>
                                            <option value='23'>23</option>
                                            <option value='24'>24</option>
                                            <option value='25'>25</option>
                                            <option value='26'>26</option>
                                            <option value='27'>27</option>
                                            <option value='28'>28</option>
                                            <option value='29'>29</option>
                                            <option value='30'>30</option>

                                        </select>
                                    </div>
                            
                                </div>
                            </div> */}
                            {/* ./Row End Active Days In Month */}


                            <div className='row'>
                                <div className='col-sm-12'>
                                    <div className="input-group frontInputBox">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text frontFromIcon">
                                                <img src={deliveriesInADay} alt="" />
                                            </span>
                                        </div>
                                        <select name='no_of_delivery_in_day' className='form-control frontFormInput' value={deliveryInADay}
                                            onChange={(e) => setDeliveryInADay(e.target.value)}
                                            required >
                                            <option>How many times you deliver in a day?</option>
                                            <option value='1 Time per Day (Morning Only)'>1 Time per Day (Morning Only)</option>
                                            <option value='1 Time per Day (Afternoon Only)'>1 Time per Day (Afternoon Only)</option>
                                            <option value='1 Time per Day (Night Only)'>1 Time per Day (Night Only)</option>
                                            <option value='2 Times per Day (Morning & Night)'>2 Times per Day (Morning & Night)</option>
                                            <option value='2 Times per Day (Afternoon &  Night)'>2 Times per Day (Afternoon &  Night)</option>
                                        </select>
                                    </div>
                                    {/* ./
                                    Single Input */}
                                </div>
                            </div>
                            {/* ./Row End Deliveries In A Day */}

                            <div className="d-flex justify-content-center">
                                <button className="btn frontFormBtn" onClick={handleregisterUser}>
                                    <div className="frontBtnIcon">
                                        <img src={checkIcon} alt="" />
                                    </div>
                                    <p>Complete</p>
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </section>

            <Footer />
        </>

    )
}

export default MealInfo