import Vendor from "../../componenets/Vendor"
// image
import { url } from "../../../utils/Helper"
import plusIcon from '../../../assets/icons/plus.png'
import ReactQuill from 'react-quill'

import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import DateTime from "../../../utils/DateTime"

const Tickets = () => {
  const navigate = useNavigate()
  const [refresh, setRefresh] = useState(false);
  const [reply, setReply] = useState('');
  const [showReply, setShowReply] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ticketId, setTicketId] = useState();
  const [ticketsData, setTicketsData] = useState([]);
  const [ticketMessages, setTicketMessages] = useState([]);
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('user_id')
  const vendorId = localStorage.getItem('vendor_id')


  useEffect(() => {
    const fetchData = async () => {
      try {
        const vendorTickets = await fetch(`${url}/vendor/tickets`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            "vendorId": vendorId,
            "userId": userId
          }),
        })
        const data = await vendorTickets.json()
        if (data?.tickets) {
          setTicketsData(data?.tickets)
          setRefresh(false)
        }
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }

    setTimeout(() => {
      fetchData();
    }, 2000);
    setLoading(true)

  }, [refresh, token, userId, vendorId]);

  const handleFetchReplies = async (id) => {
    setTicketId(id)
    try {
      const vendorTickets = await fetch(`${url}/vendor/ticket/${id}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await vendorTickets.json()
      if (data?.messages) {
        setTicketMessages(data?.messages)
        setRefresh(false)
        setShowReply(!showReply)
      }

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }


  const handleAddReply = async (id) => {
    try {
      const addReply = await fetch(`${url}/vendor/ticket/reply`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "ticketId": ticketId, // ticket_id
          "senderId": vendorId, // sendId is vendor_id
          "receiverId": 1, // Admin ID static 1
          "message": reply,
          "image": "image5.png"
        }),
      })
      await addReply.json()
      // console.log(data)
      setShowReply(!showReply)
      setLoading(true)

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }


  return <>

    <Vendor />

    <div className='frontGreyBg vendorDataContainer'>
    
    {loading ?
          <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
            <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
            <p>loading</p>
          </div>
          :
      <section className='webContainer'>

        <div className='vendorAddMenuItem'>
          <div className="d-flex justify-content-center">
            <button className="btn frontFormBtn vendorOrangeBtn" onClick={() => navigate('/vendor/tickets/new-ticket')}>
              <div className="frontBtnIcon">
                <img src={plusIcon} alt="" />
              </div>
              <p>New Ticket</p>
            </button>
          </div>
        </div>


          <div className='row pt-3 pb-5'>
            <div className='col-sm-12 mb-3'>
              <div className='card rounded-50 themeShadow border-0 p-4'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Menu</h5>
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead className='bg-light'>
                      <th scope="col" className='font-weight-bold text-left'>
                        Ticket ID
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Title
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Subject
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Status
                      </th>
                      <th scope="col"></th>
                    </thead>
                    <tbody>
                      {ticketsData?.map((item, index) =>
                        <tr key={index}>
                          <th>{item.ticket_id}</th>
                          <td>{item.title}</td>
                          <td>{item.subject}</td>
                          <td>{item.status}</td>
                          <td>
                            <button className='text-primary font-weight-light viewOrder btn' onClick={() => { handleFetchReplies(item.ticket_id) }}>View</button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

      </section>
        }
      {showReply === true ? (
        <div className="modal">
          <div className="modal-content-vendor">
            <span className="close" onClick={() => { setShowReply(!showReply) }}>
              &times;
            </span>
            <div className="row mb-3">
              <div className="col-md-7">
                <div className="card p-1 shadow">
                  <div className="admin-chat-container">
                    <div className="admin-chat-messages" id="chatMessages">
                      {ticketMessages.map((ticket) => (
                        <div key={ticket.message_id}>
                          {ticket.sender_id.toString() === userId ? (
                            <div className="admin-message text-right bg-light p-2 rounded">
                              <span className="admin-username">
                                <h5>Admin:</h5>
                                <span className="admin-timestamp">
                                  <DateTime apiDateTime={ticket.created_at} />
                                </span>
                                <div className="article" style={{ fontSize: '15px' }}>
                                  <p dangerouslySetInnerHTML={{ __html: ticket.message }}></p>
                                </div>
                              </span>

                            </div>
                          ) : (
                            // Message from vendor
                            <div className="admin-message p-2">
                              <span className="admin-username">
                                <h5>Vendor:</h5>
                                <span className="admin-timestamp">
                                  <DateTime apiDateTime={ticket.created_at} />
                                </span>
                                <p dangerouslySetInnerHTML={{ __html: ticket.message }}></p>
                              </span>
                            </div>
                          )}

                          <hr />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <div className="card p-3 w-100 shadow">
                  <h5>
                    Send Reply To
                    {/* <Link to={`/admin/user?id=${ticketId}&role=vendor`}>
                                    {" "}
                                    Vendor
                                </Link> */}
                  </h5>
                  <br />
                  <div>
                    <div style={{ display: "grid", justifyContent: "center" }}>
                      <ReactQuill
                        theme="snow"
                        modules={modules}
                        formats={formats}
                        placeholder="write your reply ...."
                        onChange={(text) => setReply(text)}
                        style={{ height: "220px" }}
                      >
                      </ReactQuill>
                    </div>
                  </div>
                  <br />
                  <br />
                  <br />
                  <br />
                  <br />
                  <button className="btn btn-theme btn-primary" style={{ cursor: "pointer" }} onClick={handleAddReply} >
                    Send
                  </button>
                </div>
              </div>

            </div>

          </div>
        </div>
      ) : null}
    </div>


  </>
}

export default Tickets