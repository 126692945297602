
// Localhost
// export const url = 'http://localhost:3001/api'
// export const Domain = 'http://localhost:3001'
// export const FrontendDomain = 'http://localhost:3000'
// export const SuccessURL = 'http://localhost:3000/thankyou'
// export const CancelURL = 'http://localhost:3000/cart'




// Statging
export const url = 'https://projectdomain.online/api'
export const Domain = 'https://projectdomain.online'
export const FrontendDomain = 'https://codatcloud.com'
export const SuccessURL = 'https://codatcloud.com/thankyou'
export const CancelURL = 'https://codatcloud.com/cart'



// // Production
// export const url = 'https://backend.tiffinbox.store/api'
// export const Domain = 'https://backend.tiffinbox.store'
// export const FrontendDomain = 'https://tiffinbox.store'
// export const SuccessURL = 'https://tiffinbox.store/thankyou'
// export const CancelURL = 'https://tiffinbox.store/cart'