import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper"
import Date from "../../../utils/Date"
import editIcon from '../../../assets/icons/edit.png'
import deleteIcon from '../../../assets/icons/trash-can.png'
import calanderIcon from '../../../assets/icons/calendar.png'
import checkIcon from '../../../assets/icons/checked.png'
import plusIcon from '../../../assets/icons/plus.png'
import { useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

const Stock = () => {
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [stockData, setStockData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [page, setPage] = useState(1);
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('user_id')
  const vendorId = localStorage.getItem('vendor_id')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchStockData = async () => {
      try {
        const stockData = await fetch(`${url}/vendor/stocks/range-filter`, {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(
            {
              "vendorId": vendorId,
              "startDate": startDate, // Optional
              "endDate": endDate, // Optional
              "page": page,
              "pageSize": 10
            }
          ),
        })
        if (!stockData.ok) {
          throw new Error('Failed to fetch users')
        }
        const data = await stockData.json()
        setStockData(data.stocks)
        setRefresh(false)

      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }

    fetchStockData();
  }, [refresh, token, vendorId, page]);

  const handleDeleteStock = async (id) => {
    try {
      const deleteTicket = await fetch(`${url}/vendor/stocks/delete-stock`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "vendorId": vendorId,
          "stockId": id
        }),
      })
      if (!deleteTicket.ok) {
        throw new Error('Failed to fetch users')
      }
      const data = await deleteTicket.json()
      console.log(data)
      setRefresh(true)

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }

  const handlePrevClick = () => {
    if (page > 1) {
      setPage(page - 1);
    }
  };

  const handleNextClick = () => {
    setPage(page + 1);
  };



  return <>

    <Vendor />

    <div className='frontGreyBg vendorDataContainer'>

      <section className='webContainer'>

        <div className='row'>
          <div className='col-sm-7'>
            <div className='row'>
              <div className='col-sm-4'>
                <div className="input-group frontInputBox">
                  <div className="input-group-prepend">
                    <span className="input-group-text frontFromIcon">
                      <img src={calanderIcon} alt="" />
                    </span>
                  </div>
                  <input type="date" className="form-control frontFormInput"
                    id="name"
                    name="name"
                    placeholder="From"
                    autoComplete="off" required
                    value={startDate}
                    onChange={(e) => setStartDate(e.target.value)}
                    onClick={(e) => e.currentTarget.showPicker()}
                  />
                </div>
                {/* Single Input */}
              </div>
              <div className='col-sm-4'>
                <div className="input-group frontInputBox">
                  <div className="input-group-prepend">
                    <span className="input-group-text frontFromIcon">
                      <img src={calanderIcon} alt="" />
                    </span>
                  </div>
                  <input type="date" className="form-control frontFormInput"
                    id="email"
                    name="email"
                    placeholder="To"
                    autoComplete="off" required
                    value={endDate}
                    onChange={(e) => setEndDate(e.target.value)}
                    onClick={(e) => e.currentTarget.showPicker()}
                  />
                </div>
                {/* Single Input */}
              </div>

              <div className='col-sm-4'>
                <div className="d-flex justify-content-start">
                  <button className="btn frontFormBtn mt-1" onClick={() => { setRefresh(true) }}>
                    <div className="frontBtnIcon" >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 23 23" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="4" y1="21" x2="4" y2="14"></line><line x1="4" y1="10" x2="4" y2="3"></line><line x1="12" y1="21" x2="12" y2="12"></line><line x1="12" y1="8" x2="12" y2="3"></line><line x1="20" y1="21" x2="20" y2="16"></line><line x1="20" y1="12" x2="20" y2="3"></line><line x1="1" y1="14" x2="7" y2="14"></line><line x1="9" y1="8" x2="15" y2="8"></line><line x1="17" y1="16" x2="23" y2="16"></line></svg>
                    </div>
                    <p>Filter</p>
                  </button>
                </div>
              </div>

            </div>
            {/* ./Row */}
          </div>

          <div className='col-5 desktop'>
            <div className='row'>
              <div className='col-6'>
                <div className="d-flex justify-content-center">
                  <a onClick={()=>{navigate("/vendor/add-stock")}} className="btn frontFormBtn vendorOrangeBtn mt-1 px-1">
                    <div className="frontBtnIcon">
                      <img src={plusIcon} alt="" />
                    </div>
                    <p>Add New</p>
                  </a>
                </div>

              </div>

              <div className='col-6'>
                <div className="d-flex justify-content-center">
                  <a onClick={()=>{navigate("/vendor/revenue")}} className="btn frontFormBtn vendorOrangeBtn mt-1 px-1">
                    <div className="frontBtnIcon">
                      <img src={plusIcon} alt="" />
                    </div>
                    <p>Revenue</p>
                  </a>
                </div>
              </div>
            </div>
          </div>

        </div>
        {/* ./Form And Nav */}

        <div className='row pt-3 pb-5'>
          <div className='col-sm-12 mb-3'>
            <div className='card rounded-50 themeShadow border-0 p-4'>
              <div className='d-flex justify-content-between'>
                <h5 className='font-weight-bold'>Stock (Latest First)</h5>
              </div>

              <div className='table-responsive'>
                <table className='table'>
                  <thead className='bg-light'>
                    <td className='font-weight-bold text-left'>
                      Item Name
                    </td>
                    <td className='font-weight-bold'>
                      Qty.
                    </td>
                    <td className='font-weight-bold'>
                      Purchased On
                    </td>
                    <td className='font-weight-bold'>
                      Price
                    </td>
                    <td></td>
                  </thead>
                  {stockData.length > 0 ?
                    <tbody>
                      {stockData?.map((item) =>
                        <tr>
                          <th className='text-left'>{item.item_name}</th>
                          <td>{item.qty}</td>
                          <td><Date apiDateTime={item.purchased_on} /></td>
                          <td>{item.price}</td>
                          <td>
                            <button className='text-primary font-weight-light editBtn btn' onClick={() => { navigate('/vendor/edit-stock', { state: { stockId: item.id } }) }}>
                              <img src={editIcon} className='img-fluid' alt="" />
                            </button>
                            <button onClick={() => { handleDeleteStock(item.id) }} className='text-primary font-weight-light deleteBtn btn'>
                              <img src={deleteIcon} className='img-fluid' alt="" />
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                    :
                    <div>
                      <div className="p-3">No data available</div>
                    </div>
                  }
                </table>
              </div>
              <div className="row mt-4 justify-content-center">
            <div className="col-auto">
                  <button
                    className="btn btn-primary" onClick={handlePrevClick} disabled={page === 1}>
                    Prev
                  </button>
                </div>
                <div className="col-auto">
                  {stockData.length > 0 ? <button className="btn btn-primary" onClick={handleNextClick}>Next</button> :
                    null
                  }
                </div>

              </div>
            </div>
          </div>

        </div>

      </section>

    </div>

    <div className="mobile mt-5">
      <div class="bottom-nav">
          <a onClick={()=>{navigate("/vendor/add-stock")}} className="border-right">
            <span className="">
            </span>
              Add Stock
          </a>
            
          <a onClick={()=>{navigate("/vendor/revenue")}}>
            
            <span className="">
            </span>
              Revenue
          </a>
      </div>
    </div>


  </>
}

export default Stock