import { url } from "../utils/Helper"

export const checkUserExistence = async (phoneNumber) => {
  try {
    const response = await fetch(`${url}/users/${phoneNumber}/check-existence`)
    const data = await response.json()
    console.log(data)
    return data.exists // API returns an object with a property 'exists'
  } catch (error) {
    console.error('Error checking user existence:', error.message)
    throw error
  }
}

export const loginUser = async (phoneNumber) => {
  try {
    const response = await fetch(`${url}/user/login`, {
    method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: phoneNumber,
      }),
    })
    const data = await response.json()
    
    console.log(data)
    return data // API returns user_id, role, and token
  } catch (error) {
    console.error('Error logging in user:', error.message)
    throw error
  }
}

export const registerUser = async (phoneNumber,role) => {
  try {
    const response = await fetch(`${url}/user/register`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        phone: phoneNumber,
        password: '123456789', // Default password
        role: role,
        permissions: role
      }),
    })
    const data = await response.json()
    console.log(data)
    return data // API returns user_id, role, and token
  } catch (error) {
    console.error('Error registering user:', error.message)
    throw error
  }
}