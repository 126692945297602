import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper"
// image
import checkIcon from '../../../assets/icons/checked.png'
import userIcon from '../../../assets/icons/user-2.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import calanderIcon from '../../../assets/icons/calendar.png'
import { useNavigate } from "react-router-dom"
import { useState } from "react"

const AddNewStock = () => {
    const [loading, setLoading] = useState(false);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [price, setPrice] = useState('');
    const [msg, setMsg] = useState('');
    const [err, setErr] = useState('');
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')
    const navigate = useNavigate()

    const validateFields = () => {
        if (!itemName || !quantity || !purchaseDate || !price) {
            setErr('All fields are required.');
            return false;
        }
        return true;
    }

    const handleAddStock = async () => {
        if (!validateFields()) return;

        setLoading(true);
        try {
            const addStock = await fetch(`${url}/vendor/stocks/add-stock`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "stockData": {
                        "vendorId": vendorId,
                        "item_name": itemName,
                        "qty": quantity,
                        "purchased_on": purchaseDate,
                        "price": price
                    }
                }),
            })
            const data = await addStock.json()
            if (data) {
                setMsg('New stock added')
                setTimeout(() => {
                    navigate('/vendor/stock')
                    setMsg('')
                }, 2000);
            }
        } catch (error) {
            console.error('Error adding stock:', error)
            setMsg('Failed to add stock. Please try again.')
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Vendor />

        <div className='frontGreyBg vendorDataContainer'>
            <section className='webContainer py-5'>
                <h1 className='pb-3 font-weight-bold'>Add New Stock</h1>
                {msg && <p style={{ color: msg.includes('added') ? 'green' : 'red' }}>{msg}</p>}
                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Item Name"
                                        autoComplete="off" required
                                        value={itemName}
                                        onChange={(e) => setItemName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="qty"
                                        name="qty"
                                        placeholder="Quantity"
                                        autoComplete="off" required
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        autoComplete="off" required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={calanderIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" className="form-control frontFormInput"
                                        id="purchased_on"
                                        name="purchased_on"
                                        placeholder="Purchased On"
                                        autoComplete="off" required
                                        value={purchaseDate}
                                        onChange={(e) => setPurchaseDate(e.target.value)}
                                        onClick={(e) => e.currentTarget.showPicker()}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddStock} disabled={loading}>
                                <div className="frontBtnIcon">
                                    <img src={checkIcon} alt="" />
                                </div>
                                <p>{loading ? 'Adding...' : 'Add New Stock'}</p>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}

export default AddNewStock
