import React, { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom'
import { Provider, useSelector } from 'react-redux';

import Home from './Front/Home'

// Admin
import Admin from './Admin/Home'
import ListUsers from './Admin/pages/user/ListUsers'
import UserProfile from './Admin/pages/user/UserProfile'
import ListCoupons from './Admin/pages/coupon/ListCoupons'
import ListContacts from './Admin/pages/contact/ListContacts'
import AllSettings from './Admin/pages/setting/AllSettings'
import ListTicket from './Admin/pages/ticket/ListTicket'
import ViewTicket from './Admin/pages/ticket/ViewTicket';

// Vendor
import Vendor from './Vendor/pages/dashboard/Home'

// Customer
import Customer from './Customer/Home'

import Login from './Auth/Login'

import Register from './Vendor/pages/register/Register';

import CompleteProfile from './Vendor/pages/register/CompleteProfile';
import MealInfo from './Vendor/pages/register/MealInfo';
import VendorMenu from './Vendor/pages/menu/Menu';
import VendorAddMenu from './Vendor/pages/menu/AddMenu';
import VendorEditMenu from './Vendor/pages/menu/EditMenu';
import VendorOrder from './Vendor/pages/order/Order';
import VendorStock from './Vendor/pages/stock/Stock';
import VendorAddNewStock from './Vendor/pages/stock/AddNewStock';
import VendorEditStock from './Vendor/pages/stock/EditStock';
import VendorSetting from './Vendor/pages/setting/Setting';
import VendorRevenue from './Vendor/pages/revenue/Revenue';
import VendorTicket from './Vendor/pages/ticket/Tickets';
import VendorCoupon from './Vendor/pages/coupon/Coupon';
import VendorAddCoupon from './Vendor/pages/coupon/AddCoupon';
import VendorEditCoupon from './Vendor/pages/coupon/EditCoupon';
import VendorNewTicket from './Vendor/pages/ticket/NewTicket';
import Privacy from './Front/Privacy';
import Terms from './Front/Terms';
import Refund from './Front/Refund';
import VendorPage from './Front/VendorPage';
import MyAccount from './Customer/MyAccount';
import Checkout from './Front/Checkout';
import SelectAddress from './Front/SelectAddress';
// import SelectPayment from './Front/SelectPayment';
import ThankYou from './Front/ThankYou';
import Cart from './Front/Cart';
import configurestore from './redux/store/Store';
import Coupon from './Front/Coupon';
import CustomerLogin from './Customer/CustomerLogin';


//redux store configration
const store = configurestore();

const App = () => {
//   const vendorUrl = VendorUrl()
//   // Retrieve user role from local storage after login
// console.log(vendorUrl)
  const userRole = localStorage.getItem('role')
  useEffect(() => {
    // console.log(userRole,'userrole')
    // Redirect based on user role after login
    if (userRole === 'admin') {
      <Navigate to="/admin" />
    } else if (userRole === 'vendor') {
      <Navigate to="/vendor" />
    } else if (userRole === 'customer') {
      <Navigate to="/customer" />
    } else {
      // No user logged in, redirect to the landing page
      <Navigate to="/" />
    }
  }, [userRole])


  return (
    <div>
     <Provider store={store}>
      <Routes>
        {/* Normal users */}
        <Route path="*" element={<PublicElement><Home /> </PublicElement>} />
        <Route path="/my-account" element={<PublicElement><MyAccount /> </PublicElement>} />
        <Route path="/cart" element={<PublicElement><Cart /> </PublicElement>} />
        <Route path="/coupons" element={<PublicElement><Coupon /> </PublicElement>} />
        <Route path="/checkout" element={<PublicElement><Checkout /> </PublicElement>} />
        <Route path="/select-address" element={<PublicElement><SelectAddress /> </PublicElement>} />
        {/* <Route path="/payment" element={<PublicElement><SelectPayment /> </PublicElement>} /> */}
        <Route path="/thankyou" element={<PublicElement><ThankYou /> </PublicElement>} />
        <Route path="/privacy-policy" element={<PublicElement><Privacy /> </PublicElement>} />
        <Route path="/terms-of-use" element={<PublicElement><Terms /> </PublicElement>} />
        <Route path="/refund-policy" element={<PublicElement><Refund /> </PublicElement>} />
        <Route path={`/restaurant/:resturanname`} element={<PublicElement><VendorPage /> </PublicElement>} />
        <Route path="/vendor/register" element={<PublicElement><Register /></PublicElement>} />
        <Route path="/vendor/register/complete-profile" element={<PublicElement><CompleteProfile /></PublicElement>} />
        <Route path="/vendor/register/meal-information" element={<PublicElement><MealInfo /></PublicElement>} />
        <Route path="/login" element={<PublicElement><Login /></PublicElement>} />
        <Route path="/customer-login" element={<PublicElement><CustomerLogin /></PublicElement>} />

        {/* Customers */}
        {/* <Route path="/my-account" element={<CustomerElement><Customer /></CustomerElement>} /> */}

        {/* Admin */}
        <Route path="/admin" element={<AdminElement><Admin /></AdminElement>} />
        <Route path="/admin/users" element={<AdminElement><ListUsers /></AdminElement>} />
        <Route path="/admin/user" element={<AdminElement><UserProfile /></AdminElement>} />
        <Route path="/admin/coupons" element={<AdminElement><ListCoupons /></AdminElement>} />
        <Route path="/admin/contacts" element={<AdminElement><ListContacts /> </AdminElement>} />
        <Route path="/admin/settings" element={<AdminElement><AllSettings /></AdminElement>} />
        <Route path="/admin/tickets" element={<AdminElement><ListTicket /></AdminElement>} />
        <Route path="/admin/ticket/view" element={<AdminElement><ViewTicket /></AdminElement>} />

        {/* Vendor */}
        <Route path="/vendor" element={<VendorElement><Vendor /></VendorElement>} />
        <Route path="/vendor/menu" element={<VendorElement><VendorMenu /></VendorElement>} />
        <Route path="/vendor/add-menu" element={<VendorElement><VendorAddMenu /></VendorElement>} />
        <Route path="/vendor/edit-menu" element={<VendorElement><VendorEditMenu /></VendorElement>} />
        <Route path="/vendor/orders" element={<VendorElement><VendorOrder /></VendorElement>} />
        <Route path="/vendor/stock" element={<VendorElement><VendorStock /></VendorElement>} />
        <Route path="/vendor/add-stock" element={<VendorElement><VendorAddNewStock /></VendorElement>} />
        <Route path="/vendor/edit-stock" element={<VendorElement><VendorEditStock /></VendorElement>} />
        <Route path="/vendor/setting" element={<VendorElement><VendorSetting /></VendorElement>} />
        <Route path="/vendor/revenue" element={<VendorElement><VendorRevenue /></VendorElement>} />
        <Route path="/vendor/tickets" element={<VendorElement><VendorTicket /></VendorElement>} />
        <Route path="/vendor/tickets/new-ticket" element={<VendorElement><VendorNewTicket /></VendorElement>} />
        <Route path="/vendor/coupon" element={<VendorElement><VendorCoupon /></VendorElement>} />
        <Route path="/vendor/add-coupon" element={<VendorElement><VendorAddCoupon /></VendorElement>} />
        <Route path="/vendor/edit-coupon" element={<VendorElement><VendorEditCoupon /></VendorElement>} />
      </Routes>
      </Provider>
    </div>
  )
}

//for normal user
function PublicElement({ children }) {
  return <>{children}</>
}

//for customers
function CustomerElement({ children }) {
  const userRole = localStorage.getItem('role')
  if (userRole === 'customer') {
    return <>{children}</>
  }
  else {
    return <Navigate to="/" />
  }

}

//for admin
function AdminElement({ children }) {
  const userRole = localStorage.getItem('role')
  if (userRole === 'admin') {
    return <>{children}</>
  }
  else {
    return <Navigate to="/" />
  }

}

//for vendor
function VendorElement({ children }) {
  const userRole = localStorage.getItem('role')
  if (userRole === 'vendor') {
    return <>{children}</>
  }
  else {
    return <Navigate to="/" />
  }

}

export default App
