import { url } from "../../utils/Helper"

export const handleUpdateVendorSettings = async (data) => {
  try {
    const response = await fetch(`${url}/vendor/profile/update/${data.user_id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "profileData": {
          "user_id": data.user_id,
          "name": data.name,
          "slug": data.slug,
          "restaurant": data.restaurant,
          "email": data.email,
          "phone": data.phone,
          "address": data.address,
          "pincode": data.pincode,
          "country": data.country,
          "vendor_id": data.vendor_id,
          "meal_type": data.meal_type,
          "no_of_meals": data.no_of_meals,
          "monthly_active_days": data.monthly_active_days,
          "no_of_delivery_in_day": data.no_of_delivery_in_day,
        }
      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleAddMenu = async (data) => {
  const token = localStorage.getItem('token')
  // console.log(data,"hh")
  try {
    const response = await fetch(`${url}/vendor/menu/add-item`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
        // 'content-type': 'multipart/form-data',
      },
      body: data
    })
    const resData = await response.json()
    if (resData) {
      // console.log(resData)
      return resData
    }
    else {
      return null
    } // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleUpdateMenu = async (data) => {
  const token = localStorage.getItem('token')
  try {
    const response = await fetch(`${url}/vendor/menu/update-item`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${token}`,
        // 'Content-Type': 'application/json',
      },
      body: data
    })
    const resData = await response.json()
    if (resData) {
      // console.log(resData)
      return resData
    }
    else {
      return null
    } // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleGetSingleMenu = async (data) => {
  try {
    const response = await fetch(`${url}/vendor/menu/single-item`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "vendor_id": data.vendor_id,
        "menu_id": data.menu_id,
      }),
    })
    const resData = await response.json()
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

