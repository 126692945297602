
import backArrow from '../assets/icons/back.png'
import cartimg from '../assets/icons/cart.png'
import itemImg from '../assets/img/about.jpg'
import { useNavigate, useLocation } from 'react-router-dom'
import { url } from '../utils/Helper';
import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import Date from "../../src/utils/Date"
import HeaderNavigation from './components/HeaderNavigation';

const Cart = () => {
    const navigate = useNavigate()
    const location = useLocation();
    // console.log(location)
    const code = location?.state?.item?.code;
    const couponDiscount = location?.state?.item?.discount != undefined ? location?.state?.item?.discount : 0;
    console.log(couponDiscount)


    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState('');
    const [cartItems, setCartItems] = useState([]);
    const orderId = localStorage.getItem('order_id')
    const vendorId = localStorage.getItem('vendor_id')

    useEffect(() => {
        fetchCartItems();
        // getCart();
    }, [refresh]);


    const fetchCartItems = async () => {
        try {
            const getCartItems = await fetch(`${url}/front/getCartItemsByOrder`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "order_id": orderId
                }),
            })
            const data = await getCartItems.json()
            if (data) {
                setCartItems(data)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }


    const handleDeleteCartItems = async (item) => {
        const getCartItems = await fetch(`${url}/front/deleteCartItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "id": item.order_item_id,
                "order_id": item.order_id,
                "vendor_id": vendorId,
                "item_name": item.item_name
            }),
        })

        const data = await getCartItems.json()
        if (data) {
            // console.log(data)
            // setCartItems(data)
            setRefresh(true)
            fetchCartItems()
        }

    }

    const handleAddToCart = async (item) => {
        // console.log(item,"add")
        const addItemsOfCart = await fetch(`${url}/front/insertCartItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "order_id": item.order_id,
                "vendor_id": vendorId,
                "item_name": item.item_name,
                "menu_item_id": item.menu_item_id,
                "qty": '1',
                "price": item.price, //data?.addCart?.price
                "discount": item.discount
            }),
        })
        const dataRes = await addItemsOfCart.json()
        if (dataRes) {
            // console.log(dataRes, "addwdtocart")
            setRefresh(true)
            fetchCartItems()
        }
    }

    const handleRemoveFromCart = async (item) => {
        // console.log(item)
        const removeItemsOfCart = await fetch(`${url}/front/reduceOrderItemQty`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "order_id": item.order_id,
                "vendor_id": vendorId,
                "item_name": item.item_name,
                "menu_item_id": item.menu_item_id,
                "qty": '1',
                "price": item.price, //data?.addCart?.price
                "discount": item.discount
            }),
        })
        const dataRes = await removeItemsOfCart.json()
        if (dataRes) {
            // console.log(dataRes, "removeCart")
            setRefresh(true)
            fetchCartItems()
        }
    };

    // Calculate subtotal of items
    const subtotal = cartItems?.length > 0 ? cartItems?.reduce((acc, item) => acc + (item.price * item.qty), 0) : 0;

    // Calculate total discounts
    const totalDiscount = cartItems?.length > 0 ? cartItems?.reduce((acc, item) => acc + (item.discount * item.qty), 0) : 0;

    // Calculate total
    const total = subtotal - totalDiscount - Number(couponDiscount);



    return <>

        <section className="frontCheckout frontGreyBg">
            <div className="webContainer">
                {/* Header */}
                <HeaderNavigation pageName={"Cart"} refreshed={refresh} />

                <div className='spacer'></div>
                {cartItems.length > 0 ?

                    <>
                        <div className='row'>
                            {cartItems.length > 0 && cartItems?.map((item, Index) =>
                                <div className='col-md-6 col-xs-12' key={Index}>
                                    <div className="card cartItemCard mb-3">
                                        <div className="row no-gutters">
                                            <div className="col-4">
                                                <img src={item.image} alt="" className='img-fluid frontCartItemImage' />
                                            </div>
                                            <div className="col-8">
                                                <div className="card-body px-1 py-2">
                                                    <span className='frontMealDate'>{item.item_name}</span>
                                                    <br />
                                                    <span className="frontVegTag">{item.category}</span>
                                                    <br />
                                                    <span className="frontMealTime">{item.day_of_the_week}</span>
                                                    <br />
                                                    <span className="frontKcal">{item.meal_kcal} Kcal</span>

                                                    <div className='frontCartManageQty'>
                                                        <button onClick={() => handleRemoveFromCart(item)} className="btn">
                                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="11.0294" cy="11.0294" r="10.5294" fill="white" stroke="#53BC9D" />
                                                                <line x1="4.41187" y1="11.2647" x2="17.6472" y2="11.2647" stroke="#53BC9D" />
                                                            </svg>
                                                        </button>
                                                        <span className='mx-2'>{Number(item?.qty)?.toFixed(2)?.replace(/\.?0+$/, '') || '0'}</span>

                                                        <button onClick={() => handleAddToCart(item)} className="btn">
                                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="11.9706" cy="11.0294" r="11.0294" fill="#53BC9D" />
                                                                <line x1="5.35303" y1="11.2647" x2="18.5883" y2="11.2647" stroke="white" />
                                                                <line x1="12.2058" y1="17.6471" x2="12.2058" y2="4.41177" stroke="white" />
                                                            </svg>
                                                        </button>
                                                    </div>


                                                    {/* Delete Cart Item */}
                                                    <button className='btn cartItemDeleteBtn' onClick={() => { handleDeleteCartItems(item) }}>
                                                        <svg width="32" height="31" viewBox="0 0 32 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="15.5" cy="15.5" r="15" fill="#F9F9F9" stroke="#EC0000" />
                                                            <line x1="8.44039" y1="22.7364" x2="23.7362" y2="7.44054" stroke="#EC0000" />
                                                            <line x1="23.736" y1="23.1514" x2="8.44023" y2="7.85556" stroke="#EC0000" />
                                                        </svg>

                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}
                            {/* Loop the items in cart */}

                        </div>
                        {/* Row End */}

                        <div className='row'>
                            <div className='col-md-6 col-xs-12'>
                                <a onClick={() => { navigate("/coupons") }}>
                                    <div className='cartApplyCupponDiv'>
                                        {code == undefined ?
                                            <>
                                                <p>Apply Promo Code</p>
                                                <button className="btn applyBtn ">Apply</button>
                                            </>
                                            :
                                            <>
                                                <p>{code}</p>
                                                <button className="btn applyBtn">Change Coupon</button>
                                            </>
                                        }
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-md-6 col-xs-12'>
                                {/* Summary */}
                                <div className="cartSummaryCard table-responsive">
                                    <h4>Summary</h4>
                                    <table className="table table-borderless mb-0">
                                        <tbody className="px-0">
                                            <tr className="border-bottom">
                                                <td className="text-left px-0">Subtotal</td>
                                                <td className="text-right px-0">${subtotal?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left px-0">Coupon Discount</td>
                                                <td className="text-right px-0">${couponDiscount}</td>
                                            </tr>
                                            <tr className="border-bottom">
                                                <td className="text-left px-0">Total Discounts</td>
                                                <td className="text-right px-0">${totalDiscount?.toFixed(2)}</td>
                                            </tr>
                                            <tr>
                                                <td className="text-left px-0">Total Amount</td>
                                                <td className="text-right px-0">${total?.toFixed(2)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>


                        <button onClick={() => {
                            localStorage.setItem("totalDiscount", totalDiscount)
                            localStorage.setItem("couponId", location?.state?.item?.id == undefined ? "" : location?.state?.item?.id)
                            localStorage.setItem("totalAmount", total)
                            navigate('/checkout')
                        }} className='btn frontPaymentBtn'>Checkout</button>
                    </>


                    :

                    <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
                        <img src={require("../assets/loader/empty-cart.gif")} alt="background" height={50} width={50} />
                        <center>Empty Cart</center>
                    </div>

                }
            </div>

            <div className='spacer'></div>
            <div className='spacer'></div>
        </section>

    </>
}

export default Cart;