import React, { useEffect, useState } from 'react';
import { url, FrontendDomain, Domain } from "../../../utils/Helper";
import Dates from '../../../utils/Date';
import Vendor from "../../componenets/Vendor"
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate()
  const [ViewOrder, setViewOrder] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(false);
  const [copyText, setCopyText] = useState(false);
  const [latestOrders, setLatestOrders] = useState([]);
  const [todaysDeliveryData, setTodaysDeliveryData] = useState([]);
  const [dashboardData, setDashboarData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [orderInfo, setOrderInfo] = useState('');
  const token = localStorage.getItem('token')
  const userId = localStorage.getItem('user_id')
  const orderId = localStorage.getItem('order_id')

  //  today's date
  const todayDate = new Date().toISOString().split('T')[0];

  useEffect(() => {
    const fetchVendorProfile = async () => {
      try {
        // console.log("user id: ", userId)
        const vendorProfile = await fetch(`${url}/vendor/profile/${userId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        if (!vendorProfile.ok) {
          throw new Error('Failed to fetch users')
        }
        const data = await vendorProfile.json()
        // console.log("Profile Response: ", data)
        localStorage.setItem('vendor_id', data.id)
        // const vendor_id = localStorage.getItem('vendor_id')
        // console.log("Vendor Is: ", vendor_id);
        fetchDashboardData(data?.id);
        setRefresh(false)
        // Get Customer Address

      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }
    setTimeout(() => {
      fetchVendorProfile();
    }, 2000);
    setLoading(true)
  }, [userId, refresh, token]);

  const fetchDashboardData = async (id) => {
    try {
      const getDashboard = await fetch(`${url}/vendor/dashboard`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "vendorId": id,
          "userId": userId,
          "deliveryDate": todayDate,
        }),
      })

      const data = await getDashboard.json()
      setLatestOrders(data?.latestOrders)
      setTodaysDeliveryData(data?.todayDeliveries)
      setDashboarData(data?.userProfile)
      setRefresh(false)

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (copyText === true) {
      setTimeout(() => {
        setCopyText(false)
      }, 2000);
    }

  }, [copyText])



  const fetchCartItems = async (orderId) => {
    try {
      const getCartItems = await fetch(`${url}/front/getCartItemsByOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "order_id": orderId
        }),
      })
      const data = await getCartItems.json()
      if (data) {
        // console.log(data, "cart items")
        setCartItems(data)
        setRefresh(false)
      }
      else {
        console.log('no data')
        setRefresh(false)
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }


  const handleChangeStatus = async (event) => {
    try {
      const changeOrderStatus = await fetch(`${url}/vendor/order/status-update/${orderId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "order_status": event.target.value
        }),
      })

      const data = await changeOrderStatus.json()
      if (data) {
        setViewOrder(!ViewOrder)
      }
      // console.log(data)

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }




  return <>

    <Vendor />

    <div className='frontGreyBg vendorDataContainer'>
      {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
          <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
          <p>loading</p>
        </div>
        :
        <section className='webContainer'>
          <div className='py-3'>
            {dashboardData?.image == null ?
              <div className='card bg-white themeShadow rounded-0 p-2 mb-1'>
                <p className='errorMsg1 text-danger mb-0'>Upload your logo to complete your profile.</p>
              </div>
              : dashboardData?.banner == null ?
                <div className='card bg-white themeShadow rounded-0 mt-3 p-2'>
                  <p className='errorMsg1 text-danger mb-0'>Upload your banner to complete your profile.</p>
                </div>
                :
                <div className='card bg-white themeShadow rounded-0 p-2 mb-1' style={{ cursor: "pointer" }} onClick={() => { navigator.clipboard.writeText(`${FrontendDomain}/restaurant/${dashboardData.slug}`); setCopyText(true) }}>
                  <p className='errorMsg1 text-success mb-0'>{`${FrontendDomain}/restaurant/${dashboardData.slug}`}  <b>{copyText === true ? "Copied" : 'click to copy'}</b></p>
                </div>
            }
          </div>

          <div className='row pt-3'>
            <div className='col-sm-7 mb-3'>
              <div className='card rounded-50 themeShadow border-0 p-4 cardHorozontalScroll'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Latest Orders</h5>
                  <a onClick={() => { navigate("/vendor/orders") }} className='btn text-primary btn-sm'>View All Orders</a>
                </div>

                <div className='table-responsive'>
                  {latestOrders?.length > 0 ?
                    <table className='table'>
                      <thead className='bg-light'>
                        <td className='font-weight-bold'>
                          S.No
                        </td>
                        <td className='font-weight-bold'>
                          Quantity
                        </td>
                        <td className='font-weight-bold'>
                          Payment
                        </td>
                        <td></td>
                      </thead>
                      <tbody>
                        {latestOrders?.slice(0, 4).map((item, index) =>
                          <tr>
                            <th>{index + 1}</th>
                            <td>{item.qty}</td>
                            <td>{item.price}</td>
                            <td>
                              <button className='text-primary font-weight-light viewOrder btn' onClick={() => {  setOrderInfo(item); fetchCartItems(item.id); setViewOrder(!ViewOrder); }}>View</button>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                    :
                    <center>No Latest Orders</center>
                  }
                </div>

              </div>
            </div>

            <div className='col-sm-5 mb-5'>
              <div className='card rounded-50 themeShadow border-0 p-4'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Today's Deliver</h5>
                  <a onClick={() => { navigate("/vendor/orders") }} className='btn text-primary btn-sm'>View All Orders</a>
                </div>

                <div className='table-responsive'>
                  {todaysDeliveryData?.length > 0 ?
                    <table className='table'>
                      <thead className='bg-light'>
                        <td className='font-weight-bold'>
                          S.No
                        </td>
                        <td className='font-weight-bold'>
                          Quantity
                        </td>
                        <td></td>
                      </thead>
                      <tbody>
                        {todaysDeliveryData?.filter((order) => order?.created_at.split('T')[0] === todayDate)
                          .slice(0, 4).map((item, index) =>
                            <tr>
                              <th>{index + 1}</th>
                              <td>{item.qty}</td>
                              <td>
                                <button className='text-primary font-weight-light viewOrder btn' onClick={() => { setOrderInfo(item); fetchCartItems(item.id); setViewOrder(!ViewOrder); }}>View</button>
                              </td>
                            </tr>
                          )}
                      </tbody>
                    </table>
                    : <center>No Today's Orders</center>
                  }
                </div>
              </div>
              {/* ./Todays Order Table */}
            </div>
          </div>

        </section>
      }

      {ViewOrder === true ? (
        <div className="modal">
          <div className="modal-content1 p-4 w-100 h-100 mt-5 overflow-auto">
            <span className="close" onClick={() => { setViewOrder(!ViewOrder) }}>
              &times;
            </span>
            <div className="row mb-4">
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Order ID: ${orderInfo?.id}`} className="form-control" readOnly />
              </div>
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Order Date: ${orderInfo?.updated_at}`} className="form-control" readOnly />
              </div>
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Delivery Address: ${orderInfo?.delivery_address}`} className="form-control" readOnly />
              </div>

              <div className="col-lg-8 col-sm-10">
                <select defaultValue={"Select Status"} className="form-control" onChange={handleChangeStatus}>
                  <option disabled >Select Status</option>
                  <option value="Ready for Delivery">Ready for Delivery</option>
                  <option value="Out for delivery">Out for delivery</option>
                  <option value="Delivery Made">Delivery Made</option>
                  <option value="Not Delivered">Not Delivered</option>
                </select>
              </div>
            </div>

            <div className='row'>
              {cartItems?.length > 0 && cartItems?.map((item, Index) =>
                <div className='col-md-6 col-xs-12' key={Index}>
                  <div class="card cartItemCard mb-3">
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img src={item.image} alt="" className='img-fluid frontCartItemImage' />
                      </div>
                      <div class="col-8">
                        <div class="card-body px-1 py-2">
                          <span class="frontVegTag">{item.category}</span>
                          <br />
                          <span class="frontMealTime">For Dinner</span>
                          <br />
                          <span class="frontKcal">{item.id} Kcal</span>
                          <br />
                          <span className='frontMealDate'><Dates apiDateTime={item.created_at} /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Loop the items in cart */}

            </div>
            {/* Row End */}

          </div>
        </div>
      ) : null}
    </div>


  </>
}

export default Home
