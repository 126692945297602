import { useNavigate } from 'react-router-dom';

const Footer = () => {
    const navigate = useNavigate()
    
    return (
        <footer className="py-3">
            <div className="webContainer">
                <div className="row border-bottom">
                    <div className="col-sm-6">
                        <p className="lead">Copyright 2024 | Tiffinbox</p>
                    </div>
                    <div className="col-sm-6 text-right">
                        <a onClick={() => { navigate('/privacy-policy') }} className="text-dark lead mr-3">Privacy Policy</a>
                        <a onClick={() => { navigate('/terms-of-use') }} className="text-dark lead mr-3">Terms Of Use</a>
                        <a onClick={() => { navigate('/refund-policy') }} className="text-dark lead mr-3">Refund Policy</a>
                    </div>
                </div>

                <div className="row mt-2">
                    <div className="col-12">
                        <p className="lead text-dark text-center">Powered By CROWDBAZAAR LIMITED</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer