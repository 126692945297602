import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import logo1 from '../../assets/img/1.png'
import logo2 from '../../assets/img/2.png'
import logo3 from '../../assets/img/3.png'
import logo4 from '../../assets/img/4.png'
import logo5 from '../../assets/img/5.png'
import logo6 from '../../assets/img/6.png'
import logo9 from '../../assets/img/9.png'
import logo10 from '../../assets/img/10.png'
import logo11 from '../../assets/img/11.png'

const logos = [
    logo1, logo2, logo3, logo4, logo5, logo6, logo9, logo10, logo11
]

const LogoSlider = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 200,
    slidesToShow: 6, // Adjust the number of logos shown in a slide
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000, // Adjust the speed of the carousel
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="logo-slider">
        <Slider {...settings}>
        {logos.map((logo, index) => (
            <div key={index}>
            <img src={logo} alt={`Logo ${index + 1}`} />
            </div>
        ))}
        </Slider>
    </div>
  );
};

export default LogoSlider;
