import Vendor from "../../componenets/Vendor"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
// image
import infoIcon from '../../../assets/icons/info.png'
import mealIcon from '../../../assets/icons/meal.png'
import kcalIcon from '../../../assets/icons/kcal.png'
import imageUploadIcon from '../../../assets/icons/photo.png'
import healthIcon from '../../../assets/icons/health.png'
import plusIcon from '../../../assets/icons/plus.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import amountIcon from '../../../assets/icons/amountIcon.png'
import discountIcon from '../../../assets/icons/discount.png'
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { handleAddMenu } from "../Service"
import axios from "axios";

const AddMenu = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [name, setName] = useState('');
    const [mealType, setMealType] = useState('');
    const [protein, setProtein] = useState('');
    const [mealKcal, setMealKcal] = useState('');
    const [mealTime, setMealTime] = useState('');
    const [aboutMeal, setAboutMeal] = useState('');
    const [availableFrom, setAvailableFrom] = useState('');
    const [availableTill, setAvailableTill] = useState('');
    const [mealFile, setMealFile] = useState('');
    const [msg, setMsg] = useState('');
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')

    const [file, setFile] = useState('')

    function handleChange(event) {
        setFile(event.target.files[0])
    }


    useEffect(() => {
        if (state) {
            setName(state.item.name)
            setMealType(state.item.category)
            setMealTime(state.item.meal_type)
            setAboutMeal(state.item.meal_details)
            setAvailableFrom(state.item.day_of_the_week)
            setAvailableTill(state.item.available_till)
            setMealKcal(state.item.meal_kcal)
            setProtein(state.item.meal_protein)
            setPrice(state.item.price)
            setDiscount(state.item.discount)
            setMealFile(state.item.image)
        }
    }, [state])


    const validateFields = () => {
        if (!name || !mealType || !mealTime || !file || !price || !availableTill) {
            setMsg('All fields are required.');
            return false;
        }
        return true;
    }

    const handleAddMenus = async () => {
        if (!validateFields()) return;

        const formData = new FormData();
        formData.append('vendor_id', vendorId);
        formData.append('name', name);
        formData.append('slug', name); // Assuming slug is the same as name
        formData.append('image', file);
        formData.append('price', price);
        formData.append('discount', discount || 0);
        formData.append('meal_type', mealTime);
        formData.append('category', mealType);
        formData.append('meal_kcal', mealKcal || 0);
        formData.append('meal_details', aboutMeal || '-');
        formData.append('meal_protein', protein || 0);
        formData.append('available_from', '2022-12-31T18:30:00.000Z');
        formData.append('available_till', availableTill);
        formData.append('day_of_the_week', availableFrom);
        try {
            const data = await handleAddMenu(formData)
            if (data !== null) {
                setMsg("Menu added")
                setTimeout(() => {
                    navigate('/vendor/menu')
                }, 1000);
            }
            else {
                setMsg("Something went wrong try again later")
                setTimeout(() => {
                    navigate('/vendor/menu')
                }, 3000);
            }
        } catch (error) {
            console.error('Login error:', error.message)
            setMsg("Something went wrong try again later")
            setTimeout(() => {
                navigate('/vendor/menu')
            }, 3000);
        }
    }

    return <>
        <Vendor />
        <div className='frontGreyBg vendorDataContainer'>
            <section className='webContainer py-5'>
                <h1 className='pb-3 font-weight-bold'>Add A New Meal</h1>
                {msg && <p style={{ color: msg.includes('added') ? 'green' : 'red' }}>{msg}</p>}
                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={infoIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Name of the meal"
                                        autoComplete="off" required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={amountIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        autoComplete="off" required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={discountIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="discount"
                                        name="discount"
                                        placeholder="Discount Amount (Optional)"
                                        autoComplete="off" required
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <select name='meal_type' className='form-control frontFormInput' value={mealType}
                                        onChange={(e) => setMealType(e.target.value)}>
                                        <option>Select Meal Type</option>
                                        <option value='Veg'>Veg</option>
                                        <option value='Non-Veg'>Non-Veg</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={kcalIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="meal_kcal"
                                        name="meal_kcal"
                                        placeholder="Meal Kcal (Optional)"
                                        autoComplete="off" required
                                        value={mealKcal}
                                        onChange={(e) => setMealKcal(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={healthIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id=""
                                        name="protein"
                                        placeholder="Protein (Optional)"
                                        autoComplete="off" required
                                        value={protein}
                                        onChange={(e) => setProtein(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={imageUploadIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="file" className="form-control frontFormInput"
                                        id="image"
                                        name="image"
                                        placeholder="Meal Kcal (Optional)"
                                        autoComplete="off" required
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealIcon} alt="" />
                                        </span>
                                    </div>
                                    <select name='available_from' className='form-control frontFormInput' value={availableFrom}
                                        onChange={(e) => setAvailableFrom(e.target.value)}>
                                        <option>Select week days</option>
                                        <option value='Monday'>Monday</option>
                                        <option value='Tuesday'>Tuesday</option>
                                        <option value='Wednesday'>Wednesday</option>
                                        <option value='Thusday'>Thusday</option>
                                        <option value='Friday'>Friday</option>
                                        <option value='Saturday'>Saturday</option>
                                        <option value='Sunday'>Sunday</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <select name='meal_type' className='form-control frontFormInput' value={mealTime}
                                        onChange={(e) => setMealTime(e.target.value)}>
                                        <option>Meal for dinner or lunch</option>
                                        <option value='Lunch'>Lunch</option>
                                        <option value='Dinner'>Dinner</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" className="form-control frontFormInput"
                                        id="availabletill"
                                        name="availabletill"
                                        placeholder="Select Date"
                                        autoComplete="off" required
                                        onChange={(e) => setAvailableTill(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-sm-12 '>
                                <div className="input-group frontInputBox" >
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Add Meal Information (Optional)"
                                        className=""
                                        style={{ height: "200%", width: "100%" }}
                                        onChange={(content) => setAboutMeal(content)}
                                    >
                                    </ReactQuill>
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddMenus}>
                                <div className="frontBtnIcon" >
                                    <img src={plusIcon} alt="" />
                                </div>
                                <p>Add</p>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}

export default AddMenu
