import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper"
// image
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
import userIcon from '../../../assets/icons/user-2.png'
import infoIcon from '../../../assets/icons/info.png'
import mealIcon from '../../../assets/icons/meal.png'
import kcalIcon from '../../../assets/icons/kcal.png'
import imageUploadIcon from '../../../assets/icons/photo.png'
import healthIcon from '../../../assets/icons/health.png'
import checkIcon from '../../../assets/icons/checked.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import amountIcon from '../../../assets/icons/amountIcon.png'
import discountIcon from '../../../assets/icons/discount.png'
import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { handleAddMenu, handleGetSingleMenu, handleUpdateMenu } from "../Service"

const EdiMenu = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [msg, setMsg] = useState('');
    const [name, setName] = useState('');
    const [mealType, setMealType] = useState('');
    const [protein, setProtein] = useState('');
    const [mealKcal, setMealKcal] = useState('');
    const [mealTime, setMealTime] = useState('');
    const [aboutMeal, setAboutMeal] = useState('');
    const [availableFrom, setAvailableFrom] = useState('');
    const [availableTill, setAvailableTill] = useState('');
    const [mealFile, setMealFile] = useState('');
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [price, setPrice] = useState('');
    const [discount, setDiscount] = useState('');


    useEffect(() => {
        const sendData = {
            token: token,
            vendor_id: vendorId,
            menu_id: state.menuId
        }
        const fetchSingleMenu = async () => {
            try {
                const data = await handleGetSingleMenu(sendData)
                setRefresh(false)
                setName(data.name)
                setMealType(data.category)
                setMealTime(data.meal_type)
                setAboutMeal(data.meal_details)
                setAvailableFrom(data.day_of_the_week)
                setAvailableTill(data.available_till)
                setMealKcal(data.meal_kcal)
                setProtein(data.meal_protein)
                setPrice(data.price)
                setDiscount(data.discount)
                setMealFile(data.image)
            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchSingleMenu();
    }, [refresh, token]);

    function handleChange(event) {
        setMealFile(event.target.files[0])

    }

    const handleAddMenus = async () => {
        const regex = /(https:\/\/[^\s]+\.[a-zA-Z]{3,4})/;
        let image = '';

        if (mealFile) {
            const match = mealFile.match(regex);
            if (match) {
                image = match[0];
            }
        }
        const formData = new FormData();
        formData.append('id', state.menuId);
        formData.append('vendor_id', vendorId);
        formData.append('name', name);
        formData.append('slug', name);
        formData.append('image', image);
        formData.append('price', price);
        formData.append('discount', discount || 0);
        formData.append('meal_type', mealTime);
        formData.append('category', mealType);
        formData.append('meal_kcal', mealKcal || 0);
        formData.append('meal_details', aboutMeal || '-');
        formData.append('meal_protein', protein || 0);
        formData.append('available_from', '2022-12-31T18:30:00.000Z');
        formData.append('available_till', availableTill);
        formData.append('day_of_the_week', availableFrom);

        try {
            const data = await handleUpdateMenu(formData)
            if (data !== null) {
                setMsg("Menu updated")
                setTimeout(() => {
                    navigate('/vendor/menu')
                }, 1000);
            }
            else {
                setMsg("Something went wrong try again later")
                setTimeout(() => {
                    navigate('/vendor/menu')
                }, 3000);
            }
        } catch (error) {
            console.error('Login error:', error.message)
            setMsg("Something went wrong try again later")
            setTimeout(() => {
                navigate('/vendor/menu')
            }, 3000);
        }
    }



    return <>

        <Vendor />

        <div className='frontGreyBg vendorDataContainer'>
            <section className='webContainer py-5'>

                <h1 className='pb-3 font-weight-bold'>Update A Meal</h1>
                {msg && <p style={{ color: msg.includes('added') ? 'green' : 'red' }}>{msg}</p>}

                <div className='row'>
                    <div className='col-7'>


                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={infoIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Name of the meal"
                                        autoComplete="off" required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Name */}

                        <div className='row'>


                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={amountIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" class="form-control frontFormInput"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        autoComplete="off" required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>


                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={discountIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" class="form-control frontFormInput"
                                        id="discount"
                                        name="discount"
                                        placeholder="Discount (Optional)"
                                        autoComplete="off" required
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row END price and Discount */}

                        <div className='row'>

                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <select name='meal_type' className='form-control frontFormInput' value={mealType}
                                        onChange={(e) => setMealType(e.target.value)}>
                                        <option>{mealType == '' ? 'Select meal type' : mealType}</option>
                                        <option value='Veg'>Veg</option>
                                        <option value='Non-Veg'>Non-Veg</option>
                                    </select>

                                </div>
                                {/* ./Single Input */}
                            </div>

                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={kcalIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="restaurant_name"
                                        name="restaurant_name"
                                        placeholder="Meal Kcal (Optional)"
                                        autoComplete="off" required
                                        value={mealKcal}
                                        onChange={(e) => setMealKcal(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row End Meal Type And  Calories */}


                        <div className='row'>

                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={healthIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id=""
                                        name="protein"
                                        placeholder="Protein (Optional)"
                                        autoComplete="off" required
                                        value={protein}
                                        onChange={(e) => setProtein(e.target.value)}
                                    />

                                </div>
                                {/* ./Single Input */}
                            </div>

                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={imageUploadIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="file" class="form-control frontFormInput"
                                        id="restaurant_name"
                                        name="restaurant_name"
                                        placeholder="Meal Kcal (Optional)"
                                        autoComplete="off" required
                                        onChange={handleChange}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row End Protein And Image */}


                        <div className='row'>

                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={mealIcon} alt="" />
                                        </span>
                                    </div>
                                    <select name='available_from' className='form-control frontFormInput' value={availableFrom}
                                        onChange={(e) => setAvailableFrom(e.target.value)}>
                                        <option>Select Week Days</option>
                                        <option value='Monday'>Monday</option>
                                        <option value='Tuesday'>Tuesday</option>
                                        <option value='Wednesday'>Wednesday</option>
                                        <option value='Thusday'>Thusday</option>
                                        <option value='Friday'>Friday</option>
                                        <option value='Saturday'>Saturday</option>
                                        <option value='Sunday'>Sunday</option>
                                    </select>

                                </div>
                                {/* ./Single Input */}
                            </div>


                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <select name='meal_type' className='form-control frontFormInput' value={mealTime}
                                        onChange={(e) => setMealTime(e.target.value)}>
                                        <option>{mealTime == '' ? 'Meal for dinner or lunch' : mealTime}</option>
                                        <option value='Lunch'>Lunch</option>
                                        <option value='Dinner'>Dinner</option>
                                    </select>

                                </div>
                                {/* ./Single Input */}
                            </div>


                        </div>
                        {/* ./Row End Meal Time */}
                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" className="form-control frontFormInput"
                                        id="availabletill"
                                        name="availabletill"
                                        placeholder="Select Date"
                                        autoComplete="off" required
                                        onChange={(e) => setAvailableTill(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 '>
                                <div class="input-group frontInputBox" >
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Add Meal Information (Optional)"
                                        className=""
                                        // onChange={handleProcedureContentChange}
                                        style={{ height: "200%", width: "100%" }}
                                        onChange={(content) => setAboutMeal(content)}

                                    >
                                    </ReactQuill>
                                </div>
                            </div>
                        </div>
                        {/* ./Row End Meal Time */}

                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddMenus}>
                                <div className="frontBtnIcon" >
                                    <img src={checkIcon} alt="" />
                                </div>
                                <p>Update</p>
                            </button>
                        </div>
                    </div>
                </div>
                {/* ./Add New Item */}

            </section>

        </div>

    </>
}

export default EdiMenu
