// reducer.js

const initialState = {
    cartCount: '',
};

const CartReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CART_DATA':
            return { ...state, cartCount: action.payload };
        default:
            return state;
    }
};

export default CartReducer;

// const data = useSelector(
//     state => state?.VendorStoreSlugReducer?.UrlUpdate,
//   );
// useEffect(() => {
//     dispatch({ type: 'VENDOR_URL', payload: location.pathname });
// }, [location])