import React, { useEffect, useState } from 'react';
import Admin from '../../components/Admin';
import '../../styles/index.css';
import { url } from '../../../utils/Helper';
import { Link } from 'react-router-dom';
import { handleAddUser } from '../../Service';

const Users = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [refresh, setRefresh] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const usersPerPage = 10;
  const token = localStorage.getItem("token");
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');
  const [selectedRole, setSelectedRole] = useState('');


  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const token = localStorage.getItem('token');
        const apiUrl = selectedRole
          ? `${url}/admin/users/role/${selectedRole}`
          : `${url}/admin/users`;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch users');
        }
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
        setRefresh(false)
      }
    };

    fetchUsers();
  }, [refresh, selectedRole]);



  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = users.slice(indexOfFirstUser, indexOfLastUser);

  const renderUsers = currentUsers?.map((user) => (
    <tr key={user.id}>
      <td>{user.id}</td>
      <td>{user.profile_info}</td>
      <td>{user.phone}</td>
      <td>{user.role}</td>
      <td>
        <Link to={`/admin/user?id=${user.id}&role=${user.role}`}>View Profile</Link>
      </td>
    </tr>
  ));

  const totalPages = Math.ceil(users.length / usersPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };



  const addUser = async () => {
    const sendStatusData = {
      token: token,
      role: role,
      phone: phone,
    }
    try {
      // console.log(sendStatusData)
      // const res = await handleAddUser(sendStatusData)
      await handleAddUser(sendStatusData)
      // console.log(res, 'hell')
      setRefresh(true)
      toggleVisibility()
      // Redirect or perform any other necessary actions
    } catch (error) {
      console.error('Login error:', error.message)
    }
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Admin />

      <div id="adminMain">
        <div className="container m-auto">
          <div className="row mb-3">
            <div className="col-12">
              <div className='card shadow p-2 text-right text-info'>
                Dashboard &gt;&gt; Users
              </div>
            </div>
          </div>

          <div className='row mb-3'>
            <div className='col-12'>
              <div className='bg-white rounded shadow p-2 d-flex justify-content-start'>
                <button onClick={toggleVisibility} className='btn btn-sm btn-primary mr-2'>
                  + Add User
                </button>

                <select className='form-control w-25' id="role" value={selectedRole} onChange={(e) => setSelectedRole(e.target.value)}>
                  <option value="">-Select Role-</option>
                  <option value="customer">Customer</option>
                  <option value="vendor">Vendor</option>
                  <option value="subadmin">Subadmin</option>
                </select>

              </div>

            </div>
          </div>

          <div className="row">
            <div className='col-12'>
              <div className="card p-2 w-100 shadow">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>User ID</th>
                      <th>Name</th>
                      <th>Phone</th>
                      <th>Role</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>{renderUsers}</tbody>
                </table>


              </div>
            </div>
          </div>
          <div style={{ justifySelf: "center", display: "flex", alignItems: "center", justifyContent: "center" }} >
            {[...Array(totalPages)].map((_, index) => (
              <span style={{
                padding: '8px',
                margin: '4px',
                cursor: 'pointer',
                backgroundColor: currentPage === index + 1 ? '#007BFF' : '#FFFFFF',
                color: currentPage === index + 1 ? '#FFFFFF' : '#007BFF',
                borderRadius: '4px',
              }}
                key={index}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
              >
                {index + 1}
              </span>
            ))}
          </div>
        </div>

        {isVisible && (
          <div className="modal">
            <div className="modal-content">
              <span className="close" onClick={toggleVisibility}>
                &times;
              </span>
              <div>
                <div className="form-group">
                  <label htmlFor="phone">Phone:</label>
                  <input
                    type="text"
                    id="phone"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    required
                  />
                </div>
                <div className="form-group">
                  <label htmlFor="role">Role:</label>
                  <select id="role" value={role} onChange={(e) => setRole(e.target.value)}>
                    <option>- Select Role -</option>
                    <option value="customer">Customer</option>
                    <option value="vendor">Vendor</option>
                    <option value="subadmin">Subadmin</option>
                  </select>
                </div>
                <button onClick={addUser}>Submit</button>
              </div>
            </div>
          </div>
        )}

      </div>
    </>
  );
};

export default Users;
