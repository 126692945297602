import '../styles/style.css'

// Images
import defaultLogo from '../../assets/img/defaultLogo.png'
import defalutVendorBg from '../../assets/img/defalutVendorBg.png'
import uploadIcon from '../../assets/icons/photo.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { useState, useEffect } from 'react'
import { url } from '../../utils/Helper'
import Sidebar from './Sidebar'


const Vendor = () => {
    const location = useLocation();
    const navigate = useNavigate()
    const currentPath = location.pathname;
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')
    const userId = localStorage.getItem('user_id')
    const profile = localStorage.getItem('profile')
    const banner = localStorage.getItem('banner')
    const [profileImage, setProfileImage] = useState(null);
    const [bannerImage, setBannerImage] = useState(null);
    const [refresh, setRefresh] = useState(false)
    const [loading, setLoading] = useState(false)


    useEffect(() => {
        const fetchVendorProfile = async () => {
            try {
                // console.log("user id: ", userId)
                const vendorProfile = await fetch(`${url}/vendor/profile/${userId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                const data = await vendorProfile.json()
                if (data) {
                    setRefresh(false)
                    // Get Customer Address
                    setBannerImage(data.banner)
                    setProfileImage(data.image)
                }
            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchVendorProfile();
    }, [userId, token]);


    const handleProfileImageChange = async (event) => {
        setProfileImage(event.target.files[0])
        const formData = new FormData();
        formData.append('image', event.target.files[0]); // Ensure the key matches the key in your backend

        try {
            const response = await fetch(`${url}/vendor/profile/upload-logo/${vendorId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token dynamically
                    // Do not set 'Content-Type' header manually when using FormData
                },
                body: formData,
            });

            if (response.ok) {
                // console.log('Image uploaded successfully');
                window.location.reload();
            } else {
                console.log('Image upload failed');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };

    const handleBannerImageChange = async (event) => {
        setBannerImage(event.target.files[0])
        const formData = new FormData();
        formData.append('banner', event.target.files[0]); // Ensure the key matches the key in your backend
        try {
            const response = await fetch(`${url}/vendor/profile/upload-banner/${vendorId}`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`, // Add token dynamically
                    // Do not set 'Content-Type' header manually when using FormData
                },
                body: formData,
            });

            if (response.ok) {
                // console.log('Image uploaded successfully');
                window.location.reload();
            } else {
                console.log('Image upload failed');
            }
        } catch (error) {
            console.error('Error uploading image:', error);
        }
    };



    return (
        <>

            <header className='vendorHeader' style={{ backgroundImage: `url(${bannerImage ? bannerImage : defalutVendorBg})` }}>
                <div className='webContainer'>
                    <div className='row'>
                        <div className="col-12">
                            <div className='vendorLogoContainer'>
                                <img src={profileImage ? profileImage : defaultLogo} alt="" className='vendorLogo bg-light' />
                                <div className='uploadLogoIcon'>
                                    <label htmlFor="profileImageInput" className='img-fluid' title='Click To Upload Profile Image'>
                                        <img src={uploadIcon} alt="" className='img-fluid' />
                                    </label>
                                    <input type="file" id="profileImageInput" style={{ display: 'none' }} onChange={handleProfileImageChange} />
                                </div>
                            </div>

                            <div className='uploadBannerIcon'>
                                <label htmlFor="bannerImageInput" className='img-fluid' title='Click To Upload Banner Image'>
                                    <img src={uploadIcon} alt="" className='img-fluid' />
                                </label>
                                <input type="file" id="bannerImageInput" style={{ display: 'none' }} onChange={handleBannerImageChange} />
                            </div>
                        </div>
                    </div>

                    <div className='desktop'>
                        <div className='vendorDesktopNav d-flex justify-content-start'>
                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor' && 'vendorNavActive'}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23" fill="none" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="3" width="6" height="6"></rect><rect x="14" y="3" width="6" height="6"></rect><rect x="14" y="14" width="6" height="6"></rect><rect x="3" y="14" width="6" height="6"></rect></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor' ? 'text-dark' : 'text-white'}`}>Dashboard</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/menu") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/menu' || currentPath === '/vendor/add-menu' || currentPath === '/vendor/edit-menu' ? 'vendorNavActive' : null}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><line x1="8" y1="6" x2="21" y2="6"></line><line x1="8" y1="12" x2="21" y2="12"></line><line x1="8" y1="18" x2="21" y2="18"></line><line x1="3" y1="6" x2="3.01" y2="6"></line><line x1="3" y1="12" x2="3.01" y2="12"></line><line x1="3" y1="18" x2="3.01" y2="18"></line></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/menu' || currentPath === '/vendor/add-menu' || currentPath === '/vendor/edit-menu' ? 'text-dark' : 'text-white'}`}>Menu</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/orders") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/orders' && 'vendorNavActive'}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="10" cy="20.5" r="1" /><circle cx="18" cy="20.5" r="1" /><path d="M2.5 2.5h3l2.7 12.4a2 2 0 0 0 2 1.6h7.7a2 2 0 0 0 2-1.6l1.6-8.4H7.1" /></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/orders' ? 'text-dark' : 'text-white'}`}>Orders</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/stock") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/stock' || currentPath === '/vendor/edit-stock' || currentPath === '/vendor/revenue' || currentPath === '/vendor/add-stock' ? 'vendorNavActive' : null}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="12" cy="5" rx="9" ry="3"></ellipse><path d="M21 12c0 1.66-4 3-9 3s-9-1.34-9-3"></path><path d="M3 5v14c0 1.66 4 3 9 3s9-1.34 9-3V5"></path></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/stock' || currentPath === '/vendor/edit-stock' || currentPath === '/vendor/revenue' || currentPath === '/vendor/add-stock' ? 'text-dark' : 'text-white'}`}>Stock</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/coupon") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/coupon' || currentPath === '/vendor/edit-coupon' || currentPath === '/vendor/add-coupon' ? 'vendorNavActive' : null}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"></path></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/coupon' || currentPath === '/vendor/edit-coupon' || currentPath === '/vendor/add-coupon' ? 'text-dark' : 'text-white'}`}>Coupons</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/setting") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/setting' && 'vendorNavActive'}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="3"></circle><path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/setting' ? 'text-dark' : 'text-white'}`}>Settings</p>
                                </a>
                            </div>

                            <div className="d-flex justify-content-center">
                                <a onClick={() => { navigate("/vendor/tickets") }} className={`btn vendorDesktopNavBtn ${currentPath === '/vendor/tickets' || currentPath === '/vendor/tickets/new-ticket' ? 'vendorNavActive' : null}`}>
                                    <div className="vendorDesktopBtnIcon">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 24 24" fill="none" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>
                                    </div>
                                    <p className={`${currentPath === '/vendor/tickets' || currentPath === '/vendor/tickets/new-ticket' ? 'text-dark' : 'text-white'}`}>Support</p>
                                </a>
                            </div>

                        </div>
                    </div>
                </div>
            </header>

            <Sidebar />


        </>
    )
}

export default Vendor