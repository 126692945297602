
import formIcon from '../assets/icons/smartphone.png'
import backArrow from '../assets/icons/back.png'
import cart from '../assets/icons/cart.png'
import { url, SuccessURL, CancelURL } from '../utils/Helper'
import React, { useState, useRef, useEffect } from 'react';
import HeaderNavigation from './components/HeaderNavigation';
import { useNavigate, useLocation } from 'react-router-dom';

const SelectAddress = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [showSelectedaddress, setShowSelectedAddress] = useState(0)
    const [predictions, setPredictions] = useState([]);
    const [input, setInput] = useState('');
    const [selectedPlace, setSelectedPlace] = useState(null);
    const autocompleteService = useRef(null);
    const [refresh, setRefresh] = useState(false);
    const [addresses, setAddresses] = useState([]);
    const token = localStorage.getItem('token')
    const customerId = localStorage.getItem('customer_id')
    const orderId = localStorage.getItem('order_id')
    const totalDiscount = localStorage.getItem('totalDiscount')
    const location = useLocation();


    useEffect(() => {
        if (!autocompleteService.current) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }

        return () => {
            // Cleanup function
            setPredictions([]);
        };
    }, []);

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleAutoCompletePlaceSelected = (placeId) => {
        if (window.google) {
            const placesService = new window.google.maps.places.PlacesService(document.createElement('div'));
            placesService.getDetails(
                {
                    placeId,
                    fields: ['name', 'formatted_address', 'geometry'],
                },
                (place, status) => {
                    if (status === 'OK') {
                        setInput(place.formatted_address);
                        handleAddNewAddress(place.formatted_address)
                        setSelectedPlace(place);
                        setPredictions([]);
                    }
                }
            );
        }
    };


    useEffect(() => {
        if (input.trim() === '') {
            setPredictions([]);
            return;
        }

        autocompleteService.current.getPlacePredictions({ input }, (predictions, status) => {
            if (status === 'OK') {
                setPredictions(predictions || []);
            } else {
                setPredictions([]);
            }
        });
    }, [input]);


    useEffect(() => {
        getAddresses();
    }, [refresh]);


    const getAddresses = async () => {
        try {
            const getAddressdata = await fetch(`${url}/customer/addresses/${customerId}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },

            })
            if (!getAddressdata.ok) {
                throw new Error('Failed to fetch users')
            }

            const data = await getAddressdata.json()
            if (data) {
                // console.log(data)
                setAddresses(data)
                setRefresh(false)
                if (data > 0) {
                    showSelectedaddress(0)
                }
                else {
                    showSelectedaddress(1)
                }

            }
            else {
                console.log('no data')
                setRefresh(false)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }


    const handleAddNewAddress = async (address) => {
        const addAddress = await fetch(`${url}/customer/profile/new-address`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "address": address,
                "customerId": customerId,
                "pincode": "",
                "landmark": "",
                "address_type": "Home"
            }),
        })
        if (!addAddress.ok) {
            throw new Error('Failed to fetch users')
        }
        const data = await addAddress.json()
        if (data) {
            localStorage.setItem("address", address)
            setRefresh(true)
            window.location.reload()
        }
    }



    const handlePaymentGateway = async () => {
        const paymentGateway = await fetch(`${url}/customer/stripe/payment`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "orderId": orderId,
                "discountAmount": totalDiscount || 0,
                "successUrl": SuccessURL,
                "cancelUrl": CancelURL

            }),
        })
        if (!paymentGateway.ok) {
            throw new Error('Failed to fetch users')
        }
        const data = await paymentGateway.json()
        if (data) {
            localStorage.setItem("paymentMethod", "Paid")
            // console.log(data)
            // setCartItems(data)
            window.open(data.url, "_self")
            setRefresh(true)
        }


    }

    const handleCashOnDelivery = () => {
        localStorage.setItem("paymentMethod", "Cash On Delivery")
        navigate('/thankyou')
    }


    return <>

        <section className="frontCheckout frontGreyBg">
            <div className="webContainer">
                {/* Header */}
                <HeaderNavigation pageName={'Select address'} refreshed={loading} />

                {/* Add New Address If No Address Found */}
                <div className='row'>
                    {showSelectedaddress == 0 &&
                        <div className='col-sm-5'>
                            <h4 className='font-weight-bold mt-5 text-left'>Choose Your Address</h4>
                            {addresses?.map((item) =>
                                <button onClick={() => {
                                    localStorage.setItem("address", item.address)
                                }} className='btn bg-white text-dark text-left w-100 p-3 mb-2 border-1'>{item.address}</button>

                            )}
                        </div>
                    }

                    <div className="col-sm-5 m-auto">
                        <h4 className='font-weight-bold mt-5 text-left'>Add New Address</h4>
                        <div class="input-group frontInputBox mt-2">
                            <div class="input-group-prepend">
                                <span class="input-group-text frontFromIcon">
                                    <img src={formIcon} alt="" />
                                </span>
                            </div>
                            <input type="text" class="form-control frontFormInput"
                                id="address"
                                name="address"
                                placeholder="Your Address"
                                autoComplete="off"
                                value={input} onChange={handleInputChange}
                            />
                        </div>

                        {/*    Select Address List */}
                        {predictions.map((prediction) => (
                            <button className='btn bg-white text-dark text-left border-bottom rounded-0 w-100 p-2' key={prediction.place_id} onClick={() => { handleAutoCompletePlaceSelected(prediction.place_id) }}>
                                {prediction.description}
                            </button>
                        ))}
                    </div>

                </div>

                <div className='fixBottomPayment'>


                    <div className='d-flex'>

                        <button onClick={() => {
                            handlePaymentGateway()
                        }} className='btn frontStripeBtn w-100'>Continue with Stripe</button>


                        <button onClick={() => {
                            handleCashOnDelivery()
                        }} className='btn codBtn w-100'>Pay On Delivery</button>



                    </div>
                </div>


            </div>

        </section>

    </>
}

export default SelectAddress;



