import { url } from "../utils/Helper"

export const handleAddReply = async (data) => {
  try {
    const response = await fetch(`${url}/admin/ticket/reply`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "message": data.message,
        'ticketId': data.ticket_id,
        'senderId': data.sender_id,
        "receiverId": data.receiver_id,
        "image": data.image
      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleChangeStatus = async (data) => {
  try {
    const response = await fetch(`${url}/admin/ticket/update/${data.ticket_id}`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "status": data.status,

      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleUpdateSetting = async (data) => {
  try {
    const response = await fetch(`${url}/admin/settings/update/${data.setting_id}`, {
      method: 'PUT',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "body": data.body,
      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleAddUser = async (data) => {
  try {
    const response = await fetch(`${url}/admin/users/add-user`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "phone": data.phone,
        "password": "123456789",
        "role": data.role
      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}

export const handleUpdateVendorProfile = async (data) => {
  try {
    const response = await fetch(`${url}/admin/users/vendor-profile/update`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${data.token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "profileData": {
          "user_id": data.user_id,
          "name": data.name,
          "slug": data.name,
          "resturant": data.resturant,
          "email": data.email,
          "phone": data.phone,
          "image": "",
          "banner": "",
          "address": data.address,
          "pincode": data.pincode,
          "country": data.country,
          "vendor_id": data.vendor_id,
          "meal_type": data.meal_type,
          "no_of_meals": data.no_of_meals,
          "monthly_active_days": data.monthly_active_days,
          "no_of_delivery_in_day": data.no_of_delivery_in_day,
        }
      }),
    })
    const resData = await response.json()

    // console.log(resData)
    return resData // API returns success data
  } catch (error) {
    console.error('Error in getting data', error.message)
    throw error
  }
}