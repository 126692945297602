import Admin from '../../components/Admin'
import '../../styles/index.css'
import VendorProfile from './VendorProfile'
import CustomerProfile from './CustomerProfile'
import { useLocation } from 'react-router-dom';

const UserProfile = () => {

  // Retrieve user.id and user.role from the query parameters
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const userId = params.get('id');
  const userRole = params.get('role');


  return (
    <>
      <Admin />

      <div id="adminMain">

        <div className='container'>


          <div className="row mb-3">
            <div className='col-12'>
              <div className='card p-2 w-100 shadow text-right text-info'>
                Dashboard &gt;&gt; Users</div>
            </div>
          </div>

          {userRole === 'vendor' && <VendorProfile userId={userId} />}
          {userRole === 'customer' && <CustomerProfile userId={userId} />}

        </div>

      </div>
    </>
  );
}

export default UserProfile