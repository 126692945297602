import Vendor from "../../componenets/Vendor"
import { url } from "../../../utils/Helper"
// image
import checkIcon from '../../../assets/icons/checked.png'
import userIcon from '../../../assets/icons/user-2.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import calanderIcon from '../../../assets/icons/calendar.png'
import { useLocation, useNavigate } from "react-router-dom"
import { useState, useEffect } from "react"

const EditStock = () => {
    const location = useLocation()
    const { state } = location
    const [loading, setLoading] = useState(true);
    const [itemName, setItemName] = useState('');
    const [quantity, setQuantity] = useState('');
    const [purchaseDate, setPurchaseDate] = useState('');
    const [price, setPrice] = useState('');
    const [msg, setMsg] = useState('');
    const token = localStorage.getItem('token')
     const vendorId = localStorage.getItem('vendor_id')
    const navigate = useNavigate()
    // stocks/single-stock/2


    useEffect(() => {
        const fetchSingleStock = async () => {
            try {
                const stockData = await fetch(`${url}/vendor/stocks/single-stock/${state.stockId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },

                })
                if (!stockData.ok) {
                    throw new Error('Failed to fetch users')
                }
                const data = await stockData.json()
                console.log(data)
                setItemName(data[0]?.item_name)
                setQuantity(data[0]?.qty)
                setPurchaseDate(data[0]?.purchased_on)
                setPrice(data[0]?.price)

            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchSingleStock();
    }, [token, vendorId]);

    const handleUpdateStock = async () => {
        try {
            const updateStock = await fetch(`${url}/vendor/stocks/update-stock`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "stockData": {
                        "vendorId": vendorId,
                        "stockId": state.stockId,
                        "item_name": itemName,
                        "qty": quantity,
                        "purchased_on": purchaseDate,
                        "price": price
                    }
                }),
            })
            if (!updateStock.ok) {
                throw new Error('Failed to fetch users')
            }
            const data = await updateStock.json()
            console.log(data)
            setMsg('Stock Updated')
            setTimeout(() => {
                navigate('/vendor/stock')
                setMsg('')
            }, 2000);

        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    return <>
        <Vendor />

        <div className='frontGreyBg vendorDataContainer'>

            <section className='webContainer py-5'>

                <h1 className='pb-3 font-weight-bold'>Add New Stock</h1>

                <div className='row'>
                    <div className='col-7'>


                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Item Name"
                                        autoComplete="off" required
                                        value={itemName}
                                        onChange={(e) => setItemName(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Name */}

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" class="form-control frontFormInput"
                                        id="qty"
                                        name="qty"
                                        placeholder="Quantity"
                                        autoComplete="off" required
                                        value={quantity}
                                        onChange={(e) => setQuantity(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>
                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" class="form-control frontFormInput"
                                        id="price"
                                        name="price"
                                        placeholder="Price"
                                        autoComplete="off" required
                                        value={price}
                                        onChange={(e) => setPrice(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row End Meal Type And  Calories */}



                        <div className='row'>


                            <div className='col-sm-6'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={calanderIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" class="form-control frontFormInput"
                                        id="purchased_on"
                                        name="purchased_on"
                                        placeholder="Purchased On"
                                        autoComplete="off" required
                                        value={purchaseDate}
                                        onChange={(e) => setPurchaseDate(e.target.value)}
                                        onClick={(e) => e.currentTarget.showPicker()}
                                    />
                                </div>
                                {/* ./Single Input */}

                            </div>

                        </div>
                        <div>{msg}</div>
                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleUpdateStock} >
                                <div className="frontBtnIcon" >
                                    <img src={checkIcon} alt="" />
                                </div>
                                <p>Update Stock</p>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>

    </>
}
export default EditStock