import { combineReducers } from 'redux';
import { legacy_createStore as createStore } from 'redux';
import { applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import CartReducer from '../reducers/CartReducer';


const rootReducer = combineReducers(
    {
        CartReducer: CartReducer
    }
);
const configurestore = () => {
    return createStore(rootReducer, applyMiddleware(thunk));
}
export default configurestore;