import Vendor from "../../componenets/Vendor"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
// image
import userIcon from '../../../assets/icons/user-2.png'
import plusIcon from '../../../assets/icons/plus.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import calanderIcon from '../../../assets/icons/calendar.png'
import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { url } from "../../../utils/Helper";

const AddCoupon = () => {
    const navigate = useNavigate()
    const [name, setName] = useState('');
    const [aboutCoupon, setAboutCoupon] = useState('');
    const [discount, setDiscount] = useState('');
    const [availableTill, setAvailableTill] = useState('');
    const [code, setCode] = useState('');
    const [msg, setMsg] = useState('');
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')

    const validateFields = () => {
        if (!name || !aboutCoupon || !discount || !availableTill || !code) {
            setMsg('All fields are required.');
            return false;
        }
        return true;
    }

    const handleAddCoupon = async () => {
        if (!validateFields()) return;

        try {
            const response = await fetch(`${url}/vendor/coupons`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendor_id": vendorId,
                    "name": name,
                    "discount": discount,
                    "code": code,
                    "details": aboutCoupon,
                    "expiry_date": availableTill
                }),
            })
            const resData = await response.json()
            if (resData) {
                // console.log(resData);
                setMsg("Coupon added")
                setTimeout(() => {
                    navigate("/vendor/coupon")
                }, 1000);
            }
        } catch (error) {
            console.error('Error in getting data', error.message)
            throw error
        }
    }

    return <>
        <Vendor />

        <div className='frontGreyBg'>
            <section className='webContainer py-5'>
                <h1 className='pb-3 font-weight-bold'>Add A New Coupon</h1>
                {msg && <p style={{ color: msg.includes('added') ? 'green' : 'red' }}>{msg}</p>}
                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Name of the coupon"
                                        autoComplete="off" required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        autoComplete="off" required
                                        value={code}
                                        onChange={(e) => setCode(e.target.value.toUpperCase())}
                                    />
                                </div>
                            </div>

                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" className="form-control frontFormInput"
                                        id="expiry"
                                        name="expiry"
                                        placeholder="expiry"
                                        autoComplete="off" required
                                        value={availableTill}
                                        onChange={(e) => setAvailableTill(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="discount"
                                        name="discount"
                                        placeholder="Enter discount"
                                        autoComplete="off" required
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='row'>
                            <div className='col-sm-12 '>
                                <div className="input-group frontInputBox">
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Enter about coupon"
                                        className=""
                                        style={{ height: "200%", width: "100%" }}
                                        onChange={(content) => setAboutCoupon(content)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddCoupon}>
                                <div className="frontBtnIcon">
                                    <img src={plusIcon} alt="" />
                                </div>
                                <p>Add</p>
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>
}

export default AddCoupon
