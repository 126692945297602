import React, { useEffect, useState } from 'react';
import Admin from '../../components/Admin';
import '../../styles/index.css';
import { url } from '../../../utils/Helper';
import DateTime from '../../../utils/DateTime';

const ListContacts = () => {
  const [contacts, setContacts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const contactsPerPage = 1;

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${url}/admin/contacts`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch contacts');
        }

        const data = await response.json();
        setContacts(data);
      } catch (error) {
        console.error('Error fetching contacts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  const indexOfLastContact = currentPage * contactsPerPage;
  const indexOfFirstContact = indexOfLastContact - contactsPerPage;
  const currentContacts = contacts.slice(indexOfFirstContact, indexOfLastContact);

  const renderContacts = currentContacts.map((contact) => (
    <tr key={contact.id}>
      <td>{contact.first_name} {contact.last_name}</td>
      <td>{contact.email}</td>
      <td>{contact.phone_number}</td>
      <td width="60%">
        <DateTime apiDateTime={contact.created_at} />
        <hr />
        {contact.body}
      </td>
    </tr>
  ));

  const totalPages = Math.ceil(contacts.length / contactsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Admin />

      <div id="adminMain">
        <div className="container">
          <div className="row mb-3">
            <div className='col-12'>
              <div className='card p-2 w-100 shadow text-right text-info'>
              Dashboard &gt;&gt; Contacts</div>
              </div>
          </div>

          <div className='row'>
            <div className='col-12'>
            <div className='card p-2 w-100 shadow'>
              <table className='table table-striped'>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Date & Message</th>
                  </tr>
                </thead>
                <tbody>{renderContacts}</tbody>
              </table>

              <div className="pagination" style={{ justifySelf: "center", display: "flex", alignItems: "center", justifyContent: "center" }}>
                {[...Array(totalPages)].map((_, index) => (
                  <span
                    key={index}
                    onClick={() => handlePageChange(index + 1)}
                    style={{
                      padding: '8px',
                      margin: '4px',
                      cursor: 'pointer',
                      backgroundColor: currentPage === index + 1 ? '#007BFF' : '#FFFFFF',
                      color: currentPage === index + 1 ? '#FFFFFF' : '#007BFF',
                      borderRadius: '4px',
                    }}
                  >
                    {index + 1}
                  </span>
                ))}
              </div>
            </div>

            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListContacts;
