import VendorMenu from './components/VendorMenu';
import { url } from '../utils/Helper';
import mealImg from '../assets/img/12.jpg';
import infoIcon from '../assets/icons/info.png';
import closeIcon from '../assets/icons/close.svg'
import defaultMenuBg from '../assets/img/defalutVendorBg.png'
import { useDispatch, useSelector, } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';

const VendorPage = () => {
    const dispatch = useDispatch()
    const [refresh, setRefresh] = useState(false);
    const [notAvailable, setNotAvailable] = useState(false);
    const [viewMenu, setViewMenu] = useState(false);
    const [modalData, setModalData] = useState('');
    const [loading, setLoading] = useState(true);
    const [ProfileData, setProfileData] = useState(null);
    const [menuItems, setMenuItems] = useState([]);
    const { resturanname } = useParams();
    const [arrowDisable, setArrowDisable] = useState(true);
    const [cartQty, setCartQty] = useState({})
    const [cardWidth, setCardWidth] = useState(0);
    const orderId = localStorage.getItem('order_id')
    const vendorId = localStorage.getItem('vendor_id')
    const userRole = localStorage.getItem('role')
    const location = useLocation()


    //  today's date
    const todayDate = new Date().toISOString().split('T')[0];

    // Function to generate a random token
    function generateToken() {
        // Generate a random string
        return Math.random().toString(36).substr(2);
    }

    // Function to check if token exists in localStorage
    function checkToken() {
        // Check if token already exists in localStorage
        const deviceToken = localStorage.getItem('deviceToken');

        if (deviceToken) {
            // Token exists, return it
            return deviceToken;
        } else {
            // Token doesn't exist, generate a new one
            const newToken = generateToken();
            // Save the new token in localStorage
            localStorage.setItem('deviceToken', newToken);
            // Return the newly generated token
            return newToken;
        }
    }

    // Usage
    const deviceToken = checkToken();
    // console.log("Device Token:", deviceToken);

    useEffect(() => {
        localStorage.setItem('vendorPage', location.pathname)
        const fetchDashboardData = async () => {
            try {
                const getDashboard = await fetch(`${url}/front/vendor-profile/${resturanname}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                const data = await getDashboard.json()
                if (data) {
                    setProfileData(data)
                    addCart(data.id)
                    setRefresh(false)
                    // console.log(data)
                    if (userRole != null && userRole == 'vendor') {
                        localStorage.clear()
                        localStorage.setItem('vendor_id', data?.id)
                    }
                    else {
                        localStorage.setItem('vendor_id', data?.id)
                    }

                }
                else {
                    console.log('no data')
                    setRefresh(false)
                }
            } catch (error) {
                console.error('Error fetching users:', error)
                setNotAvailable(true)
            } finally {
                setLoading(false)
            }
        }

        fetchDashboardData();
    }, [vendorId, userRole]);


    const addCart = async (id) => {
        if (deviceToken !== null) {
            const getAddTocartData = await fetch(`${url}/front/addToCart`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendor_id": id,
                    "device": deviceToken, // store this in local storage.
                    "payment_status": "In Cart"
                }),
            })
            const data = await getAddTocartData.json()
            // console.log(data)
            if (data?.addCart?.id) {
                localStorage.setItem('order_id', data?.addCart?.id)
                fetchVendorMenu(id, data?.addCart?.id);
            }
            else {
                fetchVendorMenu(id, orderId);
            }
        }
    }




    const fetchVendorMenu = async (id, cartId) => {
        try {
            const getMenuData = await fetch(`${url}/front/vendor-page-menu/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendorId": id,
                    "orderId": Number(cartId),
                    "date": todayDate
                }),
            })
            const data = await getMenuData.json()
            if (getMenuData.ok) {
                setMenuItems(data)
                setRefresh(false)
            }
            else {
                setNotAvailable(true)
                // console.log('no data')
                setRefresh(false)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }




    const filterMenuItems = (mealType, category) => {
        if (menuItems) {
            return menuItems?.filter(item => item?.meal_type === mealType && item?.category
                === category);
        }
    };

    const handleAddToCart = async (item) => {
        setCartQty((prevQty) => ({
            ...prevQty,
            [item.id]: (prevQty[item.id]) + 1, // Increment quantity by 1
        }));
        // console.log(item)
        const addItemsOfCart = await fetch(`${url}/front/insertCartItems`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "order_id": orderId,
                "vendor_id": ProfileData?.id,
                "item_name": item.name,
                "menu_item_id": item.id,
                "qty": '1',
                "price": item.price, //data?.addCart?.price
                "discount": item.discount,
                "delivery_date": item.available_till,
                "status": "New Order"
            }),
        })
        const dataRes = await addItemsOfCart.json()
        if (dataRes) {
            // console.log(dataRes, "addwdtocart")
            setRefresh(true)
            // window.location.reload()
            fetchVendorMenu(vendorId, orderId)
        }
    }

    const handleRemoveFromCart = async (item) => {
        setCartQty((prevQty) => ({
            ...prevQty,
            [item.id]: Math.max((prevQty[item.id]) - 1, 0), // Decrement quantity by 1, but not below 0
        }));
        const removeItemsOfCart = await fetch(`${url}/front/reduceOrderItemQty`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "order_id": orderId,
                "vendor_id": ProfileData?.id,
                "menu_item_id": item.id,
                "item_name": item.name,
                "qty": '1',
                "price": item.price, //data?.addCart?.price
                "discount": item.discount
            }),
        })
        const dataRes = await removeItemsOfCart.json()
        if (dataRes) {
            // console.log(dataRes, "removeCart")
            setRefresh(true)
            fetchVendorMenu(vendorId, orderId)
        }
    };

    const elementRef = useRef(null);
    const elementRef1 = useRef(null);
    const elementRef2 = useRef(null);
    const elementRef3 = useRef(null);

    const handleHorizantalScroll = (element, speed, distance, step) => {
        let scrollAmount = 0;
        const slideTimer = setInterval(() => {
            element.scrollLeft += step;
            scrollAmount += Math.abs(step);
            if (scrollAmount >= distance) {
                clearInterval(slideTimer);
            }
            if (element.scrollLeft === 0) {
                setArrowDisable(true);
            } else {
                setArrowDisable(false);
            }
        }, speed);
    };

    if (notAvailable) {
        return (
            <center style={{ paddingTop: '200px', background: '#f8f8f8', height: '100vh' }}>
                <h2>😕</h2>
                <h3>Profile is not available at the moment. Please try after some time.</h3>
            </center>
        )
    }


    return <>

        <VendorMenu refreshed={refresh} ProfileData={ProfileData} />

        {filterMenuItems("Lunch", "Veg") != "" &&
            <section className='webContainer mt-2'>
                <h2 className='mt-5 text-left'>Veg Meals For Lunch</h2>
                <div className="horizontal-scroll-container" >
                    <button type='button' className='prev-button' onClick={() => {
                        handleHorizantalScroll(elementRef.current, 10, 400, -10);
                    }}
                        disabled={arrowDisable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 19L8 12L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>
                    <div className="scroll-content" ref={elementRef}>
                        {filterMenuItems("Lunch", "Veg")?.map((item, index) =>
                            <div className="col-sm-3 mb-3" key={index}>
                                <div className="card border-0 bg-white cardShadow">
                                    <div onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>
                                        <p className="text-center mb-1">{item.day_of_the_week}</p>
                                        <img src={item.image} alt="No image uploaded for this meal" className="img-fluid w-100 mealCardImg" />
                                        <div className="d-flex justify-content-between align-items-end mt-2">
                                            <span className="frontVegTag">{item.category}</span>
                                            <span className="frontKcal">{item.meal_kcal} Kcal</span>
                                            <img src={infoIcon} alt="" className="img-fluid infoIcon" />
                                        </div>
                                        <div className="my-3" style={{ cursor: "pointer" }}>
                                            <h5>
                                                {item.name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='frontCartAddQty'>
                                        <button onClick={() => handleRemoveFromCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.0294" cy="11.0294" r="10.5294" fill="white" stroke="#53BC9D" />
                                                <line x1="4.41187" y1="11.2647" x2="17.6472" y2="11.2647" stroke="#53BC9D" />
                                            </svg>
                                        </button>
                                        <span className="mx-2">{cartQty[item.id] || item?.total_qty?.replace(/\.?0+$/, '') || 0}</span>
                                        <button onClick={() => handleAddToCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.9706" cy="11.0294" r="11.0294" fill="#53BC9D" />
                                                <line x1="5.35303" y1="11.2647" x2="18.5883" y2="11.2647" stroke="white" />
                                                <line x1="12.2058" y1="17.6471" x2="12.2058" y2="4.41177" stroke="white" />
                                            </svg>
                                        </button>
                                        <span className="menuMealTimetag">For {item.meal_type}</span>
                                    </div>
                                </div>

                            </div>

                        )}

                    </div>

                    <button className="next-button" onClick={() => {
                        handleHorizantalScroll(elementRef.current, 10, 400, 10);
                    }} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5L16 12L9 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>

                </div>
            </section>
        }


        {filterMenuItems("Lunch", "Non-Veg") != "" &&
            <section className='webContainer mt-2'>
                <h2 className='mt-5 text-left'>Non-Veg Meals For Lunch</h2>
                <div className="horizontal-scroll-container" >
                    <button type='button' className='prev-button' onClick={() => {
                        handleHorizantalScroll(elementRef1.current, 10, 400, -10);
                    }}
                        disabled={arrowDisable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 19L8 12L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>
                    <div className="scroll-content" ref={elementRef1}>
                        {filterMenuItems("Lunch", "Non-Veg")?.map((item, index) =>
                            <div className="col-sm-3 mb-3" key={index}  >
                                <div className="card border-0 bg-white cardShadow">
                                    <div onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>
                                        <p className="text-center mb-1">{item.day_of_the_week}</p>
                                        <img src={item.image} alt="No image uploaded for this meal" className="img-fluid w-100 mealCardImg" />
                                        <div className="d-flex justify-content-between align-items-end mt-2">
                                            <span className="frontVegTag">{item.category}</span>
                                            <span className="frontKcal">{item.meal_kcal} Kcal</span>
                                            <img src={infoIcon} alt="" className="img-fluid infoIcon" />
                                        </div>
                                        <div className="my-3">
                                            <h5>
                                                {item.name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='frontCartAddQty'>
                                        <button onClick={() => handleRemoveFromCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.0294" cy="11.0294" r="10.5294" fill="white" stroke="#53BC9D" />
                                                <line x1="4.41187" y1="11.2647" x2="17.6472" y2="11.2647" stroke="#53BC9D" />
                                            </svg>
                                        </button>
                                        <span className="mx-2">{cartQty[item.id] || item?.total_qty?.replace(/\.?0+$/, '') || 0}</span>
                                        <button onClick={() => handleAddToCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.9706" cy="11.0294" r="11.0294" fill="#53BC9D" />
                                                <line x1="5.35303" y1="11.2647" x2="18.5883" y2="11.2647" stroke="white" />
                                                <line x1="12.2058" y1="17.6471" x2="12.2058" y2="4.41177" stroke="white" />
                                            </svg>
                                        </button>
                                        <span className="menuMealTimetag">For {item.meal_type}</span>
                                    </div>
                                </div>

                            </div>

                        )}

                    </div>

                    <button className="next-button" onClick={() => {
                        handleHorizantalScroll(elementRef1.current, 10, 400, 10);
                    }} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5L16 12L9 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>

                </div>
            </section>
        }

        {filterMenuItems("Dinner", "Veg") != "" &&
            <section className='webContainer mt-2'>
                <h2 className='mt-5 text-left'>Veg Meals For Dinner</h2>
                <div className="horizontal-scroll-container" >
                    <button type='button' className='prev-button' onClick={() => {
                        handleHorizantalScroll(elementRef2.current, 10, 400, -10);
                    }}
                        disabled={arrowDisable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 19L8 12L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>
                    <div className="scroll-content " ref={elementRef2}>
                        {filterMenuItems("Dinner", "Veg")?.map((item, index) =>
                            <div className="col-sm-3 mb-3" key={index}  >
                                <div className="card border-0 bg-white cardShadow">
                                    <div onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>
                                        <p className="text-center mb-1">{item.day_of_the_week}</p>
                                        <img src={item.image} alt="No image uploaded for this meal" className="img-fluid w-100 mealCardImg" />
                                        <div className="d-flex justify-content-between align-items-end mt-2">
                                            <span className="frontVegTag">{item.category}</span>
                                            <span className="frontKcal">{item.meal_kcal} Kcal</span>
                                            <img src={infoIcon} alt="" className="img-fluid infoIcon" />
                                        </div>
                                        <div className="my-3">
                                            <h5>
                                                {item.name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='frontCartAddQty'>
                                        <button onClick={() => handleRemoveFromCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.0294" cy="11.0294" r="10.5294" fill="white" stroke="#53BC9D" />
                                                <line x1="4.41187" y1="11.2647" x2="17.6472" y2="11.2647" stroke="#53BC9D" />
                                            </svg>
                                        </button>
                                        <span className="mx-2">{cartQty[item.id] || item?.total_qty?.replace(/\.?0+$/, '') || 0}</span>
                                        <button onClick={() => handleAddToCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.9706" cy="11.0294" r="11.0294" fill="#53BC9D" />
                                                <line x1="5.35303" y1="11.2647" x2="18.5883" y2="11.2647" stroke="white" />
                                                <line x1="12.2058" y1="17.6471" x2="12.2058" y2="4.41177" stroke="white" />
                                            </svg>
                                        </button>
                                        <span className="menuMealTimetag">For {item.meal_type}</span>
                                    </div>
                                </div>

                            </div>

                        )}

                    </div>

                    <button className="next-button" onClick={() => {
                        handleHorizantalScroll(elementRef2.current, 10, 400, 10);
                    }} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5L16 12L9 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>

                </div>
            </section>
        }

        {filterMenuItems("Dinner", "Non-Veg") != "" &&
            <section className='webContainer mt-2'>
                <h2 className='mt-5 text-left'>Non-Veg Meals For Dinner</h2>
                <div className="horizontal-scroll-container" >
                    <button type='button' className='prev-button' onClick={() => {
                        handleHorizantalScroll(elementRef3.current, 10, 400, -10);
                    }}
                        disabled={arrowDisable}>
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15 19L8 12L15 5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>
                    <div className="scroll-content " ref={elementRef3}>
                        {filterMenuItems("Dinner", "Non-Veg")?.map((item, index) =>
                            <div className="col-sm-3 mb-3" key={index}  >
                                <div className="card border-0 bg-white cardShadow">
                                    <div onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>
                                        <p className="text-center mb-1">{item.day_of_the_week}</p>
                                        <img src={item.image} alt="No image uploaded for this meal" className="img-fluid w-100 mealCardImg" />
                                        <div className="d-flex justify-content-between align-items-end mt-2">
                                            <span className="frontVegTag">{item.category}</span>
                                            <span className="frontKcal">{item.meal_kcal} Kcal</span>
                                            <img src={infoIcon} alt="" className="img-fluid infoIcon" />
                                        </div>
                                        <div className="my-3">
                                            <h5>
                                                {item.name}
                                            </h5>
                                        </div>
                                    </div>
                                    <div className='frontCartAddQty'>
                                        <button onClick={() => handleRemoveFromCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.0294" cy="11.0294" r="10.5294" fill="white" stroke="#53BC9D" />
                                                <line x1="4.41187" y1="11.2647" x2="17.6472" y2="11.2647" stroke="#53BC9D" />
                                            </svg>
                                        </button>
                                        <span className="mx-2">{cartQty[item.id] || item?.total_qty?.replace(/\.?0+$/, '') || 0}</span>
                                        <button onClick={() => handleAddToCart(item)} className="btn">
                                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="11.9706" cy="11.0294" r="11.0294" fill="#53BC9D" />
                                                <line x1="5.35303" y1="11.2647" x2="18.5883" y2="11.2647" stroke="white" />
                                                <line x1="12.2058" y1="17.6471" x2="12.2058" y2="4.41177" stroke="white" />
                                            </svg>
                                        </button>
                                        <span className="menuMealTimetag">For {item.meal_type}</span>
                                    </div>
                                </div>

                            </div>
                        )}
                    </div>
                    <button className="next-button" onClick={() => {
                        handleHorizantalScroll(elementRef3.current, 10, 400, 10);
                    }} >
                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9 5L16 12L9 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>

                    </button>
                </div>
            </section>
        }

        {viewMenu === true ? (

            <div className="modal">
                <div className="modal-content1 bg-theme">
                    <span className="close fw-light" onClick={() => { setViewMenu(!viewMenu); setModalData('') }}>
                        <img src={closeIcon} alt="close" />
                    </span>
                    <div className="modalImage">
                        <img src={modalData.image} alt="background" />
                    </div>
                    <div>
                        <div className="modalBody">
                            <div className="modalTitle">
                                <h3>{modalData.name}</h3>
                            </div>
                            <div className="bg-white p-2 d-flex justify-content-between">
                                <h6 className="mb-0 font-weight-light">Price: {modalData.price}</h6>
                                <h6 className="mb-0 font-weight-light">Discount: {modalData.discount}</h6>
                                <h6 className="mb-0 font-weight-light">Kcal: {modalData.meal_kcal}</h6>
                                <h6 className="mb-0 font-weight-light">Protien: {modalData.meal_protein}</h6>
                                <h6 className="mb-0 font-weight-light">Avl: {modalData.day_of_the_week}</h6>
                            </div>

                            <div className="font-weight-normal">
                                {<div dangerouslySetInnerHTML={{ __html: modalData.meal_details }} />}
                                {/* <p className="mt-2 font-weight-normal text-dark">{modalData.meal_details}
                                </p> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        ) : null}


    </>
}

export default VendorPage