import React, { useState } from 'react';
import closeIcon from '../../assets/icons/close.svg'
import { useNavigate } from 'react-router-dom';

const Sidebar = () => {
    const [viewMenu, setViewMenu] = useState(false)
    const navigate = useNavigate()

    return (
        <div>
            <button id='openButton' className='btn btn-sm bg-white shadow-sm mobile' onClick={() => { setViewMenu(!viewMenu) }}>Menu</button>
            {viewMenu === true ?
                <div className="bottomNav">
                    <div className="modal-content1 bg-theme bottomNav-content">
                        <span className="close fw-light" onClick={() => { setViewMenu(!viewMenu) }}>
                            <img style={{width: '25px'}} src={closeIcon} alt="close" />
                        </span>
                        <div className="sidenav">
                            <ul className='nav flex-column'>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor") }} >Dashboard</a>

                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/menu") }} >Menu</a>

                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/orders")}} >Orders</a>

                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/stock") }} >Stock</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/coupon") }} >Coupon</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/setting") }} >Settings</a>
                                </li>
                                <li className='nav-item'>
                                    <a className='nav-link text-dark' onClick={() => { navigate("/vendor/tickets") }} >Support</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

                : null
            }
        </div>
    );
};

export default Sidebar;
