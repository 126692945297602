import Admin from './components/Admin';
import './styles/index.css';


const Home = () => {
  return <>

    <Admin />

    <div id="adminMain">

      <div className="container">

        <div className="row mb-3">
          <div className="col-12">
            <div className='card shadow p-2 text-right text-info'>
              Admin Dashboard
            </div>
          </div>
        </div>
        <div className="row">

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">New Orders</h4>
                <h1 className="display-4">0</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">Menu Items</h4>
                <h1 className="display-4">20</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">Categories</h4>
                <h1 className="display-4">12</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">New Users</h4>
                <h1 className="display-4">20</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">Canceled Orders</h4>
                <h1 className="display-4">20</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

          <div className="col-sm-4 mb-3">
            <div className="card shadow bg-light">
              <div className="card-body d-flex justify-content-around">
                <h4 className="card-title">In Transit</h4>
                <h1 className="display-4">20</h1>
              </div>
              <a href="/" className="btn admin-btn-theme">View All</a>
            </div>
          </div>

        </div>
      </div>

    </div>

  </>
}

export default Home
