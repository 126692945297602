import Vendor from "../../componenets/Vendor"
import { useState, useEffect } from "react"
import { Domain, url } from "../../../utils/Helper"
// image
import FormatDate from "../../../utils/Date"
import plusIcon from '../../../assets/icons/plus.png'
import editIcon from '../../../assets/icons/edit.png'
import closeIcon from '../../../assets/icons/close.svg'
import defaultMenuBg from '../../../assets/img/defalutVendorBg.png'
import { useNavigate } from "react-router-dom"

const Coupon = () => {
  const [refresh, setRefresh] = useState(false);
  const [viewMenu, setViewMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [couponData, setcouponData] = useState([]);
  const [modalData, setModalData] = useState('');
  const token = localStorage.getItem('token')
  const vendorId = localStorage.getItem('vendor_id')
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      fetchCoupons();
    }, 2000);
    setLoading(true)
  }, [refresh, token]);

  const fetchCoupons = async () => {
    try {
      const couponRes = await fetch(`${url}/vendor/coupons-all/${vendorId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      })
      const data = await couponRes.json()
      if (data) {
        // console.log(data, "menu")
        setcouponData(data)
        setRefresh(false)
      }

    } catch (error) {
      console.error('Error fetching coupons:', error)
    } finally {
      setLoading(false)
    }
  }



  const handleExpireCoupon = async (id) => {
    const my_date = new Date();
    my_date.setDate(my_date.getDate() + 1);
    const tomorrow_date = my_date.getFullYear() + "-" + ("0" + (my_date.getMonth() + 1)).slice(-2) + "-" + ("0" + my_date.getDate()).slice(-2);

    try {
      const response = await fetch(`${url}/vendor/coupons/expire/${id}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "vendor_id": vendorId,
          "expiry_date": tomorrow_date
        }),
      })
      const resData = await response.json()
      if (resData) {
        setLoading(true)
        setTimeout(() => {
          fetchCoupons();
        }, 2000);
      }
      // console.log(resData);
    } catch (error) {
      console.error('Error in getting data', error.message)
      throw error
    }
  }


  const handleDeleteCoupon = async (id) => {
    try {
      const response = await fetch(`${url}/vendor/coupons/${id}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },

      })
      const resData = await response.json()
      if (resData) {
        setLoading(true)
        setTimeout(() => {
          fetchCoupons();
        }, 2000);
      }
    } catch (error) {
      console.error('Error in getting data', error.message)
      throw error
    }
  }




  return <>

    <Vendor />

    <div className='frontGreyBg'>

      {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
          <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
          <p>loading</p>
        </div>
        :

        <section className='webContainer'>

          <div className='vendorAddMenuItem'>
            <div className="d-flex justify-content-center">
              <button onClick={() => navigate('/vendor/add-coupon')} className="btn frontFormBtn vendorOrangeBtn">
                <div className="frontBtnIcon">
                  <img src={plusIcon} alt="" />
                </div>
                <p>Add New Coupon</p>
              </button>
            </div>
          </div>

          <div className='row pt-3 pb-5'>
            <div className='col-sm-12 mb-3'>
              <div className='card rounded-50 themeShadow border-0 p-4'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Menu</h5>
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead className='bg-light'>
                      <th scope="col" className='font-weight-bold text-left'>
                        S.No
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Code
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Name
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Expiry Date
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Discount
                      </th>

                      <th scope="col"></th>
                    </thead>
                    <tbody>
                      {couponData?.map((item, index) =>
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{item.code}</td>
                          <td>{item.name}</td>
                          <td><FormatDate apiDateTime={item.expiry_date} /></td>
                          <td>{item.discount}</td>
                          <td>
                            <button className='text-primary font-weight-light editBtn btn ' onClick={() => navigate('/vendor/edit-coupon', { state: { couponId: item.id } })}>
                              <img src={editIcon} className='img-fluid' alt="" />
                            </button>
                            <button className='text-primary btn font-weight-light viewOrder btn' onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>View Details</button>
                            <button className='text-primary btn font-weight-light viewOrder btn' onClick={() => { handleDeleteCoupon(item.id) }}>Delete</button>
                            <button className='btn text-primary  font-weight-light viewOrder' onClick={() => { handleExpireCoupon(item.id) }}>Expire Coupon</button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </section>
      }

      {viewMenu === true ? (

        <div className="modal">
          <div className="modal-content1 bg-theme">
            <span className="close fw-light" onClick={() => { setViewMenu(!viewMenu); setModalData('') }}>
              <img src={closeIcon} alt="close" />
            </span>

            <div>
              <div className="modalBody">
                <div className="modalTitle">
                  <h3>{modalData.name}</h3>
                </div>

                <div className="font-weight-normal">
                  {<div dangerouslySetInnerHTML={{ __html: modalData.details }} />}

                </div>

              </div>
            </div>
          </div>
        </div>

      ) : null}

    </div>


  </>
}

export default Coupon
