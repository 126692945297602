import Vendor from "../../componenets/Vendor"
import { useState, useEffect } from "react"
import { url } from "../../../utils/Helper"
// image
import plusIcon from '../../../assets/icons/plus.png'
import editIcon from '../../../assets/icons/edit.png'
import closeIcon from '../../../assets/icons/close.svg'
import defaultMenuBg from '../../../assets/img/defalutVendorBg.png'
import { useNavigate } from "react-router-dom"

const Menu = () => {
  const [refresh, setRefresh] = useState(false);
  const [viewMenu, setViewMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  const [menuData, setMenuData] = useState([]);
  const [modalData, setModalData] = useState('');
  const token = localStorage.getItem('token')
  const vendorId = localStorage.getItem('vendor_id')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchVendorMenu = async () => {
      try {
        const vendorProfile = await fetch(`${url}/vendor/menu/${vendorId}`, {
          // const vendorProfile = await fetch(`${url}/vendor/menu/2`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        const data = await vendorProfile.json()
        if (data != null) {
          setMenuData(data)
          setRefresh(false)
        }
        // console.log(data, "menu")
      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }
    setTimeout(() => {
      fetchVendorMenu();
    }, 2000);
    setLoading(true)
  }, [refresh, token]);


  return <>

    <Vendor />

    <div className='frontGreyBg vendorDataContainer'>

      {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
          <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
          <p>loading</p>
        </div>
        :
        <section className='webContainer'>

          <div className='vendorAddMenuItem desktop'>
            <div className="d-flex justify-content-center">
              <button onClick={() => navigate('/vendor/add-menu')} className="btn frontFormBtn vendorOrangeBtn">
                <div className="frontBtnIcon">
                  <img src={plusIcon} alt="" />
                </div>
                <p>Add New Item</p>
              </button>
            </div>
          </div>


          <div className='row pt-3 pb-5'>
            <div className='col-sm-12 mb-3'>
              <div className='card rounded-50 themeShadow border-0 p-4'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Menu</h5>
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead className='bg-light'>
                      <th scope="col" className='font-weight-bold text-left'>
                        Menu Item
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Week Day
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Price
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Discount
                      </th>
                      <th scope="col" className='font-weight-bold'>
                        Meal Type
                      </th>
                      <th scope="col"></th>
                    </thead>
                    <tbody>
                      {menuData?.map((item, index) =>
                        <tr key={index}>
                          <td>{item.name}</td>
                          <td>{item.day_of_the_week}</td>
                          <td>{item.price}</td>
                          <td>{item.discount}</td>
                          <td>{item.meal_type}</td>
                          <td>
                            <button className='text-primary font-weight-light editBtn btn ' onClick={() => navigate('/vendor/edit-menu', { state: { menuId: item.id } })}>
                              <img src={editIcon} className='img-fluid' alt="" />
                            </button>
                            <button className='text-primary btn font-weight-light viewOrder btn' onClick={() => { setViewMenu(!viewMenu); setModalData(item) }}>View</button>
                            <button className='text-primary font-weight-light editBtn btn ' onClick={() => navigate('/vendor/add-menu', {
                              state: { item }
                            })}>
                              Duplicate
                            </button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </section>
      }

      {viewMenu === true ? (
        <div className="modal">
          <div className="modal-content1 bg-theme">
            <span className="close fw-light" onClick={() => { setViewMenu(!viewMenu); setModalData('') }}>
              <img src={closeIcon} alt="close" />
            </span>
            <div className="modalImage">
              <img src={modalData.image} alt="background" />
            </div>
            <div>
              <div className="modalBody">
                <div className="modalTitle">
                  <h3>{modalData.name}</h3>
                </div>
                <div className="bg-white p-2 d-flex justify-content-between mb-3">
                  <h6 className="mb-0 font-weight-light">Price: {modalData.price}</h6>
                  <h6 className="mb-0 font-weight-light">Discount: {modalData.discount}</h6>
                  <h6 className="mb-0 font-weight-light">Kcal: {modalData.meal_kcal}</h6>
                  <h6 className="mb-0 font-weight-light">Protien: {modalData.meal_protein}</h6>
                  <h6 className="mb-0 font-weight-light">Avl: {modalData.day_of_the_week}</h6>
                </div>

                <div className="font-weight-normal">
                  {<div dangerouslySetInnerHTML={{ __html: modalData.meal_details }} />}
                  {/* <p className="mt-2 font-weight-normal text-dark">{modalData.meal_details}
                                </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>

      ) : null}

    </div>

    <div className="mobile mt-5">
      <div class="bottom-nav">
        <a onClick={() => navigate('/vendor/add-menu')}>
          <span className="">
          </span>
          Add New item
        </a>

      </div>
    </div>


  </>
}

export default Menu
