const date = ({ apiDateTime }) => {

    // console.log(apiDateTime)
    // Assuming apiDateTime is the date and time string from the API
    const dateObject = new Date(apiDateTime)

    // Format the date using toLocaleString with options
    const formattedDateTime = dateObject.toLocaleString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    })

    // console.log(`Formatted Date and Time: ${formattedDateTime}`)
    // Display or use the formattedDateTime in your UI
    return (
        <>{formattedDateTime}</>
    )
  }


export default date