import Vendor from "../../componenets/Vendor"
import 'quill/dist/quill.snow.css'
import ReactQuill from 'react-quill'
import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
// image
import userIcon from '../../../assets/icons/user-2.png'
import plusIcon from '../../../assets/icons/plus.png'
import mealTypes from '../../../assets/icons/fried-rice.png'
import calanderIcon from '../../../assets/icons/calendar.png'
import { useState, useEffect } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { url } from "../../../utils/Helper";

const EditCoupon = () => {
    const navigate = useNavigate()
    const { state } = useLocation()
    const [name, setName] = useState('');
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [aboutCoupon, setAboutCoupon] = useState('');
    const [couponData, setcouponData] = useState('');
    const [discount, setDiscount] = useState('');
    const [availableTill, setAvailableTill] = useState('');
    const [code, setCode] = useState('');
    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')

    const handleAddCoupon = async () => {
        try {
            const response = await fetch(`${url}/vendor/coupons/${state.couponId}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "vendor_id": vendorId,
                    "name": name,
                    "discount": discount,
                    "code": code,
                    "details": aboutCoupon,
                    "expiry_date": availableTill
                }),
            })
            const resData = await response.json()
            // console.log(resData);
            navigate("/vendor/coupon")
        } catch (error) {
            console.error('Error in getting data', error.message)
            throw error
        }
    }

    useEffect(() => {
        const fetchCoupons = async () => {
            try {
                const couponRes = await fetch(`${url}/vendor/coupons/${state.couponId}`, {
                    method: 'GET',
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                })
                if (!couponRes.ok) {
                    throw new Error('Failed to fetch users')
                }
                const data = await couponRes.json()
                setcouponData(data)
                setName(data.name)
                setCode(data.code)
                setDiscount(data.discount)
                setAvailableTill(data.expiry_date)

                setRefresh(false)

            } catch (error) {
                console.error('Error fetching users:', error)
            } finally {
                setLoading(false)
            }
        }

        fetchCoupons();
    }, [refresh, token]);

    return <>

        <Vendor />

        <div className='frontGreyBg'>

            <section className='webContainer py-5'>

                <h1 className='pb-3 font-weight-bold'>Add A New Coupon</h1>

                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="name"
                                        name="name"
                                        placeholder="Name of the meal"
                                        autoComplete="off" required
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>
                        </div>
                        {/* ./Row End Name */}

                        <div className='row'>


                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" className="form-control frontFormInput"
                                        id="code"
                                        name="code"
                                        placeholder="Enter code"
                                        autoComplete="off" required
                                        value={code}
                                        onChange={(e) => setCode(e.target.value.toUpperCase())}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>


                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={userIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="date" className="form-control frontFormInput"
                                        id="expiry"
                                        name="expiry"
                                        placeholder="expiry"
                                        autoComplete="off" required
                                        value={availableTill}
                                        onChange={(e) => setAvailableTill(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>

                        <div className='row'>

                            <div className='col-sm-6'>
                                <div className="input-group frontInputBox">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text frontFromIcon">
                                            <img src={mealTypes} alt="" />
                                        </span>
                                    </div>
                                    <input type="number" className="form-control frontFormInput"
                                        id="discount"
                                        name="discount"
                                        placeholder="Enter discount"
                                        autoComplete="off" required
                                        value={discount}
                                        onChange={(e) => setDiscount(e.target.value)}
                                    />
                                </div>
                                {/* Single Input */}
                            </div>

                        </div>


                        <div className='row'>
                            <div className='col-sm-12 '>
                                <div className="input-group frontInputBox" >
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Enter about coupon"
                                        className=""
                                        // onChange={handleProcedureContentChange}
                                        style={{ height: "200%", width: "100%" }}
                                        onChange={(content) => setAboutCoupon(content)}

                                    >
                                    </ReactQuill>
                                </div>
                            </div>
                        </div>
                        {/* ./Row End Meal Time */}

                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddCoupon}>
                                <div className="frontBtnIcon" >
                                    <img src={plusIcon} alt="" />
                                </div>
                                <p>Update</p>
                            </button>
                        </div>
                    </div>
                </div>
                {/* ./Add New Item */}

            </section>

        </div>

    </>
}

export default EditCoupon
