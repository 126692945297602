import Vendor from "../../componenets/Vendor"
import plusIcon from '../../../assets/icons/plus.png'
import addressIcon from '../../../assets/icons/map.png'
import { url } from "../../../utils/Helper"
import ReactQuill from 'react-quill'

import { formats, modules } from "../../../Admin/components/EditorComponents/TextEditor";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";


const NewTicket = () => {
    const navigate = useNavigate()
    const [message, setMessage] = useState('')
    const [title, setTile] = useState('')
    const [subject, setSubject] = useState('')
    const [loading, setLoading] = useState(true);
    const [msg, setMsg] = useState('');

    const token = localStorage.getItem('token')
    const vendorId = localStorage.getItem('vendor_id')


    const handleAddTicket = async (id) => {
        try {
            const addTicket = await fetch(`${url}/vendor/tickets/new-ticket`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "senderId": vendorId, // sendId is vendor_id
                    "message": message,
                    "title": title,
                    "subject": subject
                }),
            })
            if (!addTicket.ok) {
                throw new Error('Failed to fetch users')
            }
            const data = await addTicket.json()
            setMsg(data.message)
            setTimeout(() => {
                navigate('/vendor/tickets')
                setMsg('')
            }, 2000);

        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }
    }

    return <>

        <Vendor />
        <div className='frontGreyBg vendorDataContainer'>

            <section className='webContainer'>

                <h1 className='pt-5 pb-3 font-weight-bold'>Update Your Profile</h1>

                <div className='row'>
                    <div className='col-7'>
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={addressIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="title"
                                        name="title"
                                        placeholder="Enter Title"
                                        autoComplete="off" required
                                        value={title} onChange={(e) => setTile(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={addressIcon} alt="" />
                                        </span>
                                    </div>
                                    <input type="text" class="form-control frontFormInput"
                                        id="subject"
                                        name="subject"
                                        placeholder="Enter Subject"
                                        autoComplete="off" required
                                        value={subject} onChange={(e) => setSubject(e.target.value)}
                                    />
                                </div>
                                {/* ./Single Input */}
                            </div>

                        </div>
                        {/* ./Row End Restaurant Address */}
                        <div className='row'>
                            <div className='col-sm-12'>
                                <div class="input-group frontInputBox" >
                                    <ReactQuill
                                        theme="snow"
                                        modules={modules}
                                        formats={formats}
                                        placeholder="Enter Message"
                                        onChange={(text) => setMessage(text)}
                                        style={{ height: "200%", width: "100%" }}
                                    >
                                    </ReactQuill>
                                </div>
                            </div>
                        </div>
                        <div>{msg}</div>

                        <div className="d-flex justify-content-start">
                            <button className="btn frontFormBtn" onClick={handleAddTicket} >
                                <div className="frontBtnIcon" >
                                    <img src={plusIcon} alt="" />
                                </div>
                                <p>Add New Ticket</p>
                            </button>
                        </div>
                    </div>

                </div>
            </section>
        </div>


    </>
}

export default NewTicket