import React, { useState, useEffect } from "react"
import { checkUserExistence, loginUser, registerUser } from '../Auth/Service'
import Header from '../Front/components/Header'
import { url } from "../utils/Helper"
import { firebase, auth } from "../firebase"
// Images
import formIcon from '../assets/icons/smartphone.png'
import authIcon from '../assets/icons/user.png'
import otpIcon from '../assets/icons/verification.png'
import Footer from "../Front/components/Footer"
import { useNavigate, Navigate } from "react-router-dom"
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const CustomerLogin = () => {
    const userRole = localStorage.getItem('role')
    const navigate = useNavigate();
    const [show, setshow] = useState(0);
    const [otp, setotp] = useState('');
    const [err, setErr] = useState('');
    const [name, setName] = useState('');
    const [pincode, setPincode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [disable, setDisable] = useState(false);
    const [final, setfinal] = useState("");

    const handleLogin = async () => {
        window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
            "recaptcha-container",
            {
                'size': 'invisible',  // Change to 'invisible' if you want to hide the recaptcha
                'callback': function (response) {
                    return response
                    // reCAPTCHA solved, allow signInWithPhoneNumber.
                    // onSignInSubmit();
                }
            }
        );

        if (phoneNumber !== "") {
            const appVerifier = window.recaptchaVerifier;
            if (appVerifier) {
                setDisable(true);
            }
            auth.signInWithPhoneNumber(phoneNumber, appVerifier)
                .then((result) => {
                    setfinal(result);
                                     setshow(true);
                    startTimer();
                    setErr('');
                })
                .catch((err) => {
                    alert(err.message);
                    window.location.reload();
                });
        } else {
            setErr('Enter mobile number');
        }
    };

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const startTimer = () => {
        setSeconds(60);
    };

    const handleCheckUser = async () => {
        if (final) {
            final.confirm(otp).then(async (result) => {
                // User signed in successfully.
                setErr('');
                try {
                    const userExists = await checkUserExistence(phoneNumber)
                    console.log(userExists)
                    if (userExists) {
                        // User exists, login
                        const userData = await loginUser(phoneNumber)
                        // Store user_id, role, and token locally
                        localStorage.setItem('user_id', userData.user.id)
                        localStorage.setItem('role', userData.user.role)
                        localStorage.setItem('token', userData.token)

                        if (userData.user.role == 'customer') {
                            navigate('/my-account')
                        } else {
                            alert('Login deny')
                        }
                    } else {
                        setshow(2)
                        // User does not exist, register
                        setErr('Registering user')
                    }
                }

                // Redirect or perform any other necessary actions
                catch (error) {
                    console.error('Login error:', error.message)
                }
            }).catch((error) => {
                // User couldn't sign in (bad verification code?)
                setErr('Enter a valid OTP');
                console.error(error);
            });
        } else {
            setErr('Enter a valid OTP');
        }
    };



    const handleregisterUser = async () => {
        try {
            const userData = await registerUser(phoneNumber, 'customer')
            // Store user_id, role, and token locally
            localStorage.setItem('user_id', userData.user.id)
            localStorage.setItem('role', userData.user.role)
            localStorage.setItem('token', userData.token)
            localStorage.setItem('name', name)
            localStorage.setItem('pincode', pincode)
            // console.log(userData, 'registered data')
            if (userData.user.role == 'customer') {
                createNewCustomer(userData.user.id, userData.token)
            } else {
                alert('Login deny')
            }

        } catch (error) {
            console.log(error)
        }
    }


    const createNewCustomer = async (id, token) => {
        // console.log(item)
        const userRes = await fetch(`${url}/customer/profile/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "profileData":
                {
                    "user_id": id, // id from auth response
                    "name": name,
                    // "email": "customeremail@gmail.com", // optional
                    "phone": phoneNumber,
                    "image": "customer1.jpg"
                }
            }),
        })
        const dataRes = await userRes.json()
        if (dataRes) {
            localStorage.setItem('customer_id', dataRes?.newUser[0]?.id)
            // console.log(dataRes?.newUser[0]?.id)
            navigate('/my-account')
        }
    };

    useEffect(() => {
        if (userRole === 'customer') {
            navigate('/my-account')
        } else {
            // No user logged in, redirect to the landing page
            navigate('/customer-login')
        }
    }, [userRole])




    return (
        <>
            <Header />
            <section className="frontCheckout frontGreyBg">
                <div className="webContainer">

                    {/* Checkout Form Insert Info */}
                    {show == 0 ?
                        <div className='row'>
                            <div className="col-sm-5 m-auto">
                                <h5 className='frontSectionTitle text-center mt-5'>Customer Login</h5>
                                <br />


                                <div class="input-group frontInputBox">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text frontFromIcon">
                                            <img src={formIcon} alt="" />
                                        </span>
                                    <PhoneInput
                                       enableSearch
                      autocompleteSearch
                      disableSearchIcon
                                        country={'us'}
                                        inputClass="form-control frontFormInput"
                                        inputStyle={{ width: "120%" }}
                                        type="text"
                                        id="phone"
                                        name="phone"
                                        value={phoneNumber} onChange={(value) => setPhoneNumber('+' + value)}
                                        placeholder="Enter mobile with country code"
                                        autoComplete="off"
                                        disabled={disable}
                                    />
                                    </div>
                                </div>

                                <br />
                                <div id="recaptcha-container"></div>
                                <br />

                                <div className="d-flex justify-content-center">
                                    <button className="btn frontFormBtn" onClick={handleLogin} >
                                        <div className="frontBtnIcon">
                                            <img src={otpIcon} alt="" />
                                        </div>
                                        <p>Send OTP</p>
                                    </button>
                                </div>

                            </div>
                        </div>

                        : show == 1 ?

                            <div className="row pt-5">                {/* Checkout Form Verify OTP*/}
                                <div className="col-sm-5 m-auto">
                                    <h5 className='frontSectionTitle text-center mt-5 mb-0'>Verify</h5>
                                    <br />
                                    <p className='lead text-center'>OTP sent to {phoneNumber}</p>
                                    <div class="input-group frontInputBox">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text frontFromIcon">
                                                <img src={otpIcon} alt="" />
                                            </span>
                                        </div>
                                        <input type="text" class="form-control frontFormInput"
                                            id="phone"
                                            name="phone"
                                            placeholder="Enter 6 digit OTP"
                                            autoComplete="off"
                                            value={otp} onChange={(e) => setotp(e.target.value)}
                                        />
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        <button className="btn frontFormBtn" onClick={handleCheckUser}>
                                            <div className="frontBtnIcon">
                                                <img src={authIcon} alt="" />
                                            </div>
                                            <p>Verify</p>
                                        </button>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-sm-6'>
                                            <button type="button" className='btn px-0 text-primary' onClick={() => { window.location.reload() }} > Change Mobile Number</button>
                                        </div>
                                        <div className='col-sm-6 text-right'>
                                            <button type='button' className='btn px-0 text-primary' onClick={() => { window.location.reload() }} disabled={seconds !== 0}  >{seconds !== 0 ? `Resend OTP after ${seconds}sec` : 'Resend OTP'}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :

                            <>
                                <section className="frontCheckout frontGreyBg">
                                    <div className="webContainer">
                                        <div className="row pt-5">
                                            <div className="col-sm-5 m-auto">
                                                <div class="input-group frontInputBox mt-5">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text frontFromIcon">
                                                            <img src={formIcon} alt="" />
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control frontFormInput"
                                                        id="name"
                                                        name="name"
                                                        value={name} onChange={(e) => setName(e.target.value)}
                                                        placeholder="Your Name"
                                                        autoComplete="off"
                                                    />
                                                </div>

                                                <div class="input-group frontInputBox">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text frontFromIcon">
                                                            <img src={formIcon} alt="" />
                                                        </span>
                                                    </div>
                                                    <input type="text" class="form-control frontFormInput"
                                                        id="phone"
                                                        name="phone"
                                                        disabled
                                                        value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                                        placeholder="Enter mobile with country code"
                                                        autoComplete="off"
                                                    />
                                                </div>

                                                <div class="input-group frontInputBox">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text frontFromIcon">
                                                            <img src={formIcon} alt="" />
                                                        </span>
                                                    </div>
                                                    <input type="number" class="form-control frontFormInput"
                                                        id="pincode"
                                                        name="pincode"
                                                        placeholder="Pincode"
                                                        autoComplete="off"
                                                        maxLength={6}

                                                        value={pincode} onChange={(e) => setPincode(e.target.value)}
                                                    />
                                                </div>
                                                <div className="d-flex justify-content-center">
                                                    <button className="btn frontFormBtn" onClick={handleregisterUser}>
                                                        <div className="frontBtnIcon">
                                                            <img src={authIcon} alt="" />
                                                        </div>
                                                        <p>Create your profile</p>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </>

                    }

                </div>
            </section>

            <Footer />
        </>

    )
}

export default CustomerLogin



