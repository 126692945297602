import '../styles/style.css'
import { url } from '../../utils/Helper';
// Images
import defaultLogo from '../../assets/img/defaultLogo.png'
import defaultBanner from '../../assets/img/defalutVendorBg.png'
import uploadIcon from '../../assets/icons/photo.png'
import cartImage from '../../assets/icons/cart.png'
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';


const VendorMenu = ({ refreshed, ProfileData }) => {
    const navigate = useNavigate()
    const [refresh, setRefresh] = useState(false);
    const [loading, setLoading] = useState(true);
    const [cart, setCart] = useState('');
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    const dispatch = useDispatch()

    const deviceToken = localStorage.getItem('deviceToken')

    const handleGoToMyAccount = () => {
        if (role === 'customer' && token != null) {
            navigate('/my-account')
        }
        else {
            navigate('/customer-login')
        }
    }

    useEffect(() => {
        getCart()
    }, []);

    useEffect(() => {
        if (refreshed) {
            getCart()
        }
    }, [refreshed]);


    const getCart = async () => {
        try {
            const getCartData = await fetch(`${url}/front/getCartItem`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    "device": deviceToken
                }),
            })

            const data = await getCartData.json()
            if (data) {
                // console.log(data)
                setCart(data[0]?.qty)
                setRefresh(false)
            }
            else {
                console.log('no data')
                setRefresh(false)
                setCart(0)
            }
        } catch (error) {
            console.error('Error fetching users:', error)
        } finally {
            setLoading(false)
        }

    }


    return (

        <header className='vendorHeader' style={{
            backgroundImage: `url(${ProfileData?.banner ? ProfileData?.banner : defaultBanner})`
        }}>

            <div className='webContainer'>

                <div className="row pt-5 cartRow">
                    <div className='col-sm-11'>
                    </div>
                    <div className='col-sm-1'>
                        <span className='frontCartCount'>
                            <span>{cart?.split('.')[0] || 0}</span>
                        </span>
                        <div className='frontCartBtn'>
                            <a onClick={() => { navigate("/cart") }} className=''>
                                <img src={cartImage} alt="cart" width={35} />
                            </a>
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-12">
                        <div className='vendorLogoContainer'>
                            <img src={ProfileData?.image ? ProfileData?.image : defaultLogo} alt="" className='vendorLogo bg-white' />
                        </div>
                    </div>
                </div>

                <div className='desktop'>
                    <div className='vendorDesktopNav d-flex justify-content-start'>
                        <div className="d-flex justify-content-center">
                            <a onClick={() => { handleGoToMyAccount() }} className={`btn vendorDesktopNavBtn`}>
                                <div className="vendorDesktopBtnIcon">
                                    <img src={uploadIcon} alt="" />
                                </div>
                                <p className='text-white'>Profile</p>
                            </a>
                        </div>

                        <div className="d-flex justify-content-center">
                            <a onClick={() => { handleGoToMyAccount() }} className={'btn vendorDesktopNavBtn'}>
                                <div className="vendorDesktopBtnIcon">
                                    <img src={uploadIcon} alt="" />
                                </div>
                                <p className={`text-white`}>My Orders</p>
                            </a>
                        </div>

                    </div>
                </div>
                {/* Desktop Nav */}
            </div>
        </header>
    )
}

export default VendorMenu