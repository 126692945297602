import { useState, useEffect } from "react";
import { url, Domain } from "../../../utils/Helper";
import Vendor from "../../componenets/Vendor"
import Date from "../../../utils/Date"

const Order = () => {
  const [ViewOrder, setViewOrder] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderData, setOrderData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [orderId, setOrderId] = useState('');
  const [orderInfo, setOrderInfo] = useState('');
  const token = localStorage.getItem('token')
  const vendorId = localStorage.getItem('vendor_id')


  useEffect(() => {
    const fetchVendorMenu = async () => {
      try {
        const vendorOrder = await fetch(`${url}/vendor/orders/${vendorId}`, {
          method: 'GET',
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        })
        const data = await vendorOrder.json()
        if (data?.order) {
          setOrderData(data?.order)
          setRefresh(false)
        }

      } catch (error) {
        console.error('Error fetching users:', error)
      } finally {
        setLoading(false)
      }
    }

    setTimeout(() => {
      fetchVendorMenu();
    }, 2000);
    setLoading(true)
  }, [refresh, token, vendorId]);


  const fetchCartItems = async (orderId) => {
    try {
      const getCartItems = await fetch(`${url}/front/getCartItemsByOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "order_id": orderId
        }),
      })

      const data = await getCartItems.json()
      if (data) {
        // console.log(data, "cart items")
        setCartItems(data)
        setRefresh(false)
      }
      else {
        console.log('no data')
        setRefresh(false)
      }
    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }

  const handleChangeStatus = async (event) => {
    try {
      const changeOrderStatus = await fetch(`${url}/vendor/order/status-update/${orderId}`, {
        method: 'PUT',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          "order_status": event.target.value
        }),
      })

      const data = await changeOrderStatus.json()
      if (data) {
        setViewOrder(!ViewOrder)
      }
      // console.log(data)

    } catch (error) {
      console.error('Error fetching users:', error)
    } finally {
      setLoading(false)
    }
  }



  return <>

    <Vendor />

    <div className='frontGreyBg vendorDataContainer'>

      {loading ?
        <div className="d-flex justify-content-center align-items-center" style={{ height: "50vh", flexDirection: "column" }} >
          <img src={require("../../../assets/loader/loadergif.gif")} alt="background" height={100} width={100} />
          <p>loading</p>
        </div>
        :
        <section className='webContainer'>

          <div className='row pt-3'>
            <div className='col-sm-12 mb-3'>
              <div className='card rounded-50 themeShadow border-0 p-4'>
                <div className='d-flex justify-content-between'>
                  <h5 className='font-weight-bold'>Orders (New First)</h5>
                  {/* <a href="/" className='btn text-primary btn-sm'>View All Orders</a> */}
                </div>

                <div className='table-responsive'>
                  <table className='table'>
                    <thead className='bg-light'>
                      <td className='font-weight-bold'>
                        Order Id
                      </td>
                      <td className='font-weight-bold'>
                        Quantity
                      </td>
                      <td className='font-weight-bold'>
                        Payment
                      </td>
                      <td className='font-weight-bold'>
                        Order Placed
                      </td>
                      <td className='font-weight-bold'>
                        Payment Status
                      </td>
                      <td></td>
                    </thead>
                    <tbody>
                      {orderData?.map((item) =>
                        <tr>
                          <th>{item.id}</th>
                          <td>{item.qty}</td>
                          <td>{item.price}</td>
                          <td><Date apiDateTime={item.created_at} /></td>
                          <td>
                            {item.payment_status}
                          </td>
                          <td>
                            <button className='text-primary font-weight-light viewOrder btn' onClick={() => { setOrderId(item.id); setOrderInfo(item); fetchCartItems(item.id); setViewOrder(!ViewOrder); }}>View</button>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

          </div>

        </section>
      }

      {ViewOrder === true ? (
        <div className="modal">
          <div className="modal-content1 p-4 w-100 h-100 mt-5 overflow-auto">
            <span className="close" onClick={() => { setViewOrder(!ViewOrder) }}>
              &times;
            </span>
            <div className="row mb-4">
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Order ID: ${orderInfo?.id}`} className="form-control" readOnly />
              </div>
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Order Date: ${orderInfo?.updated_at}`} className="form-control" readOnly />
              </div>
              <div className="col-lg-8 col-sm-10">
                <input type="text" value={`Delivery Address: ${orderInfo?.delivery_address}`} className="form-control" readOnly />
              </div>

              <div className="col-lg-8 col-sm-10">
                <select defaultValue={"Select Status"} className="form-control" onChange={handleChangeStatus}>
                  <option disabled >Select Status</option>
                  <option value="Ready for Delivery">Ready for Delivery</option>
                  <option value="Out for delivery">Out for delivery</option>
                  <option value="Delivery Made">Delivery Made</option>
                  <option value="Not Delivered">Not Delivered</option>
                </select>
              </div>
            </div>

            <div className='row'>
              {cartItems?.length > 0 && cartItems?.map((item, Index) =>
                <div className='col-md-6 col-xs-12' key={Index}>
                  <div class="card cartItemCard mb-3">
                    <div class="row no-gutters">
                      <div class="col-4">
                        <img src={item.image} alt="" className='img-fluid frontCartItemImage' />
                      </div>
                      <div class="col-8">
                        <div class="card-body px-1 py-2">
                          <span class="frontVegTag">{item.category}</span>
                          <br />
                          <span class="frontMealTime">For Dinner</span>
                          <br />
                          <span class="frontKcal">{item.id} Kcal</span>
                          <br />
                          <span className='frontMealDate'><Date apiDateTime={item.created_at} /></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* Loop the items in cart */}

            </div>
            {/* Row End */}

          </div>
        </div>
      ) : null}

    </div>


  </>
}

export default Order