import { useNavigate } from "react-router-dom"

const Admin = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="col-md-12 py-2 border-bottom shadow-sm admin-main-header">
        <div className="row">
          <div className="col-md-6 admin-logo-column">
            <button type="button" className="btn btn-lg mx-2 openbtn"><i className="fa fa-bars"></i></button>
          </div>
          <div className="col-md-6">
            <div className="d-flex justify-content-end">
              <ul className="nav">
                <li className="nav-item">
                  {/* <a className="nav-link btn btn-lg" title="Profile">Logo</a> */}
                </li>
                <li className="nav-item">
                  <a className="admin-nav-link btn btn-lg" href="/" title="Logout"><i className="fa fa-sign-out mr-3 text-dark"></i></a>
                </li>
              </ul>
              <button type="button" className="btn btn-lg mx-2 openbtn-mobile"><i
                className="fa fa-bars"></i></button>
            </div>
          </div>
        </div>
      </div>

      <div id="mySidenav" className="admin-sidenav">
        <div className="d-flex">
          <div className="admin-desktop-logo-container mb-4">
            {/* Image */}
          </div>

          <div className="close-nav-btn">
            <button type="button" className="btn btn-lg text-dark closebtn mx-2"><i className="fa fa-chevron-left"></i></button>
          </div>
        </div>
        <div className="admin-links-list">
          <div className="">
            <a onClick={()=>{navigate("/admin")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>Dashboard</a>
          </div>
        </div>
        <div className="admin-links-list mt-0">
          <div className="">
            <a onClick={()=>{navigate("/admin/users")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>All Users</a>
          </div>
        </div>
        <div className="admin-links-list mt-0">
          <div className="">
            <a onClick={()=>{navigate("/admin/coupons")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>All Coupons</a>
          </div>
        </div>
        <div className="admin-links-list mt-0">
          <div className="">
            <a onClick={()=>{navigate("/admin/contacts")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>All Contacts</a>
          </div>
        </div>
        <div className="admin-links-list mt-0">
          <div className="">
            <a onClick={()=>{navigate("/admin/tickets")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>All Tickets</a>
          </div>
        </div>
        <div className="admin-links-list mt-0">
          <div className="">
            <a onClick={()=>{navigate("/admin/settings")}} className="admin-active"><i className="fa fa-tachometer mx-3"></i>Web Settings</a>
          </div>
        </div>
      </div>
      <div className="bg-white shadow"></div>
    </>
  )
}

export default Admin