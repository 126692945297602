import React, { useState, useEffect } from "react"
import { checkUserExistence, loginUser, registerUser } from '../Auth/Service'
import formIcon from '../assets/icons/smartphone.png'
import backArrow from '../assets/icons/back.png'
import cart from '../assets/icons/cart.png'
import otpIcon from '../assets/icons/verification.png'
import authIcon from '../assets/icons/user.png'
import { useNavigate, useLocation } from "react-router-dom"
import { url } from "../utils/Helper"
import HeaderNavigation from "./components/HeaderNavigation"

const Checkout = () => {
    const userRole = localStorage.getItem('role')
    const navigate = useNavigate();
    const location = useLocation();
    const [show, setshow] = useState(false);
    const [otp, setotp] = useState('');
    const [err, setErr] = useState('');
    const [name, setName] = useState('');
    const [pincode, setPincode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')

    const handleLogin = async () => {
        var regmm = '^([0|+[0-9]{1,5})?([7-9][0-9]{9})$';
        var regmob = new RegExp(regmm);
        if (regmob.test(phoneNumber)) {
            startTimer()
            setshow(true)
            setErr('')
        }
        else {
            setErr('Enter valid mobile number')
        }
    }

    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval;

        if (seconds > 0) {
            interval = setInterval(() => {
                setSeconds((prevSeconds) => prevSeconds - 1);
            }, 1000);
        }

        return () => {
            clearInterval(interval);
        };
    }, [seconds]);

    const startTimer = () => {
        setSeconds(60);
    };

    const handleCheckUser = async () => {
        if (otp === '123456') {
            setErr('')
            try {
                const userExists = await checkUserExistence(phoneNumber)
                console.log(userExists)
                if (userExists) {
                    // User exists, login
                    const userData = await loginUser(phoneNumber)
                    // Store user_id, role, and token locally
                    localStorage.setItem('user_id', userData.user.id)
                    localStorage.setItem('role', userData.user.role)
                    localStorage.setItem('token', userData.token)
                    localStorage.setItem('name', name)
                    localStorage.setItem('pincode', pincode)
                    if (userData.user.role == 'customer') {
                        navigate('/select-address')
                    } else {
                        alert('Login deny')
                    }
                } else {
                    handleregisterUser()
                    // User does not exist, register
                    setErr('Registering user')

                }
            }

            // Redirect or perform any other necessary actions
            catch (error) {
                console.error('Login error:', error.message)
            }
        }
        else {
            setErr('Enter a valid OTP')
        }

    }

    const handleregisterUser = async () => {
        try {
            const userData = await registerUser(phoneNumber, 'customer')
            // Store user_id, role, and token locally
            localStorage.setItem('user_id', userData.user.id)
            localStorage.setItem('role', userData.user.role)
            localStorage.setItem('token', userData.token)
            console.log(userData, 'registered data')
            if (userData.user.role == 'customer') {
                createNewCustomer(userData.user.id, userData.token)
            } else {
                alert('Login deny')
            }

        } catch (error) {
            console.log(error)
        }
    }


    const createNewCustomer = async (id, token) => {
        // console.log(item)
        const userRes = await fetch(`${url}/customer/profile/create`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "profileData":
                {
                    "user_id": id, // id from auth response
                    "name": name,
                    // "email": "customeremail@gmail.com", // optional
                    "phone": phoneNumber,
                    "image": "customer1.jpg"
                }
            }),
        })
        const dataRes = await userRes.json()
        if (dataRes) {
            localStorage.setItem('customer_id', dataRes?.newUser[0]?.id)
            // console.log(dataRes?.newUser[0]?.id)
            navigate('/select-address')
        }
    };

    useEffect(() => {
        if (userRole === 'customer') {
            navigate('/select-address')
        } else {
            // No user logged in, redirect to the landing page
            navigate('/checkout')
        }
    }, [userRole])


    
    return <>

        <section className="frontCheckout frontGreyBg">
            <div className="webContainer">
                {/* Header */}
                <HeaderNavigation pageName={"Checkout"} refreshed={true} />
                {/* Checkout Form Insert Info */}
                {!show ?
                    <div className='row'>
                        <div className="col-sm-5 m-auto">

                            <div class="input-group frontInputBox mt-5">
                                <div class="input-group-prepend">
                                    <span class="input-group-text frontFromIcon">
                                        <img src={formIcon} alt="" />
                                    </span>
                                </div>
                                <input type="text" class="form-control frontFormInput"
                                    id="name"
                                    name="name"
                                    value={name} onChange={(e) => setName(e.target.value)}
                                    placeholder="Your Name"
                                    autoComplete="off"
                                />
                            </div>

                            <div class="input-group frontInputBox">
                                <div class="input-group-prepend">
                                    <span class="input-group-text frontFromIcon">
                                        <img src={formIcon} alt="" />
                                    </span>
                                </div>
                                <input type="text" class="form-control frontFormInput"
                                    id="phone"
                                    name="phone"
                                    value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)}
                                    placeholder="Enter mobile with country code"
                                    autoComplete="off"
                                />
                            </div>
                            {err}

                            <div class="input-group frontInputBox">
                                <div class="input-group-prepend">
                                    <span class="input-group-text frontFromIcon">
                                        <img src={formIcon} alt="" />
                                    </span>
                                </div>
                                <input type="number" class="form-control frontFormInput"
                                    id="pincode"
                                    name="pincode"
                                    placeholder="Pincode"
                                    autoComplete="off"
                                    maxLength={6}

                                    value={pincode} onChange={(e) => setPincode(e.target.value)}
                                />
                            </div>

                            <div className="d-flex justify-content-center">
                                <button className="btn frontFormBtn" onClick={handleLogin} >
                                    <div className="frontBtnIcon">
                                        <img src={otpIcon} alt="" />
                                    </div>
                                    <p>Send OTP</p>
                                </button>
                            </div>

                        </div>
                    </div>

                    :

                    <div className="row pt-5">                {/* Checkout Form Verify OTP*/}
                        <div className="col-sm-5 m-auto">
                            <h5 className='frontSectionTitle text-center mt-5 mb-0'>Verify</h5>
                            <br />
                            <p className='lead text-center'>OTP sent to {phoneNumber}</p>
                            <div class="input-group frontInputBox">
                                <div class="input-group-prepend">
                                    <span class="input-group-text frontFromIcon">
                                        <img src={otpIcon} alt="" />
                                    </span>
                                </div>
                                <input type="text" class="form-control frontFormInput"
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter 6 digit OTP"
                                    autoComplete="off"
                                    value={otp} onChange={(e) => setotp(e.target.value)}
                                />
                            </div>
                            <div className="d-flex justify-content-center">
                                <button className="btn frontFormBtn" onClick={handleCheckUser}>
                                    <div className="frontBtnIcon">
                                        <img src={authIcon} alt="" />
                                    </div>
                                    <p>Verify</p>
                                </button>
                            </div>

                            <div className='row mt-3'>
                                <div className='col-sm-6'>
                                    <button type="button" className='btn px-0 text-primary' onClick={() => { setshow(false); setPhoneNumber(''); setotp(''); setErr('') }} > Change Mobile Number</button>
                                </div>
                                <div className='col-sm-6 text-right'>
                                    <button type='button' className='btn px-0 text-primary' onClick={handleLogin} disabled={seconds !== 0}  >{seconds !== 0 ? `Resend OTP after ${seconds}sec` : 'Resend OTP'}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

            </div>
        </section>

    </>
}

export default Checkout;